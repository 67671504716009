import {MaterialEntityLabelAnnotationSummary, MaterialEntityTuple} from "../../api";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Breadcrumbs,
    InputAdornment,
    Link,
    Stack,
    Typography
} from "@mui/material";
import {capitalizedStr} from "../../utils/formatText";
import {Link as RouterLink} from "react-router-dom";
import React, {ChangeEvent, useState} from "react";
import {ExpandMore, Search} from "@mui/icons-material";
import {StyledSearch} from "../search";

export function AnnotationTypeBreakdown(props: {
    name: string,
    annotations: MaterialEntityLabelAnnotationSummary[]
}) {
    const [searchQuery, setSearchQuery] = useState("");
    const [annotations, setAnnotations] = useState(props.annotations);

    const handleQueryByKey = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchQuery(event.target.value);
        setAnnotations(props.annotations.filter((annotation: MaterialEntityLabelAnnotationSummary) => annotation.label.toLowerCase().includes(event.target.value.toLowerCase())))
    };

    return <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMore/>}
            aria-controls={`${props.name}-content`}
            id={`${props.name}-header`}
        >
            <Typography variant={"h6"}>{props.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Stack
                direction="column"
                spacing={1}
            >
                <StyledSearch
                    fontSize={16}
                    width={400}
                    value={searchQuery}
                    onChange={handleQueryByKey}
                    placeholder="Search Label..."
                    size="small"
                    startAdornment={
                        <InputAdornment position="start">
                            <Search/>
                        </InputAdornment>
                    }
                />
                {
                    annotations.map((annotation: MaterialEntityLabelAnnotationSummary) =>
                        <Stack direction={"row"} spacing={1} key={annotation.label}>
                            <Typography>{capitalizedStr(annotation.label)}: </Typography>

                            <Breadcrumbs
                                separator={","}>{annotation.entities?.map((material_entity: MaterialEntityTuple, index: number) =>
                                <Link key={index} to={`/ingredients/${material_entity.id}/insights`}
                                      component={RouterLink}
                                      sx={{display: 'contents'}}>
                                    {material_entity.name}
                                </Link>
                            )}
                            </Breadcrumbs>
                        </Stack>
                    )
                }
            </Stack>
        </AccordionDetails>
    </Accordion>
}