import React, {useEffect, useMemo, useState} from "react";
// @mui/material
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid2,
    IconButton,
    InputAdornment,
    MenuItem,
    Skeleton,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import {Add, Clear, Close, Edit, Info, InfoRounded, SettingsSuggest} from "@mui/icons-material";
// material react table
import {MaterialReactTable, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {
    NutritionalMeasurement,
    NutritionalParameter,
    NutritionalScores,
    NutritionalScoresInputs,
    PaginatedNutritionalMeasurementList,
    ProductTypeEnum
} from "../../../api";
import {useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
    foodMainFormulationDetailPageQuery,
    formulationCompositionSummaryPageQuery,
    formulationNutritionalBreakdownPageQuery
} from "../../../pages/FoodDetailPage";
import {mrtTheme} from "../../../theme/mrtTheme";
import {useTheme} from "@mui/material/styles";
import DataOperationStatus from "../../../components/data-operation-status/DataOperationStatus";
import {DataLoadingStatusHandler} from "../../../components/data-loading-status-handler";
import openAPIGeneratorFoodDesignerInstance from "../../../openAPIGeneratorFoodDesignerInstance";
import {apiRoutes, ProductTypeTypesOptions} from "../../../config";
import MRTButtonStack from "../../../components/ingredient-buttons/IngredientButtons";
import {nutritionalParametersPageQuery} from "../admin/NutritionalParametersSection";
import DebouncedAutocompleteSearchBar from "../../../components/debounced-autocomplete-search-bar";
import {capitalizedStr} from "../../../utils/formatText";
import NutritionBreakdownTable from "../../../components/nutrition_breakdown_table";
import NutritionClaims from "../../../components/nutrition_label";
import NutritionLabel from "../../../components/nutrition_claims";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {ScoreAvatar} from "../../../components/nutritional-score-avatar";
import {InputSlider} from "../../../components/input-slider";
import openAPIGeneratorMaterialLibraryInstance from "../../../openAPIGeneratorMaterialLibraryInstance";
import {formatGrams, formatKcal} from "../../../utils/formatUnits";

export const ingredientNutritionPageQuery = (
    materialEntity?: number,
    page?: number,
    pageSize?: number,
    search?: string,
) => ({
    queryKey: [apiRoutes.foodDesigner.formulations.baseEndpoint, materialEntity?.toString(), "contents", search, page, pageSize],
    queryFn: () => {
        return openAPIGeneratorFoodDesignerInstance
            .foodDesignerNutritionalMeasurementsList(materialEntity, undefined, page, pageSize, undefined, search)
            .then(response => response.data);
    },
});

export function AddNutrientMeasurementModal(props: { setNutrition: any, open: any, handleClose: any }) {
    const {
        control, watch,
        handleSubmit,
    } = useForm<NutritionalMeasurement>(
        {
            defaultValues: {
                measurement: 0.0,
            }
        }
    )
    const watchParameter = watch("parameter");


    const onSubmit: SubmitHandler<NutritionalMeasurement> = (data) => {
        props.setNutrition(data)
        props.handleClose();
    }

    return <Dialog
        open={props.open}
        onClose={props.handleClose}
        PaperProps={{
            // @ts-ignore
            component: "form",
            onSubmit: handleSubmit(onSubmit),
        }}>
        <DialogTitle>
            <IconButton
                edge="start"
                color="inherit"
                onClick={props.handleClose}
                aria-label="close"
            >
                <Close/>
            </IconButton>
            Add Ingredient
        </DialogTitle>
        <DialogContent>
            <Controller
                name="parameter"
                control={control}
                render={({field}) =>
                    <DebouncedAutocompleteSearchBar
                        getOptionLabel={(option) => option ? option.name : "Missing"}
                        queryFn={(page?: number, pageSize?: number, search?: string) => {
                            return openAPIGeneratorFoodDesignerInstance.foodDesignerNutritionalParametersList(undefined, undefined, page, pageSize, search).then(response => response.data.results)
                        }}
                        freeSolo={false}
                        initalOptions={["salt"]}
                        {...field}
                    />
                }
            />
            <TextField
                variant="outlined"
                label={"Parameter EUROFIR Code"}
                fullWidth
                value={watchParameter?.eurofir_code ?? " "}
                disabled={true}
                margin="normal"
            />
            <Controller
                name="measurement"
                control={control}
                render={({field}) => <TextField
                    variant="outlined"
                    label={"Measurement"}
                    fullWidth
                    margin="normal"
                    slotProps={{
                        input: {
                            endAdornment: <InputAdornment
                                position="end">{watchParameter?.default_unit}</InputAdornment>,
                        }
                    }}
                    {...field}
                />}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
        </DialogActions>
    </Dialog>
}


// ----------------------------------------------------------------------
export function NutritionTable(props: {
    material_entity: number,
}) {
    const theme = useTheme()
    const queryClient = useQueryClient()
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100, //customize the default page size
    });
    const [searchQuery, setSearchQuery] = useState<string>(""); // Updated to string

    const {
        data: formulationData,
        status: formulationStatus
    } = useQuery<PaginatedNutritionalMeasurementList>(
        ingredientNutritionPageQuery(props.material_entity, pagination.pageIndex + 1, pagination.pageSize, searchQuery)
    );

    const nutritionalMeasurementUpdateMutation = useMutation({
        mutationFn: (content: NutritionalMeasurement) => {
            return openAPIGeneratorFoodDesignerInstance.foodDesignerNutritionalMeasurementsUpdate(
                content.id,
                {
                    ...content,
                    parameter: content.parameter.id
                }
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.foodDesigner.formulations.baseEndpoint, props.material_entity?.toString()]})
            queryClient.invalidateQueries({queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, props.material_entity?.toString()]})
        },
    })

    const nutritionalMeasurementCreateMutation = useMutation({
        mutationFn: (content: NutritionalMeasurement) => {
            return openAPIGeneratorFoodDesignerInstance.foodDesignerNutritionalMeasurementsCreate(
                {
                    measurement: content.measurement,
                    material_entity: props.material_entity,
                    parameter: content.parameter.id
                }
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.foodDesigner.formulations.baseEndpoint, props.material_entity?.toString()]})
            queryClient.invalidateQueries({queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, props.material_entity?.toString()]})
        },
    })

    const nutritionalMeasurementDeleteMutation = useMutation({
        mutationFn: (id: number) => {
            return openAPIGeneratorFoodDesignerInstance.foodDesignerNutritionalMeasurementsDestroy(id)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.foodDesigner.formulations.baseEndpoint, props.material_entity?.toString()]})
            queryClient.invalidateQueries({queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, props.material_entity?.toString()]})
        },
    })

    const generateNutrientMutation: any = useMutation({
        mutationFn: (uid: number) => {
            return openAPIGeneratorMaterialLibraryInstance.materialLibraryMaterialEntitiesCalculateMeasurementsCreate(uid)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.foodDesigner.formulations.baseEndpoint, props.material_entity?.toString()]})
            queryClient.invalidateQueries({queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, props.material_entity?.toString()]})
        },
    })

    const handleGenerateNutrition = () => {
        generateNutrientMutation.mutate(props.material_entity)
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const columns: MRT_ColumnDef<NutritionalMeasurement>[] = useMemo(
        () => {
            return [
                {
                    accessorKey: 'parameter.name',
                    header: 'Parameter',
                    columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                    // @ts-ignore
                    Cell: ({row, renderedCellValue}) => capitalizedStr(renderedCellValue ?? ""),
                    enableEditing: false,
                },
                {
                    accessorKey: 'parameter.eurofir_code',
                    header: 'EuroFir Code',
                    enableEditing: false
                },
                {
                    accessorKey: 'measurement',
                    header: 'Measurement',
                },
                {
                    accessorKey: 'parameter.default_unit',
                    header: 'Unit',
                    enableEditing: false
                },
            ]
        },
        [],
    );
    // const rootData = useMemo(() => formulationData?.results.filter((r) => r.parameter.parent_parameter == 1), [formulationData]);

    const table = useMaterialReactTable({
        columns: columns,
        data: formulationData?.results ?? [],
        mrtTheme: mrtTheme(theme),
        // enableExpanding: true,

        initialState: {
            density: 'compact',
            expanded: true,
        },
        // getSubRows: (row) => formulationData?.results.filter((r) => r.parameter.parent_parameter === row.parameter.id),

        enableEditing: true,
        editDisplayMode: 'row',
        onEditingRowSave: ({table, row, values}) => {
            nutritionalMeasurementUpdateMutation.mutate({...row.original, ...values});
            table.setEditingRow(null); //exit editing mode
        },
        renderRowActions: ({row}) => (
            <MRTButtonStack>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Remove"}>
                    <IconButton onClick={() => nutritionalMeasurementDeleteMutation.mutate(row.original.id)}>
                        <Clear color={"error"}></Clear>
                    </IconButton>
                </Tooltip>
            </MRTButtonStack>
        ),
        renderTopToolbarCustomActions: ({table}) => (
            <div>
                <Button
                    onClick={handleOpen}
                    startIcon={<Add/>}>
                    Add Nutritional Information
                </Button>
                <AddNutrientMeasurementModal setNutrition={nutritionalMeasurementCreateMutation.mutate} open={open}
                                             handleClose={handleClose}/>
                <Button
                    onClick={handleGenerateNutrition}
                    startIcon={<SettingsSuggest/>}>
                    Generate Nutrition (From Composition)
                </Button>
            </div>
        ),
        positionActionsColumn: 'last',

        enablePagination: true,
        manualPagination: true,
        manualFiltering: true, //turn off client-side filtering
        onPaginationChange: setPagination,
        onGlobalFilterChange: setSearchQuery,
        state: {
            pagination: pagination,
            globalFilter: searchQuery,
        }, //pass in your own managed globalFilter state
        rowCount: formulationData?.count ?? 0
    })


    return <DataLoadingStatusHandler status={formulationStatus}
                                     successRender={
                                         formulationData?.results ?
                                             // @ts-ignore
                                             <MaterialReactTable table={table}/>
                                             : <></>
                                     }
                                     loadingRender={
                                         <DataOperationStatus
                                             icon={<CircularProgress/>}>
                                             <Typography color={"textSecondary"}>
                                                 Analysing Product Nutritional Composition.
                                             </Typography>
                                         </DataOperationStatus>
                                     }/>
}

export function RelatedNutritionParametersTable(props: {
    material_entity: number,
}) {
    const theme = useTheme()
    const queryClient = useQueryClient()

    const [searchQuery, setSearchQuery] = useState<string>(""); // Updated to string
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20, //customize the default page size
    });

    const {
        data: nutritionalParametersData,
        status: queryStatus,
    } = useQuery(
        nutritionalParametersPageQuery(pagination.pageIndex + 1, pagination.pageSize, searchQuery, props.material_entity)
    );


    const columns: MRT_ColumnDef<NutritionalParameter>[] = useMemo(
        () => {
            return [
                {
                    accessorKey: 'name',
                    header: 'Name',
                    size: 100,
                },
                {
                    accessorKey: 'description',
                    header: 'Description',
                    size: 100,
                },
                {
                    accessorKey: 'caloric_multiplier',
                    header: 'Caloric Multiplier',
                    size: 100,
                },
            ]
        },
        [],
    );

    const table = useMaterialReactTable({
        columns: columns,
        data: nutritionalParametersData?.results ?? [],
        mrtTheme: mrtTheme(theme),
        initialState: {density: 'compact'},

        // enableEditing: true,
        // editDisplayMode: "modal",
        // createDisplayMode: "modal",
        // onEditingRowSave: ({table, row, values}) => {
        //     nutritionalMeasurementUpdateMutation.mutate({...row.original, ...values});
        //     table.setEditingRow(null); //exit editing mode
        // },
        // onCreatingRowSave: ({values}) => {
        //     nutritionalMeasurementCreateMutation.mutate(values);
        //     table.setCreatingRow(null); //exit creating mode
        // },
        // renderRowActions: ({row}) => (
        //     <MRTButtonStack>
        //         <Tooltip title="Edit">
        //             <IconButton onClick={() => table.setEditingRow(row)}>
        //                 <Edit/>
        //             </IconButton>
        //         </Tooltip>
        //         <Tooltip title={"Remove"}>
        //             <IconButton onClick={() => nutritionalMeasurementDeleteMutation.mutate(row.original.id)}>
        //                 <Clear color={"error"}></Clear>
        //             </IconButton>
        //         </Tooltip>
        //     </MRTButtonStack>
        // ),
        // renderTopToolbarCustomActions: ({table}) => (
        //     <Button
        //         onClick={() => {
        //             table.setCreatingRow(true);
        //         }}
        //     >
        //         Add Nutritional Information
        //     </Button>
        // ),

        enablePagination: true,
        manualPagination: true,
        manualFiltering: true, //turn off client-side filtering
        onPaginationChange: setPagination,
        state: {
            pagination: pagination,
        }, //pass in your own managed globalFilter state
    })


    return <MaterialReactTable table={table}/>
}


// ----------------------------------------------------------------------
export default function FoodNutritionSection() {
    let {foodId} = useParams();

    const {isLoading: isLoadingFoodData, data: formulationData} = useQuery(
        foodMainFormulationDetailPageQuery(Number(foodId))
    )

    const {
        status: FormulationCompositionSummaryDataStatus,
        data: formulationCompositionSummaryData
    } = useQuery(
        {
            ...formulationCompositionSummaryPageQuery(formulationData?.id),
            enabled: !!formulationData?.id
        }
    )

    const {
        status: formulationNutritionalBreakdownDataStatus,
        data: formulationNutritionalBreakdownData
    } = useQuery(
        {
            ...formulationNutritionalBreakdownPageQuery(formulationData?.id),
            enabled: !!formulationData?.id
        }
    )

    // @ts-ignore
    return (
        <Grid2 container spacing={3}>
            <Grid2 size={12}>
                <Card>
                    <CardHeader
                        title={"What is the nutrition panel?"}
                        avatar={
                            <Info/>
                        }
                    />
                    <CardContent>
                        This panel includes all nutrients within a Food Product, it will also correlate them with your
                        choice of ingredients.
                    </CardContent>
                </Card>
            </Grid2>
            <Grid2 size={12}>
                <Typography component={"h2"} variant={"h4"}>
                    Nutrition Breakdown
                </Typography>
            </Grid2>
            <Grid2 size={12}>
                <DataLoadingStatusHandler status={formulationNutritionalBreakdownDataStatus}
                                          successRender={
                                              <NutritionBreakdownTable
                                                  rows={formulationNutritionalBreakdownData?.breakdown ?? []}/>
                                          }
                                          loadingRender={
                                              <DataOperationStatus
                                                  icon={<CircularProgress/>}>
                                                  <Typography color={"textSecondary"}>
                                                      Analysing Product Nutritional Composition.
                                                  </Typography>
                                              </DataOperationStatus>
                                          }/>

            </Grid2>
            <Grid2 size={12}>
                <Typography component={"h2"} variant={"h4"}>
                    Nutrition Editor
                </Typography>
            </Grid2>
            <Grid2 size={12}>
                {
                    formulationData?.id ?
                        <NutritionTable material_entity={formulationData?.id}/> : <Skeleton></Skeleton>
                }
            </Grid2>
            <Grid2 size={12}>
                <Typography component={"h2"} variant={"h4"}>
                    Mock Label
                </Typography>
            </Grid2>
            <Grid2 size={12}>
                <DataLoadingStatusHandler status={FormulationCompositionSummaryDataStatus}
                                          successRender={
                                              formulationCompositionSummaryData ?
                                                  // @ts-ignore
                                                  <NutritionLabel rows={[
                                                      {
                                                          name: "Calories",
                                                          value: formatKcal(formulationCompositionSummaryData?.nutrition?.enerc_kcal),
                                                          value_portion: formatKcal(formulationCompositionSummaryData?.nutrition_per_portion?.enerc_kcal)
                                                      },
                                                      {
                                                          name: "Protein",
                                                          value: formatGrams(formulationCompositionSummaryData?.nutrition.prot_g),
                                                          value_portion: formatGrams(formulationCompositionSummaryData?.nutrition_per_portion.prot_g),
                                                      },
                                                      {
                                                          name: "Carbohydrates",
                                                          value: formatGrams(formulationCompositionSummaryData?.nutrition.cho_g),
                                                          value_portion: formatGrams(formulationCompositionSummaryData?.nutrition_per_portion.cho_g),
                                                      },
                                                      {
                                                          name: "Sugars",
                                                          value: formatGrams(formulationCompositionSummaryData?.nutrition.sugar_g),
                                                          value_portion: formatGrams(formulationCompositionSummaryData?.nutrition_per_portion.sugar_g),
                                                      },
                                                      {
                                                          name: "Fat",
                                                          value: formatGrams(formulationCompositionSummaryData?.nutrition.fat_g),
                                                          value_portion: formatGrams(formulationCompositionSummaryData?.nutrition_per_portion.fat_g),
                                                      },
                                                      {
                                                          name: "Saturated",
                                                          value: formatGrams(formulationCompositionSummaryData?.nutrition.fasat_g),
                                                          value_portion: formatGrams(formulationCompositionSummaryData?.nutrition_per_portion.fasat_g),
                                                      },
                                                      {
                                                          name: "Salt",
                                                          value: formatGrams(formulationCompositionSummaryData?.nutrition.nacl_g),
                                                          value_portion: formatGrams(formulationCompositionSummaryData?.nutrition_per_portion.nacl_g),
                                                      },
                                                  ]}/>
                                                  : <></>
                                          }
                                          loadingRender={
                                              <DataOperationStatus
                                                  icon={<CircularProgress/>}>
                                                  <Typography color={"textSecondary"}>
                                                      Analysing Product Nutritional Composition.
                                                  </Typography>
                                              </DataOperationStatus>
                                          }/>
            </Grid2>
            <Grid2 size={12}>
                <Typography component={"h2"} variant={"h4"}>
                    Nutritional Claims
                </Typography>
            </Grid2>
            <Grid2 size={12}>
                <DataLoadingStatusHandler status={FormulationCompositionSummaryDataStatus}
                                          successRender={
                                              formulationCompositionSummaryData ?
                                                  <NutritionClaims
                                                      rows={formulationCompositionSummaryData?.nutrition_claims ?? {}}/>
                                                  : <></>
                                          }
                                          loadingRender={
                                              <DataOperationStatus
                                                  icon={<CircularProgress/>}>
                                                  <Typography color={"textSecondary"}>
                                                      Analysing Product Nutritional Composition.
                                                  </Typography>
                                              </DataOperationStatus>
                                          }/>
            </Grid2>
            <Grid2 size={12}>
                <Typography component={"h2"} variant={"h4"}>
                    Nutritional Score
                </Typography>
            </Grid2>
            <Grid2 size={12}>
                {formulationData ?
                    <NutritionalScore formulationId={formulationData.id}/> :
                    <></>
                }
            </Grid2>
        </Grid2>
    )
}

function NutritionalScore(props: {
    formulationId: number
}) {
    const [score, setScore] = useState<NutritionalScores | undefined>()
    const calculateNutritionalScoreMutation = useMutation({
        mutationFn: (data: NutritionalScoresInputs) => {
            return openAPIGeneratorFoodDesignerInstance.foodDesignerFormulationsNutritionalScoreCreate(props.formulationId, data)
        },
    })

    const {control, handleSubmit, watch, register, unregister} = useForm<NutritionalScoresInputs>(
        {
            defaultValues: {
                product_type: "generic",
                fruits_vegetables_legumes_percent: "0.0",
                contains_non_nutritive_sweeteners: false
            }
        }
    )

    const watchProductType = watch("product_type");

    useEffect(() => {
        if (watchProductType === ProductTypeEnum.Beverages) {
            register("contains_non_nutritive_sweeteners");
        } else {
            unregister("contains_non_nutritive_sweeteners");
        }
    }, [register, unregister, watchProductType]);

    const onSubmit: SubmitHandler<NutritionalScoresInputs> = async (data) => {
        await calculateNutritionalScoreMutation.mutateAsync(data).then((value) =>
            setScore(value.data)
        )
    }

    return <Grid2 container spacing={2}>
        <Grid2 container size={6} spacing={2}>
            <Grid2 size={12}>
                <Controller
                    name="product_type"
                    control={control}
                    render={({field}) => <TextField
                        variant="outlined"
                        label={"Product Type"}
                        select
                        fullWidth
                        {...field}>
                        {ProductTypeTypesOptions.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </TextField>}
                />
            </Grid2>
            <Grid2 size={12}>
                <Controller
                    name="fruits_vegetables_legumes_percent"
                    control={control}
                    render={({field}) =>
                        <InputSlider
                            label={<>
                                Percentage of fruits, vegetables and legumes.
                                < Tooltip
                                    title="Please confirm what components can be considered according the updated nutritional score algorithm. Please correct the percentage inputed to properly account for dried components.">
                                    <InfoRounded fontSize={"small"}/>
                                </Tooltip>
                            </>
                            }
                            {...field}
                        />
                    }
                />
            </Grid2>
            <Grid2 size={12}>
                <Controller
                    name="contains_non_nutritive_sweeteners"
                    control={control}
                    render={({field}) =>
                        <FormControl component="fieldset" variant="standard">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={field.value}
                                        onChange={field.onChange}
                                        disabled={!(watchProductType === ProductTypeEnum.Beverages)}
                                        name="public"/>
                                }
                                label="Contains Non Nutritive Sweetners"
                                {...field}
                            />
                        </FormControl>
                    }
                />
            </Grid2>
            <Grid2 size={12}>
                <Button variant={'outlined'}
                        size={'large'}
                        color={'success'}
                        type={'submit'}
                        onClick={handleSubmit(onSubmit)}>
                    Calculate
                </Button>
            </Grid2>
        </Grid2>
        <Grid2 size={6}>
            <Typography variant={"h6"} align={'center'}>
                Nutritional Score (Updated)
            </Typography>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <ScoreAvatar score={score ? score.score : "?"}/>
            </Box>
            <Typography variant={"body2"} align={'center'}>
                Points: {score?.points ?? "?"}
            </Typography>
        </Grid2>
    </Grid2>
}