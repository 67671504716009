import {Helmet} from 'react-helmet-async';
// hooks
import React, {ChangeEvent, useEffect, useState} from "react";
// @mui
import {
    Box,
    Button, ButtonGroup,
    Grid2, IconButton,
    InputAdornment,
    List, ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack, ToggleButtonGroup,
    Typography
} from '@mui/material';
import {
    Add,
    ArrowForward,
    FileUploadOutlined,
    Folder,
    FolderShared,
    GridView,
    Search,
    TableView
} from "@mui/icons-material";
import Pagination from '@mui/material/Pagination';
// sections and components
import {SortFoodCardList} from "../sections/@dashboard/foods";
import {StyledSearch} from "../components/search";
import {FoodCard} from "../components/food-card";
// router
import {NavLink as RouterLink, useSearchParams} from "react-router-dom";
// utils
import {useQuery} from '@tanstack/react-query';
import {apiRoutes, ingredientTypes} from "../config";
import {SortOption, SortOptionCreateParams} from "../utils/filteringAndSorting";
import openAPIGeneratorFoodDesignerInstance from "../openAPIGeneratorFoodDesignerInstance";
import {DataLoadingStatusHandler} from "../components/data-loading-status-handler";
import {FilterTopicTypographyStyle} from "./IngredientsPage";
import FilterBar from "../components/filter-bar";
import PermissionsGate from "../access/PermissionsGate";
import {SCOPES} from "../access/permission-maps";
import useMediaQuery from "@mui/material/useMediaQuery";
import {ToggleButton} from "@mui/lab";
import {DataList} from "../components/data-list";
import {CreatorOption} from "../sections/@dashboard/ingredients/ingredient-list-filters/CreatorIngredientCardList";

// ----------------------------------------------------------------------

const sortInitialState: SortOption = {
    property: 'none',
    order: 'asc',
    label: 'None'
}

export const foodPageQuery = (isOwner?: boolean, isPublic?: boolean, searchQuery?: string, sort?: SortOption, page?: number, pageSize?: number) => ({
    queryKey: [apiRoutes.foodDesigner.products.baseEndpoint, isOwner, isPublic, searchQuery, sort, page, pageSize],
    queryFn: () => {
        return openAPIGeneratorFoodDesignerInstance.foodDesignerProductsList(isOwner, isPublic, sort ? SortOptionCreateParams(sort) : undefined, page, pageSize, searchQuery)
            .then(response => response.data)
    }
})


export const foodPageLoader = async (queryClient: any, params?: any) => {
    const query = foodPageQuery(true, undefined, '', sortInitialState, 1, 9)
    return queryClient.ensureQueryData(query)
}

export default function FoodsPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState<string>(searchParams.get("search") ?? ""); // Updated to string
    const [sort, setSort] = useState(sortInitialState);
    const [creator, setCreator] = useState(true);
    const [page, setPage] = useState(1)
    const pageSize = 9
    // @ts-ignore
    const [viewMode, setViewMode] = React.useState('grid');
    // @ts-ignore
    const matches = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const handleViewModeChange = (
        event: React.MouseEvent<HTMLElement>,
        newMode: string,
    ) => {
        setViewMode(newMode);
    };

    const sortOptions: SortOption[] = [
        {property: 'none', order: "asc", label: 'None'},
        {property: 'date_created', order: "desc", label: 'Newest'},
        {property: 'date_created', order: "asc", label: 'Oldest'},
        {property: 'name', order: "asc", label: 'Asc: Name'},
        {property: 'name', order: "desc", label: 'Desc: Name'},
    ];

    const creatorOptions = [
        {value: true, label: 'My Products', icon: <Folder/>},
        {value: false, label: 'Shared', icon: <FolderShared/>},
    ];


    const {data: foodProductsData, status} = useQuery(
        foodPageQuery(creator, undefined, searchQuery, sort, page, pageSize)
    )

    const handleQueryByKey = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchQuery(event.target.value);
        setSearchParams(event.target.value ? {"search": event.target.value.toString()} : undefined)
    };


    // Handler for changing pages
    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number): void => {
        setPage(page);
    };

    // Effect to reset the page to 1 whenever search query, type, annotations, or sorting changes
    useEffect(() => {
        setPage(1);
    }, [creator, searchQuery, sort, pageSize]);

    return (
        <>
            <Helmet>
                <title> Food Products | CibusAI </title>
            </Helmet>

            {/* Main content container */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                {/* Sidebar with Tabs */}
                <FilterBar>
                    <Typography
                        gutterBottom
                        sx={FilterTopicTypographyStyle}
                    >
                        Collection
                    </Typography>
                    <List>
                        {creatorOptions.map((option) => (
                            <ListItemButton
                                key={option.label}
                                // @ts-ignore
                                onClick={() => setCreator(option.value)}
                                dense
                                selected={creator === option.value}
                            >
                                <ListItemIcon>
                                    {option.icon}
                                </ListItemIcon>
                                <ListItemText primary={option.label}/>
                            </ListItemButton>
                        ))}
                    </List>
                    <SortFoodCardList value={sort} setValue={setSort} sortOptions={sortOptions}/>
                </FilterBar>

                {/* Main Content Section */}
                <Box width={"100%"}>
                    <Grid2 container
                           alignItems="center"
                           justifyContent="space-between"
                           spacing={2}>
                        <Grid2 size={12}>
                            <Stack
                                alignItems={"center"}
                                direction={"row"}
                                flex-grow={1}
                                justifyContent={"space-between"}
                            >
                                <StyledSearch
                                    fontSize={14}
                                    width={400}
                                    size={"small"}
                                    value={searchQuery}
                                    onChange={handleQueryByKey}
                                    placeholder="Search Product..."
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Search/>
                                        </InputAdornment>
                                    }
                                />
                                <ToggleButtonGroup
                                    color="primary"
                                    value={viewMode}
                                    exclusive
                                    size={"small"}
                                    onChange={handleViewModeChange}
                                    aria-label="View Mode">
                                    <ToggleButton value="grid"><GridView/></ToggleButton>
                                    <ToggleButton value="table"><TableView/></ToggleButton>
                                </ToggleButtonGroup>
                                <PermissionsGate fallback={<></>} scopes={[SCOPES.productsCanCreate]}>
                                    <ButtonGroup>
                                        {
                                            matches ?
                                                <Button variant="contained"
                                                        startIcon={<Add/>}
                                                        component={RouterLink}
                                                        to={"new-food-product"}>
                                                    New Food Product
                                                </Button> :
                                                <Button variant="contained"
                                                        component={RouterLink}
                                                        to={"new-food-product"}>
                                                    <Add/>
                                                </Button>
                                        }
                                    </ButtonGroup>
                                </PermissionsGate>
                            </Stack>
                        </Grid2>
                        <Grid2 size={12}>
                            <DataLoadingStatusHandler
                                status={status}
                                count={foodProductsData?.count}
                                successRender={
                                    <Grid2 container spacing={3} size={12}>
                                        {viewMode == "table" ?
                                            <Grid2 size={12}>
                                                <DataList
                                                    data={foodProductsData?.results}
                                                    itemRender={(item) => (
                                                        <ListItem
                                                            key={item.id}
                                                            secondaryAction={
                                                                <IconButton component={RouterLink}
                                                                            to={`/food-products/${item.id}`}>
                                                                    <ArrowForward/>
                                                                </IconButton>
                                                            }
                                                        >
                                                            <ListItemText
                                                                primary={item.name}
                                                                // @ts-ignore
                                                                secondary={item.date_modified}
                                                            />
                                                        </ListItem>)}
                                                />
                                            </Grid2> :
                                            <Grid2 container spacing={3} size={12}>
                                                {foodProductsData?.results.map((food) => (
                                                    <Grid2 size={{xs: 12, md: 6, lg: 4}} key={food.id}>
                                                        <FoodCard food={food}/>
                                                    </Grid2>))
                                                }
                                            </Grid2>
                                        }
                                        <Grid2 size={12} alignItems={"center"}>
                                            <Stack alignItems={"center"}>
                                                <Pagination
                                                    variant="outlined"
                                                    shape="rounded"
                                                    count={Math.ceil(foodProductsData ? foodProductsData.count / pageSize : 0)}
                                                    page={page}
                                                    onChange={handlePageChange}
                                                />
                                            </Stack>
                                        </Grid2>
                                    </Grid2>
                                }
                            />
                        </Grid2>
                    </Grid2>
                </Box>
            </Box>
        </>
    );
}
