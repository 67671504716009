import {PERMISSIONS} from "./permission-maps";
import {useGetRoles} from "./useGetRoles";
import React from "react";

const hasPermission = ({permissions, scopes}: { permissions: string[], scopes: string[] }) => {
    const scopesMap: { [key: string]: boolean } = {};
    scopes.forEach((scope) => {
        scopesMap[scope] = true;
    });

    return permissions.some((permission) => scopesMap[permission]);
};

export default function PermissionsGate({
                                            children,
                                            fallback,
                                            scopes = []
                                        }: { children: React.ReactNode, fallback: React.ReactNode, scopes: string[] }) {
    const roles = useGetRoles();
    const permissions = roles ? PERMISSIONS[roles[0]] : [];

    const permissionGranted = hasPermission({permissions, scopes});

    if (!permissionGranted) return <>{fallback}</>
    return <>{children}</>;
}
