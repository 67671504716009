import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid2,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import React from "react";

export default function NewFoodDescription(props: { state: any, handleChanges: any }) {

    return (
        <Grid2 container spacing={2}>
            <Grid2 size={12}>
                <Typography variant={"h3"}>New Product Details</Typography>
            </Grid2>
            <Grid2 size={8}>
                <TextField variant={"outlined"}
                           label={'Name'}
                           onChange={props.handleChanges('name')}
                           defaultValue={props.state.name}
                />
            </Grid2>
            <Grid2 size={8}>
                <TextField variant={"outlined"}
                           fullWidth
                           multiline
                           label={'Description'}
                           minRows={3}
                           onChange={props.handleChanges('description')}
                           defaultValue={props.state.description}
                />
            </Grid2>
            <Grid2 size={8}>
                <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">Access</FormLabel>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.state.is_public}
                                onChange={props.handleChanges('is_public')}
                                name="public"/>
                        }
                        label="Make public"
                    />
                    <FormHelperText>Be careful</FormHelperText>
                </FormControl>
            </Grid2>
        </Grid2>
    )
}