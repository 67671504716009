import React from "react";
// @mui/material
import {Button, Grid2, MenuItem, TextField, Typography,} from "@mui/material";
import {Delete, Save} from "@mui/icons-material";
// material react table
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {MaterialEntity} from "../../../api";
import {apiRoutes, ingredientTypesOptions} from "../../../config";
import {ingredientDetailQuery} from "../../../pages/IngredientDetailPage";
import openAPIGeneratorMaterialLibraryInstance from "../../../openAPIGeneratorMaterialLibraryInstance";


// ----------------------------------------------------------------------
export default function IngredientSettingsSection() {
    let {ingredientId} = useParams();

    const queryClient = useQueryClient()
    const navigate = useNavigate()


    const {isLoading, data: ingredientData} = useQuery(
        ingredientDetailQuery(Number(ingredientId))
    )

    const {
        control,
        handleSubmit
    } = useForm<MaterialEntity>(
        {
            defaultValues: {
                name: ingredientData?.name,
                description: ingredientData?.description,
                type: ingredientData?.type,
            },
        }
    )

    const updateMutation: any = useMutation({
        mutationFn: (data: MaterialEntity) => {
            // @ts-ignore
            return openAPIGeneratorMaterialLibraryInstance.materialLibraryMaterialEntitiesUpdate(ingredientId, data)
        },
        onSuccess: () => {
            // @ts-ignore
            queryClient.invalidateQueries({queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, ingredientId.toString()]})
        },
        onError: (error, variables, context) => {
            console.log(error, variables, context)
        }
    })

    // const updateVisibilityMutation: any = useMutation({
    //     mutationFn: (visibility: VisibilityEnum) => {
    //         // @ts-ignore
    //         return openAPIGeneratorMaterialLibraryInstance.materialLibraryMaterialEntitiesVisibility(foodId, {"visibility": visibility})
    //     },
    //     onSuccess: () => {
    //         // @ts-ignore
    //         queryClient.invalidateQueries({queryKey: [apiRoutes.foodDesigner.products.baseEndpoint, foodId.toString()]})
    //     },
    // })


    const deleteMutation: any = useMutation({
        mutationFn: () => {
            // @ts-ignore
            return openAPIGeneratorMaterialLibraryInstance.materialLibraryMaterialEntitiesDestroy(ingredientId)
        },
        onSuccess: () => {
            // @ts-ignore
            queryClient.invalidateQueries({queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, ingredientId.toString()]})
        },
    })


    const onSubmit: SubmitHandler<MaterialEntity> = (data) => {
        updateMutation.mutate(data)
    }

    const onDelete = () => {
        deleteMutation.mutate()
        navigate("/ingredients")
    }

    return (
        <Grid2 container spacing={3}>
            <Grid2 size={12}>
                <Typography variant={"h6"} gutterBottom>
                    Metadata
                </Typography>
                <Controller
                    name="name"
                    control={control}
                    render={({field}) =>
                        <TextField
                            variant={"outlined"}
                            label={'Name'}
                            margin={"normal"}
                            fullWidth
                            {...field} />}
                />
                <Controller
                    name="type"
                    control={control}
                    render={({field}) => <TextField
                        variant="outlined"
                        label={"Ingredient Type"}
                        select
                        margin={"normal"}
                        fullWidth
                        multiline
                        disabled
                        maxRows={8}
                        {...field}>
                        {ingredientTypesOptions.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </TextField>}
                />
                <Controller
                    name="description"
                    control={control}
                    render={({field}) =>
                        <TextField variant={"outlined"}
                                   fullWidth
                                   multiline
                                   margin={"normal"}
                                   label={'Description'}
                                   minRows={3}
                                   {...field} />}
                />
                <div>
                    <Button startIcon={<Save/>} onClick={handleSubmit(onSubmit)}>
                        Save
                    </Button>
                </div>
                {/*<Typography variant={"h6"} gutterBottom>*/}
                {/*    Access*/}
                {/*</Typography>*/}
                {/*<FormControl component="fieldset" variant="standard">*/}
                {/*    <FormControlLabel*/}
                {/*        control={*/}
                {/*            <Switch*/}
                {/*                checked={ingredientData?.is_public}*/}
                {/*                onChange={(event) => event.target.checked ? updateVisibilityMutation.mutate("public") : updateVisibilityMutation.mutate("private")}*/}
                {/*                name="public"/>*/}
                {/*        }*/}
                {/*        label="Make public"*/}
                {/*    />*/}
                {/*    <FormHelperText>Be careful, all associated information will also be made public</FormHelperText>*/}
                {/*</FormControl>*/}
                <Typography variant={"h6"} gutterBottom>
                    Delete
                </Typography>
                <Button color={"error"} startIcon={<Delete/>} onClick={onDelete}>
                    Delete Permanently
                </Button>
            </Grid2>
        </Grid2>
    )
}