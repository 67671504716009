import {Helmet} from 'react-helmet-async';
// @mui
import {Box, Divider, Tab, Tabs, Typography} from '@mui/material';
import {TabContext} from "@mui/lab";
import {BarChart, DonutSmall, InfoOutlined, Insights, Settings,} from "@mui/icons-material";
// router
import {Link as RouterLink, Outlet, useParams} from "react-router-dom";
// components and sections
import {useQuery} from "@tanstack/react-query";
import {apiRoutes} from "../config";
import openAPIGeneratorFoodDesignerInstance from "../openAPIGeneratorFoodDesignerInstance";
import {useRouteMatch} from "../routes";
import {TabOutletPanel} from "./IngredientDetailPage";

// ----------------------------------------------------------------------

export const foodDetailPageQuery = (foodId: number) => ({
        queryKey: [apiRoutes.foodDesigner.products.baseEndpoint, foodId.toString()],
        queryFn: () => openAPIGeneratorFoodDesignerInstance
            .foodDesignerProductsRetrieve(foodId)
            .then(
                response => response.data
            ),
    }
)

export const foodMainFormulationDetailPageQuery = (foodId: number) => ({
        queryKey: [apiRoutes.foodDesigner.products.baseEndpoint, "formulations", "main"],
        queryFn: () => openAPIGeneratorFoodDesignerInstance
            .foodDesignerProductsFormulationsMainRetrieve(foodId)
            .then(
                response => response.data
            ),
    }
)

export const formulationDetailPageQuery = (formulationId: number) => ({
        queryKey: [apiRoutes.foodDesigner.formulations.baseEndpoint, formulationId?.toString()],
        queryFn: () => openAPIGeneratorFoodDesignerInstance
            .foodDesignerFormulationsRetrieve(formulationId)
            .then(
                response => response.data
            ),
    }
)

export const formulationContentPageQuery = (formulationId: number) => ({
        queryKey: [apiRoutes.foodDesigner.formulations.baseEndpoint, formulationId?.toString(), "composition", "content"],
        queryFn: () => openAPIGeneratorFoodDesignerInstance
            .foodDesignerFormulationsCompositionRetrieve(formulationId)
            .then(
                response => response.data
            ),
    }
)

export const formulationNutritionalPageQuery = (formulationId: number) => ({
        queryKey: [apiRoutes.foodDesigner.formulations.baseEndpoint, formulationId?.toString(), "nutrition", "content"],
        queryFn: () => openAPIGeneratorFoodDesignerInstance
            .foodDesignerNutritionalMeasurementsList(formulationId)
            .then(
                response => response.data
            ),
    }
)

export const formulationCompositionSummaryPageQuery = (formulationId?: number) => ({
        queryKey: [apiRoutes.foodDesigner.formulations.baseEndpoint, formulationId?.toString(), "composition", "summary"],
        queryFn: () => formulationId ? openAPIGeneratorFoodDesignerInstance
            .foodDesignerFormulationsCompositionSummaryRetrieve(formulationId)
            .then(
                response => response.data
            ) : undefined,
    }
)

export const formulationNutritionalBreakdownPageQuery = (formulationId?: number) => ({
        queryKey: [apiRoutes.foodDesigner.formulations.baseEndpoint, formulationId?.toString(), "composition", "nutrition"],
        queryFn: () => formulationId ? openAPIGeneratorFoodDesignerInstance
            .foodDesignerFormulationsNutritionBreakdownRetrieve(formulationId)
            .then(
                response => response.data
            ) : undefined,
    }
)

export const foodDetailPageLoader = async (queryClient: any, params: any) => {
    const query = foodDetailPageQuery(params.foodId)
    return queryClient.ensureQueryData(query)
}

export default function FoodDetailPage() {
    // handle tabs
    const routeMatch = useRouteMatch([
        '/food-products/:foodId/insights', '/food-products/:foodId/composition',
        '/food-products/:foodId/nutrition', '/food-products/:foodId/settings', '/food-products/:foodId'
    ]);
    const currentTab = routeMatch?.pattern?.path;

    // get macronutrients from food
    let {foodId} = useParams();

    const {isLoading: isLoadingFoodData, data: foodProductData} = useQuery(
        foodDetailPageQuery(Number(foodId))
    )

    return (
        <>
            <Helmet>
                <title> Foods | CibusAI </title>
            </Helmet>
            {isLoadingFoodData ?
                <></>
                :
                <Box>
                    <Typography component={"h1"} variant={"h3"}>
                        {/*@ts-ignore*/}
                        {foodProductData.name}
                    </Typography>
                    <TabContext value={currentTab ?? "/food-products/:foodId/details"}>
                        <Tabs value={currentTab} aria-label="food-tabs">
                            <Tab icon={<InfoOutlined/>}
                                 iconPosition={"start"}
                                 label="Details"
                                 value="/food-products/:foodId"
                                 component={RouterLink}
                                 to={""}
                            />
                            <Tab icon={<BarChart/>}
                                 iconPosition={"start"}
                                 label="Composition"
                                 value="/food-products/:foodId/composition"
                                 component={RouterLink}
                                 to={"composition"}
                            />
                            <Tab icon={<DonutSmall/>}
                                 iconPosition={"start"}
                                 label="Nutrition"
                                 value="/food-products/:foodId/nutrition"
                                 component={RouterLink}
                                 to={"nutrition"}
                            />
                            <Tab icon={<Insights/>}
                                 iconPosition={"start"}
                                 label="Insights"
                                 value="/food-products/:foodId/insights"
                                 component={RouterLink}
                                 to={"insights"}
                            />
                            {
                                foodProductData?.owner ?
                                    <Tab icon={<Settings/>}
                                         iconPosition={"start"}
                                         label="Settings"
                                         value="/food-products/:foodId/settings"
                                         component={RouterLink}
                                         to={"settings"}
                                    /> : ""
                            }
                        </Tabs>
                        <Divider/>
                        <TabOutletPanel>
                            <Outlet/>
                        </TabOutletPanel>
                    </TabContext>
                </Box>}
        </>
    );
}
