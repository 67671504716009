// ----------------------------------------------------------------------

import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {MaterialEntityComponentNutritionalSummary} from "../../api";
import {formatGrams, formatKcal} from "../../utils/formatUnits";


function NutritionBreakdownTable(props: { rows: MaterialEntityComponentNutritionalSummary[] }) {

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 150}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">Energy (Kcal)</TableCell>
                        <TableCell align="right">Fats (g)</TableCell>
                        <TableCell align="right">Saturated Fats (g)</TableCell>
                        <TableCell align="right">Carbohydrates (g)</TableCell>
                        <TableCell align="right">Sugars (g)</TableCell>
                        <TableCell align="right">Fiber (g)</TableCell>
                        <TableCell align="right">Proteins (g)</TableCell>
                        <TableCell align="right">Salt (g)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows.map((row) => (
                        <TableRow
                            key={row.material_entity_id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">{row.material_entity_name}</TableCell>
                            <TableCell align="right">{formatKcal(row.ENERC)}</TableCell>
                            <TableCell align="right">{formatGrams(row.FAT)}</TableCell>
                            <TableCell align="right">{formatGrams(row.FASAT)}</TableCell>
                            <TableCell align="right">{formatGrams(row.CHO)}</TableCell>
                            <TableCell align="right">{formatGrams(row.SUGAR)}</TableCell>
                            <TableCell align="right">{formatGrams(row.FIBT)}</TableCell>
                            <TableCell align="right">{formatGrams(row.PROT)}</TableCell>
                            <TableCell align="right">{formatGrams(row.NACL)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


export default NutritionBreakdownTable;
