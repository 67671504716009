import React from 'react';
import {Box, Button, Typography, Menu, MenuItem} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// ----------------------------------------------------------------------

export default function SortIngredientCardList(
    props: {
        value: { property: string; order: string; label: string; };
        setValue: React.Dispatch<React.SetStateAction<{ property: string; order: string; label: string; }>>;
        sortOptions: { property: string; order: string; label: string }[];
    }
) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (option: { property: string; order: string; label: string }) => {
        props.setValue(option);
        handleClose();
    };

    return (
        <Box sx={{paddingTop: '12px', marginBottom: '16px'}}>
            {/* Current selection label */}
            <Button
                variant="outlined"
                color="primary"
                endIcon={<ArrowDropDownIcon/>}
                onClick={handleClick}
                sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                    borderRadius: '8px',
                    borderColor: 'primary.main',
                    color: 'primary.main',
                    textTransform: 'none',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    ':hover': {
                        backgroundColor: 'primary.light',
                        borderColor: 'primary.main',
                        boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.15)',
                    },
                }}
            >
                <Typography component="span" variant="subtitle2">
                    {props.value.label}
                </Typography>
            </Button>

            {/* Dropdown menu for sort options */}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        sx: {
                            maxHeight: 300,
                            width: 225,
                            borderRadius: '8px',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        },
                    }
                }}
            >
                {props.sortOptions.map((option) => (
                    <MenuItem
                        key={option.property}
                        onClick={() => handleSelect(option)}
                        selected={props.value.property === option.property && props.value.order === option.order}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px', // Set font size to 14px
                            }}
                        >
                            {option.label}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}
