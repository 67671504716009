import React from "react";
// @mui/material
import {Card, CardContent, CardHeader, CircularProgress, Grid2, Typography,} from "@mui/material";
import {Info, Warning} from "@mui/icons-material";
// material react table
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {formulationNutritionalPageQuery} from "../../../pages/FoodDetailPage";
import DataOperationStatus from "../../../components/data-operation-status/DataOperationStatus";
import {DataLoadingStatusHandler} from "../../../components/data-loading-status-handler";
import {NutritionTable, RelatedNutritionParametersTable} from "../foods/FoodNutritionSection";
import {ingredientDetailQuery} from "../../../pages/IngredientDetailPage";
import {ingredientTypes} from "../../../config";

// ----------------------------------------------------------------------
export default function IngredientNutritionSection() {
    let {ingredientId} = useParams();

    const {isLoading, data: ingredientData} = useQuery(
        ingredientDetailQuery(Number(ingredientId))
    )
    const {status: formulationNutritionalDataStatus, data: formulationNutritionalData} = useQuery(
        // @ts-ignore
        formulationNutritionalPageQuery(ingredientId)
    )

    return (
        <Grid2 container spacing={3}>
            {ingredientData?.type === "CPX" ?
                <>
                    <Grid2 size={12}>
                        <Card>
                            <CardHeader
                                title={"What is the nutrition panel?"}
                                avatar={
                                    <Info/>
                                }
                            />
                            <CardContent>
                                This panel includes all nutrients within a Food Product, it will also correlate them
                                with
                                your
                                choice of ingredients.
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={12}>
                        {
                            ingredientData?.type === "CPX" ?
                                <DataLoadingStatusHandler status={formulationNutritionalDataStatus}
                                                          successRender={
                                                              formulationNutritionalData?.results ?
                                                                  // @ts-ignore
                                                                  <NutritionTable material_entity={ingredientId}
                                                                                  measurements={formulationNutritionalData?.results}/>
                                                                  : <></>
                                                          }
                                                          loadingRender={
                                                              <DataOperationStatus
                                                                  icon={<CircularProgress/>}>
                                                                  <Typography color={"textSecondary"}>
                                                                      Analysing Product Nutritional Composition.
                                                                  </Typography>
                                                              </DataOperationStatus>
                                                          }
                                /> :
                                // @ts-ignore
                                <RelatedNutritionParametersTable material_entity={ingredientId}/>
                        }
                    </Grid2>
                </>
                :
                <Grid2 size={12}>
                    <DataOperationStatus icon={<Warning fontSize={"large"} color={"disabled"}/>}>
                        <Typography color={"textSecondary"}>
                            {/*@ts-ignore*/}
                            Feature not implemented for the "{ingredientTypes[ingredientData?.type]}"
                            ingredient type.
                        </Typography>
                    </DataOperationStatus>
                </Grid2>
            }
        </Grid2>
    )
}