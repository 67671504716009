import React, {SetStateAction, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {ingredientTypes} from "../../config";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2,
    IconButton,
    InputAdornment,
    ListItem,
    ListItemText,
    Stack,
    Typography
} from "@mui/material";
import {StyledSearch} from "../search";
import {Add, Close, Error, Search} from "@mui/icons-material";
import Pagination from "@mui/material/Pagination";
import {MaterialEntity} from "../../api";
import {
    creatorInitialState,
    IngredientFilters,
    ingredientsPageQuery,
    sortInitialState
} from "../../pages/IngredientsPage";
import {CreatorOption} from "../../sections/@dashboard/ingredients/ingredient-list-filters/CreatorIngredientCardList";
import {SortOption} from "../../utils/filteringAndSorting";
import {DataLoadingStatusHandler} from "../data-loading-status-handler";
import {DataList} from "../data-list";
import {DataOperationStatus} from "../data-operation-status";

export default function IngredientsModal(props: { setIngredient: any, open: any, handleClose: any }) {

    const [filters, setFilters] = useState<string[] | undefined>([]);
    const [searchQuery, setSearchQuery] = useState<string>(""); // Updated to string
    const [sort, setSort] = useState<SortOption>(sortInitialState);
    const [creator, setCreator] = useState<CreatorOption>(creatorInitialState);
    const [page, setPage] = useState<number>(1);
    const pageSize = 10
    const [type, setType] = useState<string[]>([]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number): void => {
        setPage(page);
    };

    const handleQueryByKey = (event: { target: { value: SetStateAction<string>; }; }) => {
        setSearchQuery(event.target.value);
    };

    const {
        data: ingredientsData,
        status: queryStatus,
    } = useQuery(
        ingredientsPageQuery(filters, creator.value, searchQuery, sort, page, pageSize, type)
    );

    function updateIngredients(ingredient: MaterialEntity) {
        props.setIngredient(ingredient)
        props.handleClose();
    }

    // Effect to reset the page to 1 whenever search query, type, annotations, or sorting changes
    useEffect(() => {
        setPage(1);
    }, [filters, searchQuery, sort, pageSize, type]);

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            fullScreen
        >
            <DialogTitle>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={props.handleClose}
                    aria-label="close"
                >
                    <Close/>
                </IconButton>
                Add Ingredient
            </DialogTitle>
            <DialogContent>
                <Grid2 container spacing={2}>
                    <Grid2 size={3}>
                        <IngredientFilters creator={creator}
                                           handleCreatorChange={setCreator}
                                           type={type}
                                           handleTypeChange={setType}
                                           sort={sort}
                                           handleSortChange={setSort}
                            // @ts-ignore
                                           filters={filters}
                            // @ts-ignore
                                           handleFiltersChange={setFilters}
                        />
                    </Grid2>
                    <Grid2 size={9}>
                        <Stack direction={"row"} spacing={4}>
                            <StyledSearch
                                fontSize={16}
                                //disabled
                                fullWidth
                                value={searchQuery}
                                onChange={handleQueryByKey}
                                placeholder="Search..."
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Search/>
                                    </InputAdornment>
                                }
                            />
                        </Stack>
                        <DataLoadingStatusHandler
                            status={queryStatus}
                            count={ingredientsData?.count}
                            successRender={
                                <DataList
                                    data={ingredientsData?.results}
                                    itemRender={(item) => (
                                        <ListItem
                                            key={item.id}
                                            secondaryAction={
                                                <IconButton
                                                    onClick={() => updateIngredients(item)}
                                                >
                                                    <Add/>
                                                </IconButton>
                                            }
                                        >
                                            <ListItemText
                                                primary={item.name}
                                                // @ts-ignore
                                                secondary={ingredientTypes[item.type]}
                                            />
                                        </ListItem>)}
                                />}
                            emptyRender={<DataOperationStatus
                                icon={<Search fontSize={"large"} color={"disabled"}/>}>
                                <Typography color={"textSecondary"}>
                                    No ingredient found.
                                </Typography>
                            </DataOperationStatus>}
                            errorRender={<DataOperationStatus
                                icon={<Error/>}>
                                <Typography>
                                    Error loading ingredients.
                                </Typography>
                            </DataOperationStatus>}
                            loadingRender={<DataOperationStatus
                                icon={<CircularProgress/>}>
                                <Typography color={"textSecondary"}>
                                    Loading ingredients.
                                </Typography>
                            </DataOperationStatus>}
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Grid2 size={12}>
                    <Stack spacing={2} alignItems={"center"}>
                        <Pagination
                            variant="outlined"
                            shape="rounded"
                            count={ingredientsData ? Math.ceil(ingredientsData.count / pageSize) : 0}
                            page={page}
                            onChange={handlePageChange}
                        />
                    </Stack>
                </Grid2>
            </DialogActions>
        </Dialog>
    )
}