/* tslint:disable */
/* eslint-disable */
/**
 * CibusAI API
 * API to access CibusAI backend services.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AnnotationLabel
 */
export interface AnnotationLabel {
    /**
     * 
     * @type {Array<string>}
     * @memberof AnnotationLabel
     */
    'EXT'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnnotationLabel
     */
    'REG'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnnotationLabel
     */
    'TST'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnnotationLabel
     */
    'ODO'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnnotationLabel
     */
    'TEC'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ChemicalEntity
 */
export interface ChemicalEntity {
    /**
     * 
     * @type {number}
     * @memberof ChemicalEntity
     */
    'id': number;
    /**
     * 
     * @type {Array<MaterialEntityAnnotation>}
     * @memberof ChemicalEntity
     */
    'annotations': Array<MaterialEntityAnnotation>;
    /**
     * 
     * @type {string}
     * @memberof ChemicalEntity
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof ChemicalEntity
     */
    'date_modified': string;
    /**
     * 
     * @type {boolean}
     * @memberof ChemicalEntity
     */
    'is_public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChemicalEntity
     */
    'cibus_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChemicalEntity
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof ChemicalEntity
     */
    'alternative_name'?: Array<string | null> | null;
    /**
     * 
     * @type {string}
     * @memberof ChemicalEntity
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChemicalEntity
     */
    'img'?: string | null;
    /**
     * 
     * @type {PhysicalStateEnum}
     * @memberof ChemicalEntity
     */
    'physical_state'?: PhysicalStateEnum;
    /**
     * 
     * @type {TypeEfbEnum}
     * @memberof ChemicalEntity
     */
    'type'?: TypeEfbEnum;
    /**
     * 
     * @type {string}
     * @memberof ChemicalEntity
     */
    'molecular_formula'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChemicalEntity
     */
    'molecular_weight'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ChemicalEntity
     */
    'iupac_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChemicalEntity
     */
    'inchi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChemicalEntity
     */
    'inchi_key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChemicalEntity
     */
    'canonical_smiles'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChemicalEntity
     */
    'isomeric_smiles'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChemicalEntity
     */
    'owner': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChemicalEntity
     */
    'contents': Array<number>;
}


/**
 * Serializer used to validate the completion message (only for the input).
 * @export
 * @interface Completion
 */
export interface Completion {
    /**
     * 
     * @type {Array<CompletionMessage>}
     * @memberof Completion
     */
    'messages': Array<CompletionMessage>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Completion
     */
    'tools_selected': Array<string>;
}
/**
 * Serializer used to display the messages nested in the completion output serializer
 * @export
 * @interface CompletionMessage
 */
export interface CompletionMessage {
    /**
     * 
     * @type {RoleEnum}
     * @memberof CompletionMessage
     */
    'role'?: RoleEnum;
    /**
     * 
     * @type {string}
     * @memberof CompletionMessage
     */
    'content'?: string | null;
}


/**
 * Serializer used to validate the completion message (only for the output).
 * @export
 * @interface CompletionSerializerOutput
 */
export interface CompletionSerializerOutput {
    /**
     * 
     * @type {Array<CompletionMessage>}
     * @memberof CompletionSerializerOutput
     */
    'messages': Array<CompletionMessage>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompletionSerializerOutput
     */
    'tools_selected': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompletionSerializerOutput
     */
    'tools_used': Array<string>;
    /**
     * 
     * @type {Array<{ [key: string]: any; }>}
     * @memberof CompletionSerializerOutput
     */
    'tool_results'?: Array<{ [key: string]: any; }>;
}
/**
 * 
 * @export
 * @interface CompositionSummary
 */
export interface CompositionSummary {
    /**
     * 
     * @type {number}
     * @memberof CompositionSummary
     */
    'total': number;
}
/**
 * Serializer for the conversation model. It\'s used to display the conversation in the conversation list view (list action).
 * @export
 * @interface Conversation
 */
export interface Conversation {
    /**
     * 
     * @type {number}
     * @memberof Conversation
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'session_id': string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Conversation
     */
    'user': number;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'updated_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof Conversation
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateFormulation
 */
export interface CreateFormulation {
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateFormulation
     */
    'contents': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateFormulation
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFormulation
     */
    'date_modified': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFormulation
     */
    'cibus_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateFormulation
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof CreateFormulation
     */
    'alternative_name'?: Array<string | null> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateFormulation
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateFormulation
     */
    'img'?: string | null;
    /**
     * 
     * @type {PhysicalStateEnum}
     * @memberof CreateFormulation
     */
    'physical_state'?: PhysicalStateEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateFormulation
     */
    'serving'?: number;
    /**
     * 
     * @type {ServingUnitEnum}
     * @memberof CreateFormulation
     */
    'serving_unit'?: ServingUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateFormulation
     */
    'price'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateFormulation
     */
    'price_unit'?: string;
}


/**
 * 
 * @export
 * @interface CreateMaterialEntity
 */
export interface CreateMaterialEntity {
    /**
     * 
     * @type {number}
     * @memberof CreateMaterialEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CreateMaterialEntity
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMaterialEntity
     */
    'date_modified': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateMaterialEntity
     */
    'is_public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateMaterialEntity
     */
    'cibus_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMaterialEntity
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof CreateMaterialEntity
     */
    'alternative_name'?: Array<string | null> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMaterialEntity
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMaterialEntity
     */
    'img'?: string | null;
    /**
     * 
     * @type {PhysicalStateEnum}
     * @memberof CreateMaterialEntity
     */
    'physical_state'?: PhysicalStateEnum;
    /**
     * 
     * @type {TypeEfbEnum}
     * @memberof CreateMaterialEntity
     */
    'type'?: TypeEfbEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateMaterialEntity
     */
    'contents': Array<number>;
}


/**
 * DO NOT USE THIS SERIALIZER TO CREATE/UPDATE OR SAVE DATA
 * @export
 * @interface CreateNestedFormulation
 */
export interface CreateNestedFormulation {
    /**
     * 
     * @type {Array<NestedFormulationContents>}
     * @memberof CreateNestedFormulation
     */
    'contents': Array<NestedFormulationContents>;
    /**
     * 
     * @type {string}
     * @memberof CreateNestedFormulation
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNestedFormulation
     */
    'date_modified': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateNestedFormulation
     */
    'is_public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateNestedFormulation
     */
    'cibus_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNestedFormulation
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof CreateNestedFormulation
     */
    'alternative_name'?: Array<string | null> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNestedFormulation
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNestedFormulation
     */
    'img'?: string | null;
    /**
     * 
     * @type {PhysicalStateEnum}
     * @memberof CreateNestedFormulation
     */
    'physical_state'?: PhysicalStateEnum;
    /**
     * 
     * @type {TypeEfbEnum}
     * @memberof CreateNestedFormulation
     */
    'type'?: TypeEfbEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateNestedFormulation
     */
    'serving'?: number;
    /**
     * 
     * @type {ServingUnitEnum}
     * @memberof CreateNestedFormulation
     */
    'serving_unit'?: ServingUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateNestedFormulation
     */
    'price'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNestedFormulation
     */
    'price_unit'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateNestedFormulation
     */
    'owner': number;
}


/**
 * 
 * @export
 * @interface CreateNutritionalMeasurement
 */
export interface CreateNutritionalMeasurement {
    /**
     * 
     * @type {number}
     * @memberof CreateNutritionalMeasurement
     */
    'parameter': number;
    /**
     * 
     * @type {number}
     * @memberof CreateNutritionalMeasurement
     */
    'measurement': number;
    /**
     * 
     * @type {number}
     * @memberof CreateNutritionalMeasurement
     */
    'material_entity': number;
}
/**
 * 
 * @export
 * @interface CreateProduct
 */
export interface CreateProduct {
    /**
     * 
     * @type {number}
     * @memberof CreateProduct
     */
    'id': number;
    /**
     * 
     * @type {Array<CreateNestedFormulation>}
     * @memberof CreateProduct
     */
    'formulations'?: Array<CreateNestedFormulation>;
    /**
     * 
     * @type {string}
     * @memberof CreateProduct
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProduct
     */
    'date_modified': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProduct
     */
    'is_public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateProduct
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProduct
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProduct
     */
    'img'?: string | null;
}
/**
 * * `g` - g * `mg` - mg * `μg` - μg * `kcal` - kcal * `kj` - kj
 * @export
 * @enum {string}
 */

export const DefaultUnitEnum = {
    G: 'g',
    Mg: 'mg',
    G2: 'μg',
    Kcal: 'kcal',
    Kj: 'kj'
} as const;

export type DefaultUnitEnum = typeof DefaultUnitEnum[keyof typeof DefaultUnitEnum];


/**
 * * `regulatory` - Regulatory * `experimental` - Experimental * `inferred` - Inferred * `prediction` - Predicted * `uncertain` - Uncertain
 * @export
 * @enum {string}
 */

export const EvidenceTypeEnum = {
    Regulatory: 'regulatory',
    Experimental: 'experimental',
    Inferred: 'inferred',
    Prediction: 'prediction',
    Uncertain: 'uncertain'
} as const;

export type EvidenceTypeEnum = typeof EvidenceTypeEnum[keyof typeof EvidenceTypeEnum];


/**
 * * `-1` - Negative * `0` - Neutral * `1` - Positive
 * @export
 * @enum {string}
 */

export const FeedbackEnum = {
    NUMBER_MINUS_1: -1,
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type FeedbackEnum = typeof FeedbackEnum[keyof typeof FeedbackEnum];


/**
 * 
 * @export
 * @interface FoodProductsOverview
 */
export interface FoodProductsOverview {
    /**
     * 
     * @type {number}
     * @memberof FoodProductsOverview
     */
    'total_count': number;
    /**
     * 
     * @type {Array<Product>}
     * @memberof FoodProductsOverview
     */
    'latest': Array<Product>;
    /**
     * 
     * @type {number}
     * @memberof FoodProductsOverview
     */
    'average_formulation_count': number;
}
/**
 * 
 * @export
 * @interface Formulation
 */
export interface Formulation {
    /**
     * 
     * @type {number}
     * @memberof Formulation
     */
    'id': number;
    /**
     * 
     * @type {Array<MaterialEntity>}
     * @memberof Formulation
     */
    'contents': Array<MaterialEntity> | null;
    /**
     * 
     * @type {string}
     * @memberof Formulation
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof Formulation
     */
    'date_modified': string;
    /**
     * 
     * @type {boolean}
     * @memberof Formulation
     */
    'is_public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Formulation
     */
    'cibus_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Formulation
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof Formulation
     */
    'alternative_name'?: Array<string | null> | null;
    /**
     * 
     * @type {string}
     * @memberof Formulation
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Formulation
     */
    'img'?: string | null;
    /**
     * 
     * @type {PhysicalStateEnum}
     * @memberof Formulation
     */
    'physical_state'?: PhysicalStateEnum;
    /**
     * 
     * @type {TypeEfbEnum}
     * @memberof Formulation
     */
    'type'?: TypeEfbEnum;
    /**
     * 
     * @type {number}
     * @memberof Formulation
     */
    'serving'?: number;
    /**
     * 
     * @type {ServingUnitEnum}
     * @memberof Formulation
     */
    'serving_unit'?: ServingUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Formulation
     */
    'price'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Formulation
     */
    'price_unit'?: string;
    /**
     * 
     * @type {number}
     * @memberof Formulation
     */
    'owner': number;
    /**
     * 
     * @type {number}
     * @memberof Formulation
     */
    'product': number;
}


/**
 * 
 * @export
 * @interface FormulationCompact
 */
export interface FormulationCompact {
    /**
     * 
     * @type {number}
     * @memberof FormulationCompact
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FormulationCompact
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormulationCompact
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof FormulationCompact
     */
    'date_modified': string;
}
/**
 * 
 * @export
 * @interface MaterialEntity
 */
export interface MaterialEntity {
    /**
     * 
     * @type {number}
     * @memberof MaterialEntity
     */
    'id': number;
    /**
     * 
     * @type {Array<MaterialEntityAnnotation>}
     * @memberof MaterialEntity
     */
    'annotations': Array<MaterialEntityAnnotation>;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntity
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntity
     */
    'date_modified': string;
    /**
     * 
     * @type {boolean}
     * @memberof MaterialEntity
     */
    'is_public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntity
     */
    'cibus_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntity
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof MaterialEntity
     */
    'alternative_name'?: Array<string | null> | null;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntity
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntity
     */
    'img'?: string | null;
    /**
     * 
     * @type {PhysicalStateEnum}
     * @memberof MaterialEntity
     */
    'physical_state'?: PhysicalStateEnum;
    /**
     * 
     * @type {TypeEfbEnum}
     * @memberof MaterialEntity
     */
    'type'?: TypeEfbEnum;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntity
     */
    'owner': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof MaterialEntity
     */
    'contents': Array<number>;
}


/**
 * 
 * @export
 * @interface MaterialEntityAlternatives
 */
export interface MaterialEntityAlternatives {
    /**
     * 
     * @type {Array<string>}
     * @memberof MaterialEntityAlternatives
     */
    'alternatives': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityAlternatives
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface MaterialEntityAnnotation
 */
export interface MaterialEntityAnnotation {
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityAnnotation
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntityAnnotation
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntityAnnotation
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntityAnnotation
     */
    'date_modified': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntityAnnotation
     */
    'cibus_id'?: string | null;
    /**
     * 
     * @type {MaterialEntityAnnotationTypeEnum}
     * @memberof MaterialEntityAnnotation
     */
    'type': MaterialEntityAnnotationTypeEnum;
    /**
     * 
     * @type {EvidenceTypeEnum}
     * @memberof MaterialEntityAnnotation
     */
    'evidence_type'?: EvidenceTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntityAnnotation
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntityAnnotation
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntityAnnotation
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntityAnnotation
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityAnnotation
     */
    'prediction_source'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityAnnotation
     */
    'material_entity': number;
}


/**
 * 
 * @export
 * @interface MaterialEntityAnnotationSummary
 */
export interface MaterialEntityAnnotationSummary {
    /**
     * 
     * @type {Array<MaterialEntityLabelAnnotationSummary>}
     * @memberof MaterialEntityAnnotationSummary
     */
    'REG'?: Array<MaterialEntityLabelAnnotationSummary>;
    /**
     * 
     * @type {Array<MaterialEntityLabelAnnotationSummary>}
     * @memberof MaterialEntityAnnotationSummary
     */
    'TST'?: Array<MaterialEntityLabelAnnotationSummary>;
    /**
     * 
     * @type {Array<MaterialEntityLabelAnnotationSummary>}
     * @memberof MaterialEntityAnnotationSummary
     */
    'ODO'?: Array<MaterialEntityLabelAnnotationSummary>;
    /**
     * 
     * @type {Array<MaterialEntityLabelAnnotationSummary>}
     * @memberof MaterialEntityAnnotationSummary
     */
    'TEC'?: Array<MaterialEntityLabelAnnotationSummary>;
}
/**
 * * `EXT` - External ID * `LBL` - Label * `GEO` - Geography * `SRC` - Source * `REG` - Regulatory * `TST` - Taste * `TEX` - Texture * `ODO` - Aroma & Odor * `HLT` - Health * `FUC` - Functional * `COL` - Color * `PAT` - Patent * `RSC` - Research * `TEC` - Technical Effect * `CMP` - Chemical Property
 * @export
 * @enum {string}
 */

export const MaterialEntityAnnotationTypeEnum = {
    Ext: 'EXT',
    Lbl: 'LBL',
    Geo: 'GEO',
    Src: 'SRC',
    Reg: 'REG',
    Tst: 'TST',
    Tex: 'TEX',
    Odo: 'ODO',
    Hlt: 'HLT',
    Fuc: 'FUC',
    Col: 'COL',
    Pat: 'PAT',
    Rsc: 'RSC',
    Tec: 'TEC',
    Cmp: 'CMP'
} as const;

export type MaterialEntityAnnotationTypeEnum = typeof MaterialEntityAnnotationTypeEnum[keyof typeof MaterialEntityAnnotationTypeEnum];


/**
 * 
 * @export
 * @interface MaterialEntityComponentNutritionalSummary
 */
export interface MaterialEntityComponentNutritionalSummary {
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityComponentNutritionalSummary
     */
    'material_entity_id': number;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntityComponentNutritionalSummary
     */
    'material_entity_name': string;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityComponentNutritionalSummary
     */
    'ENERC': number;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityComponentNutritionalSummary
     */
    'FAT': number;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityComponentNutritionalSummary
     */
    'FASAT': number;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityComponentNutritionalSummary
     */
    'CHO': number;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityComponentNutritionalSummary
     */
    'SUGAR': number;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityComponentNutritionalSummary
     */
    'NACL': number;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityComponentNutritionalSummary
     */
    'PROT': number;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityComponentNutritionalSummary
     */
    'FIBT': number;
}
/**
 * 
 * @export
 * @interface MaterialEntityComposition
 */
export interface MaterialEntityComposition {
    /**
     * 
     * @type {Array<MaterialEntityCompositionRecord>}
     * @memberof MaterialEntityComposition
     */
    'composition': Array<MaterialEntityCompositionRecord>;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityComposition
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface MaterialEntityCompositionRecord
 */
export interface MaterialEntityCompositionRecord {
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityCompositionRecord
     */
    'child_material_entity_id': number;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntityCompositionRecord
     */
    'name': string;
    /**
     * 
     * @type {TypeEfbEnum}
     * @memberof MaterialEntityCompositionRecord
     */
    'type': TypeEfbEnum;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityCompositionRecord
     */
    'total_amount': number;
}


/**
 * 
 * @export
 * @interface MaterialEntityCompositionSummary
 */
export interface MaterialEntityCompositionSummary {
    /**
     * 
     * @type {CompositionSummary}
     * @memberof MaterialEntityCompositionSummary
     */
    'composition': CompositionSummary;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MaterialEntityCompositionSummary
     */
    'nutrition': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MaterialEntityCompositionSummary
     */
    'nutrition_per_portion': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof MaterialEntityCompositionSummary
     */
    'nutrition_claims': { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface MaterialEntityContainsMaterialEntity
 */
export interface MaterialEntityContainsMaterialEntity {
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityContainsMaterialEntity
     */
    'id': number;
    /**
     * 
     * @type {MaterialEntity}
     * @memberof MaterialEntityContainsMaterialEntity
     */
    'child_material_entity': MaterialEntity;
    /**
     * 
     * @type {MaterialEntity}
     * @memberof MaterialEntityContainsMaterialEntity
     */
    'material_entity': MaterialEntity;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntityContainsMaterialEntity
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntityContainsMaterialEntity
     */
    'date_modified': string;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityContainsMaterialEntity
     */
    'amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntityContainsMaterialEntity
     */
    'alias_name'?: string | null;
    /**
     * 
     * @type {UnitEnum}
     * @memberof MaterialEntityContainsMaterialEntity
     */
    'unit'?: UnitEnum;
}


/**
 * 
 * @export
 * @interface MaterialEntityLabelAnnotationSummary
 */
export interface MaterialEntityLabelAnnotationSummary {
    /**
     * 
     * @type {string}
     * @memberof MaterialEntityLabelAnnotationSummary
     */
    'label': string;
    /**
     * 
     * @type {Array<MaterialEntityTuple>}
     * @memberof MaterialEntityLabelAnnotationSummary
     */
    'entities': Array<MaterialEntityTuple>;
}
/**
 * 
 * @export
 * @interface MaterialEntityNutritionalBreakdown
 */
export interface MaterialEntityNutritionalBreakdown {
    /**
     * 
     * @type {Array<MaterialEntityComponentNutritionalSummary>}
     * @memberof MaterialEntityNutritionalBreakdown
     */
    'breakdown': Array<MaterialEntityComponentNutritionalSummary>;
}
/**
 * 
 * @export
 * @interface MaterialEntityTuple
 */
export interface MaterialEntityTuple {
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityTuple
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MaterialEntityTuple
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface MaterialEntityTypeCount
 */
export interface MaterialEntityTypeCount {
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityTypeCount
     */
    'COM'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityTypeCount
     */
    'CPX'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityTypeCount
     */
    'PRO'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MaterialEntityTypeCount
     */
    'OTH'?: number | null;
}
/**
 * 
 * @export
 * @interface MaterialLibraryOverview
 */
export interface MaterialLibraryOverview {
    /**
     * 
     * @type {number}
     * @memberof MaterialLibraryOverview
     */
    'total_count': number;
    /**
     * 
     * @type {Array<MaterialEntity>}
     * @memberof MaterialLibraryOverview
     */
    'latest': Array<MaterialEntity>;
    /**
     * 
     * @type {MaterialEntityTypeCount}
     * @memberof MaterialLibraryOverview
     */
    'count_by_type': MaterialEntityTypeCount;
}
/**
 * Serializer for the message model.
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    'id': number;
    /**
     * 
     * @type {RoleEnum}
     * @memberof Message
     */
    'role'?: RoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'content'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Message
     */
    'tools_selected': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Message
     */
    'tools_used': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'timestamp'?: string;
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    'conversation'?: number | null;
    /**
     * 
     * @type {Array<FeedbackEnum>}
     * @memberof Message
     */
    'feedback': Array<FeedbackEnum>;
}


/**
 * Serializer for the message feedback model.
 * @export
 * @interface MessageFeedback
 */
export interface MessageFeedback {
    /**
     * 
     * @type {number}
     * @memberof MessageFeedback
     */
    'message': number;
    /**
     * 
     * @type {FeedbackEnum}
     * @memberof MessageFeedback
     */
    'feedback'?: FeedbackEnum;
}


/**
 * 
 * @export
 * @interface ModelEntity
 */
export interface ModelEntity {
    /**
     * 
     * @type {number}
     * @memberof ModelEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ModelEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ModelEntity
     */
    'run_id': string;
    /**
     * 
     * @type {number}
     * @memberof ModelEntity
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof ModelEntity
     */
    'endpoint'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModelEntity
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof ModelEntity
     */
    'date_modified': string;
}
/**
 * DO NOT USE THIS SERIALIZER TO CREATE/UPDATE OR SAVE DATA
 * @export
 * @interface NestedFormulationContents
 */
export interface NestedFormulationContents {
    /**
     * 
     * @type {string}
     * @memberof NestedFormulationContents
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof NestedFormulationContents
     */
    'date_modified': string;
    /**
     * 
     * @type {number}
     * @memberof NestedFormulationContents
     */
    'amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NestedFormulationContents
     */
    'alias_name'?: string | null;
    /**
     * 
     * @type {UnitEnum}
     * @memberof NestedFormulationContents
     */
    'unit'?: UnitEnum;
    /**
     * 
     * @type {number}
     * @memberof NestedFormulationContents
     */
    'child_material_entity': number;
}


/**
 * Serializer for nested messages in the conversation serializer. Nested messages are used to display the messages in the single conversation serializer. It\'s a simplified version of the message serializer with only the id, role, content, tools selected, and tools used fields.
 * @export
 * @interface NestedMessage
 */
export interface NestedMessage {
    /**
     * 
     * @type {number}
     * @memberof NestedMessage
     */
    'id': number;
    /**
     * 
     * @type {RoleEnum}
     * @memberof NestedMessage
     */
    'role'?: RoleEnum;
    /**
     * 
     * @type {string}
     * @memberof NestedMessage
     */
    'content'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NestedMessage
     */
    'tools_selected': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NestedMessage
     */
    'tools_used': Array<string>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const NullEnum = {
    Null: 'null'
} as const;

export type NullEnum = typeof NullEnum[keyof typeof NullEnum];


/**
 * 
 * @export
 * @interface NutritionalMeasurement
 */
export interface NutritionalMeasurement {
    /**
     * 
     * @type {number}
     * @memberof NutritionalMeasurement
     */
    'id': number;
    /**
     * 
     * @type {NutritionalParameter}
     * @memberof NutritionalMeasurement
     */
    'parameter': NutritionalParameter;
    /**
     * 
     * @type {number}
     * @memberof NutritionalMeasurement
     */
    'measurement': number;
    /**
     * 
     * @type {number}
     * @memberof NutritionalMeasurement
     */
    'material_entity': number;
}
/**
 * 
 * @export
 * @interface NutritionalParameter
 */
export interface NutritionalParameter {
    /**
     * 
     * @type {number}
     * @memberof NutritionalParameter
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof NutritionalParameter
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof NutritionalParameter
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NutritionalParameter
     */
    'caloric_multiplier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof NutritionalParameter
     */
    'mandatory_labeling'?: boolean;
    /**
     * 
     * @type {DefaultUnitEnum}
     * @memberof NutritionalParameter
     */
    'default_unit'?: DefaultUnitEnum | null;
    /**
     * 
     * @type {string}
     * @memberof NutritionalParameter
     */
    'eurofir_code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NutritionalParameter
     */
    'parent_parameter'?: number | null;
}


/**
 * 
 * @export
 * @interface NutritionalScores
 */
export interface NutritionalScores {
    /**
     * 
     * @type {number}
     * @memberof NutritionalScores
     */
    'points': number;
    /**
     * 
     * @type {string}
     * @memberof NutritionalScores
     */
    'score': string;
}
/**
 * 
 * @export
 * @interface NutritionalScoresInputs
 */
export interface NutritionalScoresInputs {
    /**
     * 
     * @type {ProductTypeEnum}
     * @memberof NutritionalScoresInputs
     */
    'product_type': ProductTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NutritionalScoresInputs
     */
    'fruits_vegetables_legumes_percent': string;
    /**
     * 
     * @type {boolean}
     * @memberof NutritionalScoresInputs
     */
    'contains_non_nutritive_sweeteners'?: boolean;
}


/**
 * Core overview endpoint serializer.
 * @export
 * @interface Overview
 */
export interface Overview {
    /**
     * 
     * @type {FoodProductsOverview}
     * @memberof Overview
     */
    'food_products': FoodProductsOverview;
    /**
     * 
     * @type {MaterialLibraryOverview}
     * @memberof Overview
     */
    'material_library': MaterialLibraryOverview;
    /**
     * 
     * @type {FoodProductsOverview}
     * @memberof Overview
     */
    'reformulations': FoodProductsOverview;
}
/**
 * 
 * @export
 * @interface PaginatedChemicalEntityList
 */
export interface PaginatedChemicalEntityList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedChemicalEntityList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedChemicalEntityList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedChemicalEntityList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<ChemicalEntity>}
     * @memberof PaginatedChemicalEntityList
     */
    'results': Array<ChemicalEntity>;
}
/**
 * 
 * @export
 * @interface PaginatedConversationList
 */
export interface PaginatedConversationList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedConversationList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedConversationList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedConversationList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Conversation>}
     * @memberof PaginatedConversationList
     */
    'results': Array<Conversation>;
}
/**
 * 
 * @export
 * @interface PaginatedFormulationList
 */
export interface PaginatedFormulationList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedFormulationList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedFormulationList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedFormulationList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Formulation>}
     * @memberof PaginatedFormulationList
     */
    'results': Array<Formulation>;
}
/**
 * 
 * @export
 * @interface PaginatedMaterialEntityAnnotationList
 */
export interface PaginatedMaterialEntityAnnotationList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedMaterialEntityAnnotationList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMaterialEntityAnnotationList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMaterialEntityAnnotationList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<MaterialEntityAnnotation>}
     * @memberof PaginatedMaterialEntityAnnotationList
     */
    'results': Array<MaterialEntityAnnotation>;
}
/**
 * 
 * @export
 * @interface PaginatedMaterialEntityContainsMaterialEntityList
 */
export interface PaginatedMaterialEntityContainsMaterialEntityList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedMaterialEntityContainsMaterialEntityList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMaterialEntityContainsMaterialEntityList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMaterialEntityContainsMaterialEntityList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<MaterialEntityContainsMaterialEntity>}
     * @memberof PaginatedMaterialEntityContainsMaterialEntityList
     */
    'results': Array<MaterialEntityContainsMaterialEntity>;
}
/**
 * 
 * @export
 * @interface PaginatedMaterialEntityList
 */
export interface PaginatedMaterialEntityList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedMaterialEntityList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMaterialEntityList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMaterialEntityList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<MaterialEntity>}
     * @memberof PaginatedMaterialEntityList
     */
    'results': Array<MaterialEntity>;
}
/**
 * 
 * @export
 * @interface PaginatedMessageList
 */
export interface PaginatedMessageList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedMessageList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMessageList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMessageList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Message>}
     * @memberof PaginatedMessageList
     */
    'results': Array<Message>;
}
/**
 * 
 * @export
 * @interface PaginatedModelEntityList
 */
export interface PaginatedModelEntityList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedModelEntityList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedModelEntityList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedModelEntityList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<ModelEntity>}
     * @memberof PaginatedModelEntityList
     */
    'results': Array<ModelEntity>;
}
/**
 * 
 * @export
 * @interface PaginatedNutritionalMeasurementList
 */
export interface PaginatedNutritionalMeasurementList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedNutritionalMeasurementList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedNutritionalMeasurementList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedNutritionalMeasurementList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<NutritionalMeasurement>}
     * @memberof PaginatedNutritionalMeasurementList
     */
    'results': Array<NutritionalMeasurement>;
}
/**
 * 
 * @export
 * @interface PaginatedNutritionalParameterList
 */
export interface PaginatedNutritionalParameterList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedNutritionalParameterList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedNutritionalParameterList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedNutritionalParameterList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<NutritionalParameter>}
     * @memberof PaginatedNutritionalParameterList
     */
    'results': Array<NutritionalParameter>;
}
/**
 * 
 * @export
 * @interface PaginatedProductList
 */
export interface PaginatedProductList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedProductList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedProductList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedProductList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Product>}
     * @memberof PaginatedProductList
     */
    'results': Array<Product>;
}
/**
 * 
 * @export
 * @interface PaginatedProteinEntityList
 */
export interface PaginatedProteinEntityList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedProteinEntityList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedProteinEntityList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedProteinEntityList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<ProteinEntity>}
     * @memberof PaginatedProteinEntityList
     */
    'results': Array<ProteinEntity>;
}
/**
 * 
 * @export
 * @interface PaginatedToolList
 */
export interface PaginatedToolList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedToolList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedToolList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedToolList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Tool>}
     * @memberof PaginatedToolList
     */
    'results': Array<Tool>;
}
/**
 * 
 * @export
 * @interface PatchedChemicalEntity
 */
export interface PatchedChemicalEntity {
    /**
     * 
     * @type {number}
     * @memberof PatchedChemicalEntity
     */
    'id'?: number;
    /**
     * 
     * @type {Array<MaterialEntityAnnotation>}
     * @memberof PatchedChemicalEntity
     */
    'annotations'?: Array<MaterialEntityAnnotation>;
    /**
     * 
     * @type {string}
     * @memberof PatchedChemicalEntity
     */
    'date_created'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedChemicalEntity
     */
    'date_modified'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedChemicalEntity
     */
    'is_public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedChemicalEntity
     */
    'cibus_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedChemicalEntity
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof PatchedChemicalEntity
     */
    'alternative_name'?: Array<string | null> | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedChemicalEntity
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedChemicalEntity
     */
    'img'?: string | null;
    /**
     * 
     * @type {PhysicalStateEnum}
     * @memberof PatchedChemicalEntity
     */
    'physical_state'?: PhysicalStateEnum;
    /**
     * 
     * @type {TypeEfbEnum}
     * @memberof PatchedChemicalEntity
     */
    'type'?: TypeEfbEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedChemicalEntity
     */
    'molecular_formula'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedChemicalEntity
     */
    'molecular_weight'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedChemicalEntity
     */
    'iupac_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedChemicalEntity
     */
    'inchi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedChemicalEntity
     */
    'inchi_key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedChemicalEntity
     */
    'canonical_smiles'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedChemicalEntity
     */
    'isomeric_smiles'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedChemicalEntity
     */
    'owner'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedChemicalEntity
     */
    'contents'?: Array<number>;
}


/**
 * 
 * @export
 * @interface PatchedFormulation
 */
export interface PatchedFormulation {
    /**
     * 
     * @type {number}
     * @memberof PatchedFormulation
     */
    'id'?: number;
    /**
     * 
     * @type {Array<MaterialEntity>}
     * @memberof PatchedFormulation
     */
    'contents'?: Array<MaterialEntity> | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedFormulation
     */
    'date_created'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedFormulation
     */
    'date_modified'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedFormulation
     */
    'is_public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedFormulation
     */
    'cibus_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedFormulation
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof PatchedFormulation
     */
    'alternative_name'?: Array<string | null> | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedFormulation
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedFormulation
     */
    'img'?: string | null;
    /**
     * 
     * @type {PhysicalStateEnum}
     * @memberof PatchedFormulation
     */
    'physical_state'?: PhysicalStateEnum;
    /**
     * 
     * @type {TypeEfbEnum}
     * @memberof PatchedFormulation
     */
    'type'?: TypeEfbEnum;
    /**
     * 
     * @type {number}
     * @memberof PatchedFormulation
     */
    'serving'?: number;
    /**
     * 
     * @type {ServingUnitEnum}
     * @memberof PatchedFormulation
     */
    'serving_unit'?: ServingUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof PatchedFormulation
     */
    'price'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedFormulation
     */
    'price_unit'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedFormulation
     */
    'owner'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedFormulation
     */
    'product'?: number;
}


/**
 * 
 * @export
 * @interface PatchedMaterialEntity
 */
export interface PatchedMaterialEntity {
    /**
     * 
     * @type {number}
     * @memberof PatchedMaterialEntity
     */
    'id'?: number;
    /**
     * 
     * @type {Array<MaterialEntityAnnotation>}
     * @memberof PatchedMaterialEntity
     */
    'annotations'?: Array<MaterialEntityAnnotation>;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntity
     */
    'date_created'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntity
     */
    'date_modified'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedMaterialEntity
     */
    'is_public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntity
     */
    'cibus_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntity
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof PatchedMaterialEntity
     */
    'alternative_name'?: Array<string | null> | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntity
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntity
     */
    'img'?: string | null;
    /**
     * 
     * @type {PhysicalStateEnum}
     * @memberof PatchedMaterialEntity
     */
    'physical_state'?: PhysicalStateEnum;
    /**
     * 
     * @type {TypeEfbEnum}
     * @memberof PatchedMaterialEntity
     */
    'type'?: TypeEfbEnum;
    /**
     * 
     * @type {number}
     * @memberof PatchedMaterialEntity
     */
    'owner'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedMaterialEntity
     */
    'contents'?: Array<number>;
}


/**
 * 
 * @export
 * @interface PatchedMaterialEntityAnnotation
 */
export interface PatchedMaterialEntityAnnotation {
    /**
     * 
     * @type {number}
     * @memberof PatchedMaterialEntityAnnotation
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntityAnnotation
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntityAnnotation
     */
    'date_created'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntityAnnotation
     */
    'date_modified'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntityAnnotation
     */
    'cibus_id'?: string | null;
    /**
     * 
     * @type {MaterialEntityAnnotationTypeEnum}
     * @memberof PatchedMaterialEntityAnnotation
     */
    'type'?: MaterialEntityAnnotationTypeEnum;
    /**
     * 
     * @type {EvidenceTypeEnum}
     * @memberof PatchedMaterialEntityAnnotation
     */
    'evidence_type'?: EvidenceTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntityAnnotation
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntityAnnotation
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntityAnnotation
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntityAnnotation
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedMaterialEntityAnnotation
     */
    'prediction_source'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedMaterialEntityAnnotation
     */
    'material_entity'?: number;
}


/**
 * 
 * @export
 * @interface PatchedMaterialEntityContainsMaterialEntity
 */
export interface PatchedMaterialEntityContainsMaterialEntity {
    /**
     * 
     * @type {number}
     * @memberof PatchedMaterialEntityContainsMaterialEntity
     */
    'id'?: number;
    /**
     * 
     * @type {MaterialEntity}
     * @memberof PatchedMaterialEntityContainsMaterialEntity
     */
    'child_material_entity'?: MaterialEntity;
    /**
     * 
     * @type {MaterialEntity}
     * @memberof PatchedMaterialEntityContainsMaterialEntity
     */
    'material_entity'?: MaterialEntity;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntityContainsMaterialEntity
     */
    'date_created'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntityContainsMaterialEntity
     */
    'date_modified'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedMaterialEntityContainsMaterialEntity
     */
    'amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedMaterialEntityContainsMaterialEntity
     */
    'alias_name'?: string | null;
    /**
     * 
     * @type {UnitEnum}
     * @memberof PatchedMaterialEntityContainsMaterialEntity
     */
    'unit'?: UnitEnum;
}


/**
 * 
 * @export
 * @interface PatchedNutritionalMeasurement
 */
export interface PatchedNutritionalMeasurement {
    /**
     * 
     * @type {number}
     * @memberof PatchedNutritionalMeasurement
     */
    'id'?: number;
    /**
     * 
     * @type {NutritionalParameter}
     * @memberof PatchedNutritionalMeasurement
     */
    'parameter'?: NutritionalParameter;
    /**
     * 
     * @type {number}
     * @memberof PatchedNutritionalMeasurement
     */
    'measurement'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedNutritionalMeasurement
     */
    'material_entity'?: number;
}
/**
 * 
 * @export
 * @interface PatchedNutritionalParameter
 */
export interface PatchedNutritionalParameter {
    /**
     * 
     * @type {number}
     * @memberof PatchedNutritionalParameter
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedNutritionalParameter
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedNutritionalParameter
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedNutritionalParameter
     */
    'caloric_multiplier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNutritionalParameter
     */
    'mandatory_labeling'?: boolean;
    /**
     * 
     * @type {DefaultUnitEnum}
     * @memberof PatchedNutritionalParameter
     */
    'default_unit'?: DefaultUnitEnum | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedNutritionalParameter
     */
    'eurofir_code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedNutritionalParameter
     */
    'parent_parameter'?: number | null;
}


/**
 * 
 * @export
 * @interface PatchedProduct
 */
export interface PatchedProduct {
    /**
     * 
     * @type {number}
     * @memberof PatchedProduct
     */
    'id'?: number;
    /**
     * 
     * @type {Array<FormulationCompact>}
     * @memberof PatchedProduct
     */
    'formulations'?: Array<FormulationCompact>;
    /**
     * 
     * @type {string}
     * @memberof PatchedProduct
     */
    'date_created'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedProduct
     */
    'date_modified'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedProduct
     */
    'is_public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedProduct
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedProduct
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedProduct
     */
    'img'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedProduct
     */
    'owner'?: number;
}
/**
 * 
 * @export
 * @interface PatchedProteinEntity
 */
export interface PatchedProteinEntity {
    /**
     * 
     * @type {number}
     * @memberof PatchedProteinEntity
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedProteinEntity
     */
    'date_created'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedProteinEntity
     */
    'date_modified'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedProteinEntity
     */
    'is_public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedProteinEntity
     */
    'cibus_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedProteinEntity
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof PatchedProteinEntity
     */
    'alternative_name'?: Array<string | null> | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedProteinEntity
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedProteinEntity
     */
    'img'?: string | null;
    /**
     * 
     * @type {PhysicalStateEnum}
     * @memberof PatchedProteinEntity
     */
    'physical_state'?: PhysicalStateEnum;
    /**
     * 
     * @type {TypeEfbEnum}
     * @memberof PatchedProteinEntity
     */
    'type'?: TypeEfbEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedProteinEntity
     */
    'sequence'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedProteinEntity
     */
    'owner'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedProteinEntity
     */
    'contents'?: Array<number>;
}


/**
 * * `solid` - solid * `liquid` - liquid
 * @export
 * @enum {string}
 */

export const PhysicalStateEnum = {
    Solid: 'solid',
    Liquid: 'liquid'
} as const;

export type PhysicalStateEnum = typeof PhysicalStateEnum[keyof typeof PhysicalStateEnum];


/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'id': number;
    /**
     * 
     * @type {Array<FormulationCompact>}
     * @memberof Product
     */
    'formulations': Array<FormulationCompact>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'date_modified': string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'is_public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'img'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'owner': number;
}
/**
 * * `generic` - generic * `generic-meat` - generic-meat * `generic-cheese` - generic-cheese * `fats_and_seeds` - fats_and_seeds * `beverages` - beverages
 * @export
 * @enum {string}
 */

export const ProductTypeEnum = {
    Generic: 'generic',
    GenericMeat: 'generic-meat',
    GenericCheese: 'generic-cheese',
    FatsAndSeeds: 'fats_and_seeds',
    Beverages: 'beverages'
} as const;

export type ProductTypeEnum = typeof ProductTypeEnum[keyof typeof ProductTypeEnum];


/**
 * 
 * @export
 * @interface ProteinEntity
 */
export interface ProteinEntity {
    /**
     * 
     * @type {number}
     * @memberof ProteinEntity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProteinEntity
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof ProteinEntity
     */
    'date_modified': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProteinEntity
     */
    'is_public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProteinEntity
     */
    'cibus_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProteinEntity
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof ProteinEntity
     */
    'alternative_name'?: Array<string | null> | null;
    /**
     * 
     * @type {string}
     * @memberof ProteinEntity
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProteinEntity
     */
    'img'?: string | null;
    /**
     * 
     * @type {PhysicalStateEnum}
     * @memberof ProteinEntity
     */
    'physical_state'?: PhysicalStateEnum;
    /**
     * 
     * @type {TypeEfbEnum}
     * @memberof ProteinEntity
     */
    'type'?: TypeEfbEnum;
    /**
     * 
     * @type {string}
     * @memberof ProteinEntity
     */
    'sequence'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProteinEntity
     */
    'owner': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProteinEntity
     */
    'contents': Array<number>;
}


/**
 * * `user` - user * `assistant` - assistant * `error` - error * `system` - system
 * @export
 * @enum {string}
 */

export const RoleEnum = {
    User: 'user',
    Assistant: 'assistant',
    Error: 'error',
    System: 'system'
} as const;

export type RoleEnum = typeof RoleEnum[keyof typeof RoleEnum];


/**
 * * `g` - g
 * @export
 * @enum {string}
 */

export const ServingUnitEnum = {
    G: 'g'
} as const;

export type ServingUnitEnum = typeof ServingUnitEnum[keyof typeof ServingUnitEnum];


/**
 * Simple serializer for a simple feedback message.
 * @export
 * @interface SimpleFeedback
 */
export interface SimpleFeedback {
    /**
     * 
     * @type {string}
     * @memberof SimpleFeedback
     */
    'message'?: string;
}
/**
 * Serializer for a single conversation. It\'s used to display a single conversation with all its messages in the conversation view (retrieve and create action).
 * @export
 * @interface SingleConversation
 */
export interface SingleConversation {
    /**
     * 
     * @type {number}
     * @memberof SingleConversation
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SingleConversation
     */
    'session_id': string;
    /**
     * 
     * @type {string}
     * @memberof SingleConversation
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SingleConversation
     */
    'user': number;
    /**
     * 
     * @type {string}
     * @memberof SingleConversation
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof SingleConversation
     */
    'updated_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof SingleConversation
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Array<NestedMessage>}
     * @memberof SingleConversation
     */
    'messages': Array<NestedMessage>;
}
/**
 * 
 * @export
 * @interface SingleEntityContainsMaterialEntity
 */
export interface SingleEntityContainsMaterialEntity {
    /**
     * 
     * @type {number}
     * @memberof SingleEntityContainsMaterialEntity
     */
    'material_entity': number;
    /**
     * 
     * @type {number}
     * @memberof SingleEntityContainsMaterialEntity
     */
    'child_material_entity': number;
}
/**
 * Core status / health endpoint serializer.
 * @export
 * @interface Status
 */
export interface Status {
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    'status'?: string;
}
/**
 * Serializer for the tool model.
 * @export
 * @interface Tool
 */
export interface Tool {
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'programatic_name': string;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'description': string;
    /**
     * 
     * @type {UsageEnum}
     * @memberof Tool
     */
    'usage'?: UsageEnum;
}


/**
 * * `PRO` - Protein * `COM` - Compound * `OTH` - Other * `CPX` - Complex Substance
 * @export
 * @enum {string}
 */

export const TypeEfbEnum = {
    Pro: 'PRO',
    Com: 'COM',
    Oth: 'OTH',
    Cpx: 'CPX'
} as const;

export type TypeEfbEnum = typeof TypeEfbEnum[keyof typeof TypeEfbEnum];


/**
 * * `%` - %
 * @export
 * @enum {string}
 */

export const UnitEnum = {
    Percent: '%'
} as const;

export type UnitEnum = typeof UnitEnum[keyof typeof UnitEnum];


/**
 * 
 * @export
 * @interface UpdateFormulation
 */
export interface UpdateFormulation {
    /**
     * 
     * @type {string}
     * @memberof UpdateFormulation
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFormulation
     */
    'date_modified': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFormulation
     */
    'cibus_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFormulation
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof UpdateFormulation
     */
    'alternative_name'?: Array<string | null> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFormulation
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFormulation
     */
    'img'?: string | null;
    /**
     * 
     * @type {PhysicalStateEnum}
     * @memberof UpdateFormulation
     */
    'physical_state'?: PhysicalStateEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateFormulation
     */
    'serving'?: number;
    /**
     * 
     * @type {ServingUnitEnum}
     * @memberof UpdateFormulation
     */
    'serving_unit'?: ServingUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateFormulation
     */
    'price'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFormulation
     */
    'price_unit'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateFormulation
     */
    'contents': Array<number>;
}


/**
 * 
 * @export
 * @interface UpdateProduct
 */
export interface UpdateProduct {
    /**
     * 
     * @type {string}
     * @memberof UpdateProduct
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProduct
     */
    'description'?: string | null;
}
/**
 * * `optional` - Optional * `deactivated` - Deactivated
 * @export
 * @enum {string}
 */

export const UsageEnum = {
    Optional: 'optional',
    Deactivated: 'deactivated'
} as const;

export type UsageEnum = typeof UsageEnum[keyof typeof UsageEnum];


/**
 * 
 * @export
 * @interface Visibility
 */
export interface Visibility {
    /**
     * 
     * @type {VisibilityEnum}
     * @memberof Visibility
     */
    'visibility': VisibilityEnum;
}


/**
 * * `public` - public * `private` - private
 * @export
 * @enum {string}
 */

export const VisibilityEnum = {
    Public: 'public',
    Private: 'private'
} as const;

export type VisibilityEnum = typeof VisibilityEnum[keyof typeof VisibilityEnum];


/**
 * 
 * @export
 * @interface WritableNutritionalMeasurement
 */
export interface WritableNutritionalMeasurement {
    /**
     * 
     * @type {number}
     * @memberof WritableNutritionalMeasurement
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof WritableNutritionalMeasurement
     */
    'parameter': number;
    /**
     * 
     * @type {number}
     * @memberof WritableNutritionalMeasurement
     */
    'measurement': number;
    /**
     * 
     * @type {number}
     * @memberof WritableNutritionalMeasurement
     */
    'material_entity': number;
}

/**
 * AssistantApi - axios parameter creator
 * @export
 */
export const AssistantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * It receives an external list of messages comprising a conversation. It then submits a request to a GumGum agent to make a completion/answer to the conversion using LLMs and tools.
         * @param {Completion} completion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantCompletionCreate: async (completion: Completion, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'completion' is not null or undefined
            assertParamExists('assistantCompletionCreate', 'completion', completion)
            const localVarPath = `/assistant/completion/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * It receives a chat history completion question from an external system. It then submits a request to a GumGum agent to make a completion/answer to the conversion using LLMs and tools.
         * @param {number} id A unique integer value identifying this conversation.
         * @param {Message} message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantConversationsChatCreate: async (id: number, message: Message, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assistantConversationsChatCreate', 'id', id)
            // verify required parameter 'message' is not null or undefined
            assertParamExists('assistantConversationsChatCreate', 'message', message)
            const localVarPath = `/assistant/conversations/{id}/chat/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(message, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new conversation instance for the authenticated user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantConversationsCreate: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assistant/conversations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset for the conversation model. It allows to list, retrieve, create, and delete conversations. It also has custom actions to get all messages related to a specific conversation, and to chat with the assistant within a specific conversation.
         * @param {number} id A unique integer value identifying this conversation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantConversationsDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assistantConversationsDestroy', 'id', id)
            const localVarPath = `/assistant/conversations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset for the conversation model. It allows to list, retrieve, create, and delete conversations. It also has custom actions to get all messages related to a specific conversation, and to chat with the assistant within a specific conversation.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantConversationsList: async (ordering?: string, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assistant/conversations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all messages related to a specific conversation for the authenticated user.
         * @param {number} id A unique integer value identifying this conversation.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantConversationsMessagesList: async (id: number, ordering?: string, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assistantConversationsMessagesList', 'id', id)
            const localVarPath = `/assistant/conversations/{id}/messages/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset for the conversation model. It allows to list, retrieve, create, and delete conversations. It also has custom actions to get all messages related to a specific conversation, and to chat with the assistant within a specific conversation.
         * @param {number} id A unique integer value identifying this conversation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantConversationsRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assistantConversationsRetrieve', 'id', id)
            const localVarPath = `/assistant/conversations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Saves the user feedback (thumbs up/thumbs down) for a specific message.
         * @summary Saves user feedback for a message
         * @param {MessageFeedback} messageFeedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantMessageFeedbackCreate: async (messageFeedback: MessageFeedback, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageFeedback' is not null or undefined
            assertParamExists('assistantMessageFeedbackCreate', 'messageFeedback', messageFeedback)
            const localVarPath = `/assistant/message-feedback/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageFeedback, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset for the tool model.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantToolsList: async (page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assistant/tools/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset for the tool model.
         * @param {number} id A unique integer value identifying this tool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantToolsRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assistantToolsRetrieve', 'id', id)
            const localVarPath = `/assistant/tools/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssistantApi - functional programming interface
 * @export
 */
export const AssistantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssistantApiAxiosParamCreator(configuration)
    return {
        /**
         * It receives an external list of messages comprising a conversation. It then submits a request to a GumGum agent to make a completion/answer to the conversion using LLMs and tools.
         * @param {Completion} completion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assistantCompletionCreate(completion: Completion, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompletionSerializerOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assistantCompletionCreate(completion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssistantApi.assistantCompletionCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * It receives a chat history completion question from an external system. It then submits a request to a GumGum agent to make a completion/answer to the conversion using LLMs and tools.
         * @param {number} id A unique integer value identifying this conversation.
         * @param {Message} message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assistantConversationsChatCreate(id: number, message: Message, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assistantConversationsChatCreate(id, message, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssistantApi.assistantConversationsChatCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new conversation instance for the authenticated user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assistantConversationsCreate(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleConversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assistantConversationsCreate(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssistantApi.assistantConversationsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Viewset for the conversation model. It allows to list, retrieve, create, and delete conversations. It also has custom actions to get all messages related to a specific conversation, and to chat with the assistant within a specific conversation.
         * @param {number} id A unique integer value identifying this conversation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assistantConversationsDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assistantConversationsDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssistantApi.assistantConversationsDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Viewset for the conversation model. It allows to list, retrieve, create, and delete conversations. It also has custom actions to get all messages related to a specific conversation, and to chat with the assistant within a specific conversation.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assistantConversationsList(ordering?: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedConversationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assistantConversationsList(ordering, page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssistantApi.assistantConversationsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all messages related to a specific conversation for the authenticated user.
         * @param {number} id A unique integer value identifying this conversation.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assistantConversationsMessagesList(id: number, ordering?: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedMessageList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assistantConversationsMessagesList(id, ordering, page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssistantApi.assistantConversationsMessagesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Viewset for the conversation model. It allows to list, retrieve, create, and delete conversations. It also has custom actions to get all messages related to a specific conversation, and to chat with the assistant within a specific conversation.
         * @param {number} id A unique integer value identifying this conversation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assistantConversationsRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleConversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assistantConversationsRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssistantApi.assistantConversationsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Saves the user feedback (thumbs up/thumbs down) for a specific message.
         * @summary Saves user feedback for a message
         * @param {MessageFeedback} messageFeedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assistantMessageFeedbackCreate(messageFeedback: MessageFeedback, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assistantMessageFeedbackCreate(messageFeedback, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssistantApi.assistantMessageFeedbackCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Viewset for the tool model.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assistantToolsList(page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedToolList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assistantToolsList(page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssistantApi.assistantToolsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Viewset for the tool model.
         * @param {number} id A unique integer value identifying this tool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assistantToolsRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tool>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assistantToolsRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssistantApi.assistantToolsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssistantApi - factory interface
 * @export
 */
export const AssistantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssistantApiFp(configuration)
    return {
        /**
         * It receives an external list of messages comprising a conversation. It then submits a request to a GumGum agent to make a completion/answer to the conversion using LLMs and tools.
         * @param {Completion} completion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantCompletionCreate(completion: Completion, options?: RawAxiosRequestConfig): AxiosPromise<CompletionSerializerOutput> {
            return localVarFp.assistantCompletionCreate(completion, options).then((request) => request(axios, basePath));
        },
        /**
         * It receives a chat history completion question from an external system. It then submits a request to a GumGum agent to make a completion/answer to the conversion using LLMs and tools.
         * @param {number} id A unique integer value identifying this conversation.
         * @param {Message} message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantConversationsChatCreate(id: number, message: Message, options?: RawAxiosRequestConfig): AxiosPromise<Message> {
            return localVarFp.assistantConversationsChatCreate(id, message, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new conversation instance for the authenticated user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantConversationsCreate(options?: RawAxiosRequestConfig): AxiosPromise<SingleConversation> {
            return localVarFp.assistantConversationsCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset for the conversation model. It allows to list, retrieve, create, and delete conversations. It also has custom actions to get all messages related to a specific conversation, and to chat with the assistant within a specific conversation.
         * @param {number} id A unique integer value identifying this conversation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantConversationsDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.assistantConversationsDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset for the conversation model. It allows to list, retrieve, create, and delete conversations. It also has custom actions to get all messages related to a specific conversation, and to chat with the assistant within a specific conversation.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantConversationsList(ordering?: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedConversationList> {
            return localVarFp.assistantConversationsList(ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all messages related to a specific conversation for the authenticated user.
         * @param {number} id A unique integer value identifying this conversation.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantConversationsMessagesList(id: number, ordering?: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedMessageList> {
            return localVarFp.assistantConversationsMessagesList(id, ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset for the conversation model. It allows to list, retrieve, create, and delete conversations. It also has custom actions to get all messages related to a specific conversation, and to chat with the assistant within a specific conversation.
         * @param {number} id A unique integer value identifying this conversation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantConversationsRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<SingleConversation> {
            return localVarFp.assistantConversationsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Saves the user feedback (thumbs up/thumbs down) for a specific message.
         * @summary Saves user feedback for a message
         * @param {MessageFeedback} messageFeedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantMessageFeedbackCreate(messageFeedback: MessageFeedback, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.assistantMessageFeedbackCreate(messageFeedback, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset for the tool model.
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantToolsList(page?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedToolList> {
            return localVarFp.assistantToolsList(page, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset for the tool model.
         * @param {number} id A unique integer value identifying this tool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assistantToolsRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Tool> {
            return localVarFp.assistantToolsRetrieve(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssistantApi - object-oriented interface
 * @export
 * @class AssistantApi
 * @extends {BaseAPI}
 */
export class AssistantApi extends BaseAPI {
    /**
     * It receives an external list of messages comprising a conversation. It then submits a request to a GumGum agent to make a completion/answer to the conversion using LLMs and tools.
     * @param {Completion} completion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssistantApi
     */
    public assistantCompletionCreate(completion: Completion, options?: RawAxiosRequestConfig) {
        return AssistantApiFp(this.configuration).assistantCompletionCreate(completion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * It receives a chat history completion question from an external system. It then submits a request to a GumGum agent to make a completion/answer to the conversion using LLMs and tools.
     * @param {number} id A unique integer value identifying this conversation.
     * @param {Message} message 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssistantApi
     */
    public assistantConversationsChatCreate(id: number, message: Message, options?: RawAxiosRequestConfig) {
        return AssistantApiFp(this.configuration).assistantConversationsChatCreate(id, message, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new conversation instance for the authenticated user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssistantApi
     */
    public assistantConversationsCreate(options?: RawAxiosRequestConfig) {
        return AssistantApiFp(this.configuration).assistantConversationsCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset for the conversation model. It allows to list, retrieve, create, and delete conversations. It also has custom actions to get all messages related to a specific conversation, and to chat with the assistant within a specific conversation.
     * @param {number} id A unique integer value identifying this conversation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssistantApi
     */
    public assistantConversationsDestroy(id: number, options?: RawAxiosRequestConfig) {
        return AssistantApiFp(this.configuration).assistantConversationsDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset for the conversation model. It allows to list, retrieve, create, and delete conversations. It also has custom actions to get all messages related to a specific conversation, and to chat with the assistant within a specific conversation.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssistantApi
     */
    public assistantConversationsList(ordering?: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return AssistantApiFp(this.configuration).assistantConversationsList(ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all messages related to a specific conversation for the authenticated user.
     * @param {number} id A unique integer value identifying this conversation.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssistantApi
     */
    public assistantConversationsMessagesList(id: number, ordering?: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return AssistantApiFp(this.configuration).assistantConversationsMessagesList(id, ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset for the conversation model. It allows to list, retrieve, create, and delete conversations. It also has custom actions to get all messages related to a specific conversation, and to chat with the assistant within a specific conversation.
     * @param {number} id A unique integer value identifying this conversation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssistantApi
     */
    public assistantConversationsRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return AssistantApiFp(this.configuration).assistantConversationsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Saves the user feedback (thumbs up/thumbs down) for a specific message.
     * @summary Saves user feedback for a message
     * @param {MessageFeedback} messageFeedback 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssistantApi
     */
    public assistantMessageFeedbackCreate(messageFeedback: MessageFeedback, options?: RawAxiosRequestConfig) {
        return AssistantApiFp(this.configuration).assistantMessageFeedbackCreate(messageFeedback, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset for the tool model.
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssistantApi
     */
    public assistantToolsList(page?: number, options?: RawAxiosRequestConfig) {
        return AssistantApiFp(this.configuration).assistantToolsList(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset for the tool model.
     * @param {number} id A unique integer value identifying this tool.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssistantApi
     */
    public assistantToolsRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return AssistantApiFp(this.configuration).assistantToolsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CoreApi - axios parameter creator
 * @export
 */
export const CoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete all data from database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreCleanCreate: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/clean/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load data from databricks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreLoadCreate: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/load/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provides an overview of the system and data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreOverviewRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/overview/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * It provides the status of the app.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreStatusRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/status/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoreApi - functional programming interface
 * @export
 */
export const CoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoreApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete all data from database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreCleanCreate(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleFeedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreCleanCreate(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreCleanCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Load data from databricks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreLoadCreate(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleFeedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreLoadCreate(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreLoadCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provides an overview of the system and data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreOverviewRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Overview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreOverviewRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreOverviewRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * It provides the status of the app.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreStatusRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreStatusRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CoreApi.coreStatusRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CoreApi - factory interface
 * @export
 */
export const CoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoreApiFp(configuration)
    return {
        /**
         * Delete all data from database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreCleanCreate(options?: RawAxiosRequestConfig): AxiosPromise<SimpleFeedback> {
            return localVarFp.coreCleanCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * Load data from databricks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreLoadCreate(options?: RawAxiosRequestConfig): AxiosPromise<SimpleFeedback> {
            return localVarFp.coreLoadCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * Provides an overview of the system and data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreOverviewRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<Overview> {
            return localVarFp.coreOverviewRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * It provides the status of the app.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreStatusRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<Status> {
            return localVarFp.coreStatusRetrieve(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoreApi - object-oriented interface
 * @export
 * @class CoreApi
 * @extends {BaseAPI}
 */
export class CoreApi extends BaseAPI {
    /**
     * Delete all data from database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreCleanCreate(options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreCleanCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load data from databricks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreLoadCreate(options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreLoadCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provides an overview of the system and data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreOverviewRetrieve(options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreOverviewRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * It provides the status of the app.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreStatusRetrieve(options?: RawAxiosRequestConfig) {
        return CoreApiFp(this.configuration).coreStatusRetrieve(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FoodDesignerApi - axios parameter creator
 * @export
 */
export const FoodDesignerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List of alternatives
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsAlternativesRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerFormulationsAlternativesRetrieve', 'id', id)
            const localVarPath = `/food_designer/formulations/{id}/alternatives/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of labels by type available.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsAnnotationLabelsRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/food_designer/formulations/annotation_labels/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Summary of annotation labels of child entities, grouped by type and then label.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsAnnotationSummaryRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerFormulationsAnnotationSummaryRetrieve', 'id', id)
            const localVarPath = `/food_designer/formulations/{id}/annotation_summary/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calculate Nutritional Measurements from ingredients.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsCalculateMeasurementsCreate: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerFormulationsCalculateMeasurementsCreate', 'id', id)
            const localVarPath = `/food_designer/formulations/{id}/calculate_measurements/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Materialized recursive composition.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsCompositionRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerFormulationsCompositionRetrieve', 'id', id)
            const localVarPath = `/food_designer/formulations/{id}/composition/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Summary of composition.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsCompositionSummaryRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerFormulationsCompositionSummaryRetrieve', 'id', id)
            const localVarPath = `/food_designer/formulations/{id}/composition_summary/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateFormulation} createFormulation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsCreate: async (createFormulation: CreateFormulation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFormulation' is not null or undefined
            assertParamExists('foodDesignerFormulationsCreate', 'createFormulation', createFormulation)
            const localVarPath = `/food_designer/formulations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFormulation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerFormulationsDestroy', 'id', id)
            const localVarPath = `/food_designer/formulations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsList: async (ordering?: string, page?: number, pageSize?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/food_designer/formulations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the literature for an entity
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsLiteratureRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerFormulationsLiteratureRetrieve', 'id', id)
            const localVarPath = `/food_designer/formulations/{id}/literature/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calculate Nutritional Measurements from ingredients.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsNutritionBreakdownRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerFormulationsNutritionBreakdownRetrieve', 'id', id)
            const localVarPath = `/food_designer/formulations/{id}/nutrition_breakdown/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Summary of composition.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {NutritionalScoresInputs} nutritionalScoresInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsNutritionalScoreCreate: async (id: number, nutritionalScoresInputs: NutritionalScoresInputs, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerFormulationsNutritionalScoreCreate', 'id', id)
            // verify required parameter 'nutritionalScoresInputs' is not null or undefined
            assertParamExists('foodDesignerFormulationsNutritionalScoreCreate', 'nutritionalScoresInputs', nutritionalScoresInputs)
            const localVarPath = `/food_designer/formulations/{id}/nutritional_score/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nutritionalScoresInputs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this formulation.
         * @param {PatchedFormulation} [patchedFormulation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsPartialUpdate: async (id: number, patchedFormulation?: PatchedFormulation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerFormulationsPartialUpdate', 'id', id)
            const localVarPath = `/food_designer/formulations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedFormulation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerFormulationsRetrieve', 'id', id)
            const localVarPath = `/food_designer/formulations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this formulation.
         * @param {UpdateFormulation} [updateFormulation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsUpdate: async (id: number, updateFormulation?: UpdateFormulation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerFormulationsUpdate', 'id', id)
            const localVarPath = `/food_designer/formulations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFormulation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateNutritionalMeasurement} createNutritionalMeasurement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalMeasurementsCreate: async (createNutritionalMeasurement: CreateNutritionalMeasurement, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNutritionalMeasurement' is not null or undefined
            assertParamExists('foodDesignerNutritionalMeasurementsCreate', 'createNutritionalMeasurement', createNutritionalMeasurement)
            const localVarPath = `/food_designer/nutritional_measurements/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNutritionalMeasurement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional measurements.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalMeasurementsDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerNutritionalMeasurementsDestroy', 'id', id)
            const localVarPath = `/food_designer/nutritional_measurements/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [materialEntity] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {number} [parameter] 
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalMeasurementsList: async (materialEntity?: number, ordering?: string, page?: number, pageSize?: number, parameter?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/food_designer/nutritional_measurements/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (materialEntity !== undefined) {
                localVarQueryParameter['material_entity'] = materialEntity;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (parameter !== undefined) {
                localVarQueryParameter['parameter'] = parameter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional measurements.
         * @param {PatchedNutritionalMeasurement} [patchedNutritionalMeasurement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalMeasurementsPartialUpdate: async (id: number, patchedNutritionalMeasurement?: PatchedNutritionalMeasurement, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerNutritionalMeasurementsPartialUpdate', 'id', id)
            const localVarPath = `/food_designer/nutritional_measurements/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedNutritionalMeasurement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional measurements.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalMeasurementsRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerNutritionalMeasurementsRetrieve', 'id', id)
            const localVarPath = `/food_designer/nutritional_measurements/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional measurements.
         * @param {WritableNutritionalMeasurement} writableNutritionalMeasurement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalMeasurementsUpdate: async (id: number, writableNutritionalMeasurement: WritableNutritionalMeasurement, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerNutritionalMeasurementsUpdate', 'id', id)
            // verify required parameter 'writableNutritionalMeasurement' is not null or undefined
            assertParamExists('foodDesignerNutritionalMeasurementsUpdate', 'writableNutritionalMeasurement', writableNutritionalMeasurement)
            const localVarPath = `/food_designer/nutritional_measurements/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(writableNutritionalMeasurement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NutritionalParameter} nutritionalParameter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalParametersCreate: async (nutritionalParameter: NutritionalParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nutritionalParameter' is not null or undefined
            assertParamExists('foodDesignerNutritionalParametersCreate', 'nutritionalParameter', nutritionalParameter)
            const localVarPath = `/food_designer/nutritional_parameters/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nutritionalParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalParametersDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerNutritionalParametersDestroy', 'id', id)
            const localVarPath = `/food_designer/nutritional_parameters/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [materialEntitiesId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalParametersList: async (materialEntitiesId?: number, ordering?: string, page?: number, pageSize?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/food_designer/nutritional_parameters/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (materialEntitiesId !== undefined) {
                localVarQueryParameter['material_entities__id'] = materialEntitiesId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional parameters.
         * @param {PatchedNutritionalParameter} [patchedNutritionalParameter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalParametersPartialUpdate: async (id: number, patchedNutritionalParameter?: PatchedNutritionalParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerNutritionalParametersPartialUpdate', 'id', id)
            const localVarPath = `/food_designer/nutritional_parameters/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedNutritionalParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalParametersRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerNutritionalParametersRetrieve', 'id', id)
            const localVarPath = `/food_designer/nutritional_parameters/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional parameters.
         * @param {NutritionalParameter} nutritionalParameter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalParametersUpdate: async (id: number, nutritionalParameter: NutritionalParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerNutritionalParametersUpdate', 'id', id)
            // verify required parameter 'nutritionalParameter' is not null or undefined
            assertParamExists('foodDesignerNutritionalParametersUpdate', 'nutritionalParameter', nutritionalParameter)
            const localVarPath = `/food_designer/nutritional_parameters/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nutritionalParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProduct} createProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsCreate: async (createProduct: CreateProduct, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProduct' is not null or undefined
            assertParamExists('foodDesignerProductsCreate', 'createProduct', createProduct)
            const localVarPath = `/food_designer/products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerProductsDestroy', 'id', id)
            const localVarPath = `/food_designer/products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsFormulationsMainRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerProductsFormulationsMainRetrieve', 'id', id)
            const localVarPath = `/food_designer/products/{id}/formulations/main/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsFormulationsRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerProductsFormulationsRetrieve', 'id', id)
            const localVarPath = `/food_designer/products/{id}/formulations/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isOwner] Selects items the user is the owner or not of. If not defined it will be ignored.
         * @param {boolean} [isPublic] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsList: async (isOwner?: boolean, isPublic?: boolean, ordering?: string, page?: number, pageSize?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/food_designer/products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isOwner !== undefined) {
                localVarQueryParameter['is_owner'] = isOwner;
            }

            if (isPublic !== undefined) {
                localVarQueryParameter['is_public'] = isPublic;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {PatchedProduct} [patchedProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsPartialUpdate: async (id: number, patchedProduct?: PatchedProduct, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerProductsPartialUpdate', 'id', id)
            const localVarPath = `/food_designer/products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerProductsRetrieve', 'id', id)
            const localVarPath = `/food_designer/products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {UpdateProduct} updateProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsUpdate: async (id: number, updateProduct: UpdateProduct, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerProductsUpdate', 'id', id)
            // verify required parameter 'updateProduct' is not null or undefined
            assertParamExists('foodDesignerProductsUpdate', 'updateProduct', updateProduct)
            const localVarPath = `/food_designer/products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {Visibility} visibility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsVisibilityCreate: async (id: number, visibility: Visibility, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('foodDesignerProductsVisibilityCreate', 'id', id)
            // verify required parameter 'visibility' is not null or undefined
            assertParamExists('foodDesignerProductsVisibilityCreate', 'visibility', visibility)
            const localVarPath = `/food_designer/products/{id}/visibility/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visibility, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FoodDesignerApi - functional programming interface
 * @export
 */
export const FoodDesignerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FoodDesignerApiAxiosParamCreator(configuration)
    return {
        /**
         * List of alternatives
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerFormulationsAlternativesRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityAlternatives>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerFormulationsAlternativesRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerFormulationsAlternativesRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List of labels by type available.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerFormulationsAnnotationLabelsRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationLabel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerFormulationsAnnotationLabelsRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerFormulationsAnnotationLabelsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Summary of annotation labels of child entities, grouped by type and then label.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerFormulationsAnnotationSummaryRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityAnnotationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerFormulationsAnnotationSummaryRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerFormulationsAnnotationSummaryRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Calculate Nutritional Measurements from ingredients.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerFormulationsCalculateMeasurementsCreate(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerFormulationsCalculateMeasurementsCreate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerFormulationsCalculateMeasurementsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Materialized recursive composition.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerFormulationsCompositionRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityComposition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerFormulationsCompositionRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerFormulationsCompositionRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Summary of composition.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerFormulationsCompositionSummaryRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityCompositionSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerFormulationsCompositionSummaryRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerFormulationsCompositionSummaryRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateFormulation} createFormulation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerFormulationsCreate(createFormulation: CreateFormulation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateFormulation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerFormulationsCreate(createFormulation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerFormulationsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerFormulationsDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerFormulationsDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerFormulationsDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerFormulationsList(ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedFormulationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerFormulationsList(ordering, page, pageSize, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerFormulationsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the literature for an entity
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerFormulationsLiteratureRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Formulation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerFormulationsLiteratureRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerFormulationsLiteratureRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Calculate Nutritional Measurements from ingredients.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerFormulationsNutritionBreakdownRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityNutritionalBreakdown>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerFormulationsNutritionBreakdownRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerFormulationsNutritionBreakdownRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Summary of composition.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {NutritionalScoresInputs} nutritionalScoresInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerFormulationsNutritionalScoreCreate(id: number, nutritionalScoresInputs: NutritionalScoresInputs, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutritionalScores>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerFormulationsNutritionalScoreCreate(id, nutritionalScoresInputs, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerFormulationsNutritionalScoreCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this formulation.
         * @param {PatchedFormulation} [patchedFormulation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerFormulationsPartialUpdate(id: number, patchedFormulation?: PatchedFormulation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Formulation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerFormulationsPartialUpdate(id, patchedFormulation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerFormulationsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerFormulationsRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Formulation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerFormulationsRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerFormulationsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this formulation.
         * @param {UpdateFormulation} [updateFormulation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerFormulationsUpdate(id: number, updateFormulation?: UpdateFormulation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateFormulation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerFormulationsUpdate(id, updateFormulation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerFormulationsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateNutritionalMeasurement} createNutritionalMeasurement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerNutritionalMeasurementsCreate(createNutritionalMeasurement: CreateNutritionalMeasurement, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNutritionalMeasurement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerNutritionalMeasurementsCreate(createNutritionalMeasurement, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerNutritionalMeasurementsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional measurements.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerNutritionalMeasurementsDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerNutritionalMeasurementsDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerNutritionalMeasurementsDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [materialEntity] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {number} [parameter] 
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerNutritionalMeasurementsList(materialEntity?: number, ordering?: string, page?: number, pageSize?: number, parameter?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedNutritionalMeasurementList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerNutritionalMeasurementsList(materialEntity, ordering, page, pageSize, parameter, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerNutritionalMeasurementsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional measurements.
         * @param {PatchedNutritionalMeasurement} [patchedNutritionalMeasurement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerNutritionalMeasurementsPartialUpdate(id: number, patchedNutritionalMeasurement?: PatchedNutritionalMeasurement, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutritionalMeasurement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerNutritionalMeasurementsPartialUpdate(id, patchedNutritionalMeasurement, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerNutritionalMeasurementsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional measurements.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerNutritionalMeasurementsRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutritionalMeasurement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerNutritionalMeasurementsRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerNutritionalMeasurementsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional measurements.
         * @param {WritableNutritionalMeasurement} writableNutritionalMeasurement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerNutritionalMeasurementsUpdate(id: number, writableNutritionalMeasurement: WritableNutritionalMeasurement, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WritableNutritionalMeasurement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerNutritionalMeasurementsUpdate(id, writableNutritionalMeasurement, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerNutritionalMeasurementsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {NutritionalParameter} nutritionalParameter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerNutritionalParametersCreate(nutritionalParameter: NutritionalParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutritionalParameter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerNutritionalParametersCreate(nutritionalParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerNutritionalParametersCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerNutritionalParametersDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerNutritionalParametersDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerNutritionalParametersDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [materialEntitiesId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerNutritionalParametersList(materialEntitiesId?: number, ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedNutritionalParameterList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerNutritionalParametersList(materialEntitiesId, ordering, page, pageSize, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerNutritionalParametersList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional parameters.
         * @param {PatchedNutritionalParameter} [patchedNutritionalParameter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerNutritionalParametersPartialUpdate(id: number, patchedNutritionalParameter?: PatchedNutritionalParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutritionalParameter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerNutritionalParametersPartialUpdate(id, patchedNutritionalParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerNutritionalParametersPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerNutritionalParametersRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutritionalParameter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerNutritionalParametersRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerNutritionalParametersRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional parameters.
         * @param {NutritionalParameter} nutritionalParameter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerNutritionalParametersUpdate(id: number, nutritionalParameter: NutritionalParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutritionalParameter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerNutritionalParametersUpdate(id, nutritionalParameter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerNutritionalParametersUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateProduct} createProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerProductsCreate(createProduct: CreateProduct, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerProductsCreate(createProduct, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerProductsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerProductsDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerProductsDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerProductsDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerProductsFormulationsMainRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Formulation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerProductsFormulationsMainRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerProductsFormulationsMainRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerProductsFormulationsRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Formulation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerProductsFormulationsRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerProductsFormulationsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [isOwner] Selects items the user is the owner or not of. If not defined it will be ignored.
         * @param {boolean} [isPublic] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerProductsList(isOwner?: boolean, isPublic?: boolean, ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerProductsList(isOwner, isPublic, ordering, page, pageSize, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerProductsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {PatchedProduct} [patchedProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerProductsPartialUpdate(id: number, patchedProduct?: PatchedProduct, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerProductsPartialUpdate(id, patchedProduct, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerProductsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerProductsRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerProductsRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerProductsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {UpdateProduct} updateProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerProductsUpdate(id: number, updateProduct: UpdateProduct, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerProductsUpdate(id, updateProduct, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerProductsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {Visibility} visibility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foodDesignerProductsVisibilityCreate(id: number, visibility: Visibility, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Visibility>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foodDesignerProductsVisibilityCreate(id, visibility, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FoodDesignerApi.foodDesignerProductsVisibilityCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FoodDesignerApi - factory interface
 * @export
 */
export const FoodDesignerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FoodDesignerApiFp(configuration)
    return {
        /**
         * List of alternatives
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsAlternativesRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityAlternatives> {
            return localVarFp.foodDesignerFormulationsAlternativesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List of labels by type available.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsAnnotationLabelsRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<AnnotationLabel> {
            return localVarFp.foodDesignerFormulationsAnnotationLabelsRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * Summary of annotation labels of child entities, grouped by type and then label.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsAnnotationSummaryRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityAnnotationSummary> {
            return localVarFp.foodDesignerFormulationsAnnotationSummaryRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Calculate Nutritional Measurements from ingredients.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsCalculateMeasurementsCreate(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.foodDesignerFormulationsCalculateMeasurementsCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Materialized recursive composition.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsCompositionRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityComposition> {
            return localVarFp.foodDesignerFormulationsCompositionRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Summary of composition.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsCompositionSummaryRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityCompositionSummary> {
            return localVarFp.foodDesignerFormulationsCompositionSummaryRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateFormulation} createFormulation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsCreate(createFormulation: CreateFormulation, options?: RawAxiosRequestConfig): AxiosPromise<CreateFormulation> {
            return localVarFp.foodDesignerFormulationsCreate(createFormulation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.foodDesignerFormulationsDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsList(ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedFormulationList> {
            return localVarFp.foodDesignerFormulationsList(ordering, page, pageSize, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the literature for an entity
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsLiteratureRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Formulation> {
            return localVarFp.foodDesignerFormulationsLiteratureRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Calculate Nutritional Measurements from ingredients.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsNutritionBreakdownRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityNutritionalBreakdown> {
            return localVarFp.foodDesignerFormulationsNutritionBreakdownRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Summary of composition.
         * @param {number} id A unique integer value identifying this formulation.
         * @param {NutritionalScoresInputs} nutritionalScoresInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsNutritionalScoreCreate(id: number, nutritionalScoresInputs: NutritionalScoresInputs, options?: RawAxiosRequestConfig): AxiosPromise<NutritionalScores> {
            return localVarFp.foodDesignerFormulationsNutritionalScoreCreate(id, nutritionalScoresInputs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this formulation.
         * @param {PatchedFormulation} [patchedFormulation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsPartialUpdate(id: number, patchedFormulation?: PatchedFormulation, options?: RawAxiosRequestConfig): AxiosPromise<Formulation> {
            return localVarFp.foodDesignerFormulationsPartialUpdate(id, patchedFormulation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this formulation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Formulation> {
            return localVarFp.foodDesignerFormulationsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this formulation.
         * @param {UpdateFormulation} [updateFormulation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerFormulationsUpdate(id: number, updateFormulation?: UpdateFormulation, options?: RawAxiosRequestConfig): AxiosPromise<UpdateFormulation> {
            return localVarFp.foodDesignerFormulationsUpdate(id, updateFormulation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateNutritionalMeasurement} createNutritionalMeasurement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalMeasurementsCreate(createNutritionalMeasurement: CreateNutritionalMeasurement, options?: RawAxiosRequestConfig): AxiosPromise<CreateNutritionalMeasurement> {
            return localVarFp.foodDesignerNutritionalMeasurementsCreate(createNutritionalMeasurement, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional measurements.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalMeasurementsDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.foodDesignerNutritionalMeasurementsDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [materialEntity] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {number} [parameter] 
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalMeasurementsList(materialEntity?: number, ordering?: string, page?: number, pageSize?: number, parameter?: number, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedNutritionalMeasurementList> {
            return localVarFp.foodDesignerNutritionalMeasurementsList(materialEntity, ordering, page, pageSize, parameter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional measurements.
         * @param {PatchedNutritionalMeasurement} [patchedNutritionalMeasurement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalMeasurementsPartialUpdate(id: number, patchedNutritionalMeasurement?: PatchedNutritionalMeasurement, options?: RawAxiosRequestConfig): AxiosPromise<NutritionalMeasurement> {
            return localVarFp.foodDesignerNutritionalMeasurementsPartialUpdate(id, patchedNutritionalMeasurement, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional measurements.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalMeasurementsRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<NutritionalMeasurement> {
            return localVarFp.foodDesignerNutritionalMeasurementsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional measurements.
         * @param {WritableNutritionalMeasurement} writableNutritionalMeasurement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalMeasurementsUpdate(id: number, writableNutritionalMeasurement: WritableNutritionalMeasurement, options?: RawAxiosRequestConfig): AxiosPromise<WritableNutritionalMeasurement> {
            return localVarFp.foodDesignerNutritionalMeasurementsUpdate(id, writableNutritionalMeasurement, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NutritionalParameter} nutritionalParameter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalParametersCreate(nutritionalParameter: NutritionalParameter, options?: RawAxiosRequestConfig): AxiosPromise<NutritionalParameter> {
            return localVarFp.foodDesignerNutritionalParametersCreate(nutritionalParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalParametersDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.foodDesignerNutritionalParametersDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [materialEntitiesId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalParametersList(materialEntitiesId?: number, ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedNutritionalParameterList> {
            return localVarFp.foodDesignerNutritionalParametersList(materialEntitiesId, ordering, page, pageSize, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional parameters.
         * @param {PatchedNutritionalParameter} [patchedNutritionalParameter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalParametersPartialUpdate(id: number, patchedNutritionalParameter?: PatchedNutritionalParameter, options?: RawAxiosRequestConfig): AxiosPromise<NutritionalParameter> {
            return localVarFp.foodDesignerNutritionalParametersPartialUpdate(id, patchedNutritionalParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalParametersRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<NutritionalParameter> {
            return localVarFp.foodDesignerNutritionalParametersRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nutritional parameters.
         * @param {NutritionalParameter} nutritionalParameter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerNutritionalParametersUpdate(id: number, nutritionalParameter: NutritionalParameter, options?: RawAxiosRequestConfig): AxiosPromise<NutritionalParameter> {
            return localVarFp.foodDesignerNutritionalParametersUpdate(id, nutritionalParameter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProduct} createProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsCreate(createProduct: CreateProduct, options?: RawAxiosRequestConfig): AxiosPromise<CreateProduct> {
            return localVarFp.foodDesignerProductsCreate(createProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.foodDesignerProductsDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsFormulationsMainRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Formulation> {
            return localVarFp.foodDesignerProductsFormulationsMainRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsFormulationsRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Formulation> {
            return localVarFp.foodDesignerProductsFormulationsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isOwner] Selects items the user is the owner or not of. If not defined it will be ignored.
         * @param {boolean} [isPublic] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsList(isOwner?: boolean, isPublic?: boolean, ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedProductList> {
            return localVarFp.foodDesignerProductsList(isOwner, isPublic, ordering, page, pageSize, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {PatchedProduct} [patchedProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsPartialUpdate(id: number, patchedProduct?: PatchedProduct, options?: RawAxiosRequestConfig): AxiosPromise<Product> {
            return localVarFp.foodDesignerProductsPartialUpdate(id, patchedProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Product> {
            return localVarFp.foodDesignerProductsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {UpdateProduct} updateProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsUpdate(id: number, updateProduct: UpdateProduct, options?: RawAxiosRequestConfig): AxiosPromise<UpdateProduct> {
            return localVarFp.foodDesignerProductsUpdate(id, updateProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this product.
         * @param {Visibility} visibility 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foodDesignerProductsVisibilityCreate(id: number, visibility: Visibility, options?: RawAxiosRequestConfig): AxiosPromise<Visibility> {
            return localVarFp.foodDesignerProductsVisibilityCreate(id, visibility, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FoodDesignerApi - object-oriented interface
 * @export
 * @class FoodDesignerApi
 * @extends {BaseAPI}
 */
export class FoodDesignerApi extends BaseAPI {
    /**
     * List of alternatives
     * @param {number} id A unique integer value identifying this formulation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerFormulationsAlternativesRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerFormulationsAlternativesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of labels by type available.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerFormulationsAnnotationLabelsRetrieve(options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerFormulationsAnnotationLabelsRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Summary of annotation labels of child entities, grouped by type and then label.
     * @param {number} id A unique integer value identifying this formulation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerFormulationsAnnotationSummaryRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerFormulationsAnnotationSummaryRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calculate Nutritional Measurements from ingredients.
     * @param {number} id A unique integer value identifying this formulation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerFormulationsCalculateMeasurementsCreate(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerFormulationsCalculateMeasurementsCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Materialized recursive composition.
     * @param {number} id A unique integer value identifying this formulation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerFormulationsCompositionRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerFormulationsCompositionRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Summary of composition.
     * @param {number} id A unique integer value identifying this formulation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerFormulationsCompositionSummaryRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerFormulationsCompositionSummaryRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateFormulation} createFormulation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerFormulationsCreate(createFormulation: CreateFormulation, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerFormulationsCreate(createFormulation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this formulation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerFormulationsDestroy(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerFormulationsDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerFormulationsList(ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerFormulationsList(ordering, page, pageSize, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the literature for an entity
     * @param {number} id A unique integer value identifying this formulation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerFormulationsLiteratureRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerFormulationsLiteratureRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calculate Nutritional Measurements from ingredients.
     * @param {number} id A unique integer value identifying this formulation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerFormulationsNutritionBreakdownRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerFormulationsNutritionBreakdownRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Summary of composition.
     * @param {number} id A unique integer value identifying this formulation.
     * @param {NutritionalScoresInputs} nutritionalScoresInputs 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerFormulationsNutritionalScoreCreate(id: number, nutritionalScoresInputs: NutritionalScoresInputs, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerFormulationsNutritionalScoreCreate(id, nutritionalScoresInputs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this formulation.
     * @param {PatchedFormulation} [patchedFormulation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerFormulationsPartialUpdate(id: number, patchedFormulation?: PatchedFormulation, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerFormulationsPartialUpdate(id, patchedFormulation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this formulation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerFormulationsRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerFormulationsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this formulation.
     * @param {UpdateFormulation} [updateFormulation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerFormulationsUpdate(id: number, updateFormulation?: UpdateFormulation, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerFormulationsUpdate(id, updateFormulation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateNutritionalMeasurement} createNutritionalMeasurement 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerNutritionalMeasurementsCreate(createNutritionalMeasurement: CreateNutritionalMeasurement, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerNutritionalMeasurementsCreate(createNutritionalMeasurement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this nutritional measurements.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerNutritionalMeasurementsDestroy(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerNutritionalMeasurementsDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [materialEntity] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {number} [parameter] 
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerNutritionalMeasurementsList(materialEntity?: number, ordering?: string, page?: number, pageSize?: number, parameter?: number, search?: string, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerNutritionalMeasurementsList(materialEntity, ordering, page, pageSize, parameter, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this nutritional measurements.
     * @param {PatchedNutritionalMeasurement} [patchedNutritionalMeasurement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerNutritionalMeasurementsPartialUpdate(id: number, patchedNutritionalMeasurement?: PatchedNutritionalMeasurement, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerNutritionalMeasurementsPartialUpdate(id, patchedNutritionalMeasurement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this nutritional measurements.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerNutritionalMeasurementsRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerNutritionalMeasurementsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this nutritional measurements.
     * @param {WritableNutritionalMeasurement} writableNutritionalMeasurement 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerNutritionalMeasurementsUpdate(id: number, writableNutritionalMeasurement: WritableNutritionalMeasurement, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerNutritionalMeasurementsUpdate(id, writableNutritionalMeasurement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NutritionalParameter} nutritionalParameter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerNutritionalParametersCreate(nutritionalParameter: NutritionalParameter, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerNutritionalParametersCreate(nutritionalParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this nutritional parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerNutritionalParametersDestroy(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerNutritionalParametersDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [materialEntitiesId] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerNutritionalParametersList(materialEntitiesId?: number, ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerNutritionalParametersList(materialEntitiesId, ordering, page, pageSize, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this nutritional parameters.
     * @param {PatchedNutritionalParameter} [patchedNutritionalParameter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerNutritionalParametersPartialUpdate(id: number, patchedNutritionalParameter?: PatchedNutritionalParameter, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerNutritionalParametersPartialUpdate(id, patchedNutritionalParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this nutritional parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerNutritionalParametersRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerNutritionalParametersRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this nutritional parameters.
     * @param {NutritionalParameter} nutritionalParameter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerNutritionalParametersUpdate(id: number, nutritionalParameter: NutritionalParameter, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerNutritionalParametersUpdate(id, nutritionalParameter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateProduct} createProduct 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerProductsCreate(createProduct: CreateProduct, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerProductsCreate(createProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this product.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerProductsDestroy(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerProductsDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this product.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerProductsFormulationsMainRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerProductsFormulationsMainRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this product.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerProductsFormulationsRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerProductsFormulationsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isOwner] Selects items the user is the owner or not of. If not defined it will be ignored.
     * @param {boolean} [isPublic] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerProductsList(isOwner?: boolean, isPublic?: boolean, ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerProductsList(isOwner, isPublic, ordering, page, pageSize, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this product.
     * @param {PatchedProduct} [patchedProduct] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerProductsPartialUpdate(id: number, patchedProduct?: PatchedProduct, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerProductsPartialUpdate(id, patchedProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this product.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerProductsRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerProductsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this product.
     * @param {UpdateProduct} updateProduct 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerProductsUpdate(id: number, updateProduct: UpdateProduct, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerProductsUpdate(id, updateProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this product.
     * @param {Visibility} visibility 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodDesignerApi
     */
    public foodDesignerProductsVisibilityCreate(id: number, visibility: Visibility, options?: RawAxiosRequestConfig) {
        return FoodDesignerApiFp(this.configuration).foodDesignerProductsVisibilityCreate(id, visibility, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MaterialLibraryApi - axios parameter creator
 * @export
 */
export const MaterialLibraryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChemicalEntity} chemicalEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryCompoundsCreate: async (chemicalEntity: ChemicalEntity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chemicalEntity' is not null or undefined
            assertParamExists('materialLibraryCompoundsCreate', 'chemicalEntity', chemicalEntity)
            const localVarPath = `/material_library/compounds/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chemicalEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this chemical entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryCompoundsDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryCompoundsDestroy', 'id', id)
            const localVarPath = `/material_library/compounds/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] Search filter using full text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryCompoundsList: async (ordering?: string, page?: number, pageSize?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/material_library/compounds/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this chemical entity.
         * @param {PatchedChemicalEntity} [patchedChemicalEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryCompoundsPartialUpdate: async (id: number, patchedChemicalEntity?: PatchedChemicalEntity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryCompoundsPartialUpdate', 'id', id)
            const localVarPath = `/material_library/compounds/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedChemicalEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this chemical entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryCompoundsRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryCompoundsRetrieve', 'id', id)
            const localVarPath = `/material_library/compounds/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this chemical entity.
         * @param {ChemicalEntity} chemicalEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryCompoundsUpdate: async (id: number, chemicalEntity: ChemicalEntity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryCompoundsUpdate', 'id', id)
            // verify required parameter 'chemicalEntity' is not null or undefined
            assertParamExists('materialLibraryCompoundsUpdate', 'chemicalEntity', chemicalEntity)
            const localVarPath = `/material_library/compounds/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chemicalEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of alternatives
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAlternativesRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesAlternativesRetrieve', 'id', id)
            const localVarPath = `/material_library/material_entities/{id}/alternatives/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of labels by type available.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAnnotationLabelsRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/material_library/material_entities/annotation_labels/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Summary of annotation labels of child entities, grouped by type and then label.
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAnnotationSummaryRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesAnnotationSummaryRetrieve', 'id', id)
            const localVarPath = `/material_library/material_entities/{id}/annotation_summary/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MaterialEntityAnnotation} materialEntityAnnotation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAnnotationsCreate: async (materialEntityAnnotation: MaterialEntityAnnotation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'materialEntityAnnotation' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesAnnotationsCreate', 'materialEntityAnnotation', materialEntityAnnotation)
            const localVarPath = `/material_library/material_entities_annotations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(materialEntityAnnotation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity annotation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAnnotationsDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesAnnotationsDestroy', 'id', id)
            const localVarPath = `/material_library/material_entities_annotations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [label] 
         * @param {number} [materialEntity] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] Search filter using full text search.
         * @param {MaterialLibraryMaterialEntitiesAnnotationsListTypeEnum} [type] * &#x60;EXT&#x60; - External ID * &#x60;LBL&#x60; - Label * &#x60;GEO&#x60; - Geography * &#x60;SRC&#x60; - Source * &#x60;REG&#x60; - Regulatory * &#x60;TST&#x60; - Taste * &#x60;TEX&#x60; - Texture * &#x60;ODO&#x60; - Aroma &amp; Odor * &#x60;HLT&#x60; - Health * &#x60;FUC&#x60; - Functional * &#x60;COL&#x60; - Color * &#x60;PAT&#x60; - Patent * &#x60;RSC&#x60; - Research * &#x60;TEC&#x60; - Technical Effect * &#x60;CMP&#x60; - Chemical Property
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAnnotationsList: async (label?: string, materialEntity?: number, ordering?: string, page?: number, pageSize?: number, search?: string, type?: MaterialLibraryMaterialEntitiesAnnotationsListTypeEnum, value?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/material_library/material_entities_annotations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (materialEntity !== undefined) {
                localVarQueryParameter['material_entity'] = materialEntity;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity annotation.
         * @param {PatchedMaterialEntityAnnotation} [patchedMaterialEntityAnnotation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAnnotationsPartialUpdate: async (id: number, patchedMaterialEntityAnnotation?: PatchedMaterialEntityAnnotation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesAnnotationsPartialUpdate', 'id', id)
            const localVarPath = `/material_library/material_entities_annotations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedMaterialEntityAnnotation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity annotation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAnnotationsRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesAnnotationsRetrieve', 'id', id)
            const localVarPath = `/material_library/material_entities_annotations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity annotation.
         * @param {MaterialEntityAnnotation} materialEntityAnnotation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAnnotationsUpdate: async (id: number, materialEntityAnnotation: MaterialEntityAnnotation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesAnnotationsUpdate', 'id', id)
            // verify required parameter 'materialEntityAnnotation' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesAnnotationsUpdate', 'materialEntityAnnotation', materialEntityAnnotation)
            const localVarPath = `/material_library/material_entities_annotations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(materialEntityAnnotation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calculate Nutritional Measurements from ingredients.
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesCalculateMeasurementsCreate: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesCalculateMeasurementsCreate', 'id', id)
            const localVarPath = `/material_library/material_entities/{id}/calculate_measurements/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Materialized recursive composition.
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesCompositionRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesCompositionRetrieve', 'id', id)
            const localVarPath = `/material_library/material_entities/{id}/composition/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Summary of composition.
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesCompositionSummaryRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesCompositionSummaryRetrieve', 'id', id)
            const localVarPath = `/material_library/material_entities/{id}/composition_summary/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateMaterialEntity} [createMaterialEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesCreate: async (createMaterialEntity?: CreateMaterialEntity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/material_library/material_entities/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMaterialEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesDestroy', 'id', id)
            const localVarPath = `/material_library/material_entities/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [annotationsLabelIn] Multiple values may be separated by commas.
         * @param {boolean} [cibusIdIsnull] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] Search filter using full text search.
         * @param {Array<string>} [typeIn] Multiple values may be separated by commas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesList: async (annotationsLabelIn?: Array<string>, cibusIdIsnull?: boolean, ordering?: string, page?: number, pageSize?: number, search?: string, typeIn?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/material_library/material_entities/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (annotationsLabelIn) {
                localVarQueryParameter['annotations__label__in'] = annotationsLabelIn.join(COLLECTION_FORMATS.csv);
            }

            if (cibusIdIsnull !== undefined) {
                localVarQueryParameter['cibus_id__isnull'] = cibusIdIsnull;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (typeIn) {
                localVarQueryParameter['type__in'] = typeIn.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the literature for an entity
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesLiteratureRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesLiteratureRetrieve', 'id', id)
            const localVarPath = `/material_library/material_entities/{id}/literature/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calculate Nutritional Measurements from ingredients.
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesNutritionBreakdownRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesNutritionBreakdownRetrieve', 'id', id)
            const localVarPath = `/material_library/material_entities/{id}/nutrition_breakdown/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity.
         * @param {PatchedMaterialEntity} [patchedMaterialEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesPartialUpdate: async (id: number, patchedMaterialEntity?: PatchedMaterialEntity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesPartialUpdate', 'id', id)
            const localVarPath = `/material_library/material_entities/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedMaterialEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesRetrieve', 'id', id)
            const localVarPath = `/material_library/material_entities/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity.
         * @param {CreateMaterialEntity} [createMaterialEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesUpdate: async (id: number, createMaterialEntity?: CreateMaterialEntity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntitiesUpdate', 'id', id)
            const localVarPath = `/material_library/material_entities/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMaterialEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SingleEntityContainsMaterialEntity} singleEntityContainsMaterialEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntityContainsMaterialEntityCreate: async (singleEntityContainsMaterialEntity: SingleEntityContainsMaterialEntity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'singleEntityContainsMaterialEntity' is not null or undefined
            assertParamExists('materialLibraryMaterialEntityContainsMaterialEntityCreate', 'singleEntityContainsMaterialEntity', singleEntityContainsMaterialEntity)
            const localVarPath = `/material_library/material_entity_contains_material_entity/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(singleEntityContainsMaterialEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity contains material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntityContainsMaterialEntityDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntityContainsMaterialEntityDestroy', 'id', id)
            const localVarPath = `/material_library/material_entity_contains_material_entity/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [amount] 
         * @param {number} [childMaterialEntity] 
         * @param {number} [materialEntity] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] Search filter using full text search.
         * @param {MaterialLibraryMaterialEntityContainsMaterialEntityListUnitEnum} [unit] * &#x60;%&#x60; - %
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntityContainsMaterialEntityList: async (amount?: number, childMaterialEntity?: number, materialEntity?: number, page?: number, pageSize?: number, search?: string, unit?: MaterialLibraryMaterialEntityContainsMaterialEntityListUnitEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/material_library/material_entity_contains_material_entity/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }

            if (childMaterialEntity !== undefined) {
                localVarQueryParameter['child_material_entity'] = childMaterialEntity;
            }

            if (materialEntity !== undefined) {
                localVarQueryParameter['material_entity'] = materialEntity;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (unit !== undefined) {
                localVarQueryParameter['unit'] = unit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity contains material entity.
         * @param {PatchedMaterialEntityContainsMaterialEntity} [patchedMaterialEntityContainsMaterialEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntityContainsMaterialEntityPartialUpdate: async (id: number, patchedMaterialEntityContainsMaterialEntity?: PatchedMaterialEntityContainsMaterialEntity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntityContainsMaterialEntityPartialUpdate', 'id', id)
            const localVarPath = `/material_library/material_entity_contains_material_entity/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedMaterialEntityContainsMaterialEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity contains material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntityContainsMaterialEntityRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntityContainsMaterialEntityRetrieve', 'id', id)
            const localVarPath = `/material_library/material_entity_contains_material_entity/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity contains material entity.
         * @param {MaterialEntityContainsMaterialEntity} [materialEntityContainsMaterialEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntityContainsMaterialEntityUpdate: async (id: number, materialEntityContainsMaterialEntity?: MaterialEntityContainsMaterialEntity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryMaterialEntityContainsMaterialEntityUpdate', 'id', id)
            const localVarPath = `/material_library/material_entity_contains_material_entity/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(materialEntityContainsMaterialEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProteinEntity} proteinEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryProteinsCreate: async (proteinEntity: ProteinEntity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'proteinEntity' is not null or undefined
            assertParamExists('materialLibraryProteinsCreate', 'proteinEntity', proteinEntity)
            const localVarPath = `/material_library/proteins/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proteinEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this protein entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryProteinsDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryProteinsDestroy', 'id', id)
            const localVarPath = `/material_library/proteins/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] Search filter using full text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryProteinsList: async (ordering?: string, page?: number, pageSize?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/material_library/proteins/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this protein entity.
         * @param {PatchedProteinEntity} [patchedProteinEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryProteinsPartialUpdate: async (id: number, patchedProteinEntity?: PatchedProteinEntity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryProteinsPartialUpdate', 'id', id)
            const localVarPath = `/material_library/proteins/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedProteinEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this protein entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryProteinsRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryProteinsRetrieve', 'id', id)
            const localVarPath = `/material_library/proteins/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this protein entity.
         * @param {ProteinEntity} proteinEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryProteinsUpdate: async (id: number, proteinEntity: ProteinEntity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialLibraryProteinsUpdate', 'id', id)
            // verify required parameter 'proteinEntity' is not null or undefined
            assertParamExists('materialLibraryProteinsUpdate', 'proteinEntity', proteinEntity)
            const localVarPath = `/material_library/proteins/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proteinEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaterialLibraryApi - functional programming interface
 * @export
 */
export const MaterialLibraryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaterialLibraryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChemicalEntity} chemicalEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryCompoundsCreate(chemicalEntity: ChemicalEntity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChemicalEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryCompoundsCreate(chemicalEntity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryCompoundsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this chemical entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryCompoundsDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryCompoundsDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryCompoundsDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] Search filter using full text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryCompoundsList(ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedChemicalEntityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryCompoundsList(ordering, page, pageSize, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryCompoundsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this chemical entity.
         * @param {PatchedChemicalEntity} [patchedChemicalEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryCompoundsPartialUpdate(id: number, patchedChemicalEntity?: PatchedChemicalEntity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChemicalEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryCompoundsPartialUpdate(id, patchedChemicalEntity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryCompoundsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this chemical entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryCompoundsRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChemicalEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryCompoundsRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryCompoundsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this chemical entity.
         * @param {ChemicalEntity} chemicalEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryCompoundsUpdate(id: number, chemicalEntity: ChemicalEntity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChemicalEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryCompoundsUpdate(id, chemicalEntity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryCompoundsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List of alternatives
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesAlternativesRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityAlternatives>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesAlternativesRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesAlternativesRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List of labels by type available.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesAnnotationLabelsRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationLabel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesAnnotationLabelsRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesAnnotationLabelsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Summary of annotation labels of child entities, grouped by type and then label.
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesAnnotationSummaryRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityAnnotationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesAnnotationSummaryRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesAnnotationSummaryRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MaterialEntityAnnotation} materialEntityAnnotation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesAnnotationsCreate(materialEntityAnnotation: MaterialEntityAnnotation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityAnnotation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesAnnotationsCreate(materialEntityAnnotation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesAnnotationsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity annotation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesAnnotationsDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesAnnotationsDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesAnnotationsDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [label] 
         * @param {number} [materialEntity] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] Search filter using full text search.
         * @param {MaterialLibraryMaterialEntitiesAnnotationsListTypeEnum} [type] * &#x60;EXT&#x60; - External ID * &#x60;LBL&#x60; - Label * &#x60;GEO&#x60; - Geography * &#x60;SRC&#x60; - Source * &#x60;REG&#x60; - Regulatory * &#x60;TST&#x60; - Taste * &#x60;TEX&#x60; - Texture * &#x60;ODO&#x60; - Aroma &amp; Odor * &#x60;HLT&#x60; - Health * &#x60;FUC&#x60; - Functional * &#x60;COL&#x60; - Color * &#x60;PAT&#x60; - Patent * &#x60;RSC&#x60; - Research * &#x60;TEC&#x60; - Technical Effect * &#x60;CMP&#x60; - Chemical Property
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesAnnotationsList(label?: string, materialEntity?: number, ordering?: string, page?: number, pageSize?: number, search?: string, type?: MaterialLibraryMaterialEntitiesAnnotationsListTypeEnum, value?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedMaterialEntityAnnotationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesAnnotationsList(label, materialEntity, ordering, page, pageSize, search, type, value, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesAnnotationsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity annotation.
         * @param {PatchedMaterialEntityAnnotation} [patchedMaterialEntityAnnotation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesAnnotationsPartialUpdate(id: number, patchedMaterialEntityAnnotation?: PatchedMaterialEntityAnnotation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityAnnotation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesAnnotationsPartialUpdate(id, patchedMaterialEntityAnnotation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesAnnotationsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity annotation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesAnnotationsRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityAnnotation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesAnnotationsRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesAnnotationsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity annotation.
         * @param {MaterialEntityAnnotation} materialEntityAnnotation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesAnnotationsUpdate(id: number, materialEntityAnnotation: MaterialEntityAnnotation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityAnnotation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesAnnotationsUpdate(id, materialEntityAnnotation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesAnnotationsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Calculate Nutritional Measurements from ingredients.
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesCalculateMeasurementsCreate(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesCalculateMeasurementsCreate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesCalculateMeasurementsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Materialized recursive composition.
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesCompositionRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityComposition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesCompositionRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesCompositionRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Summary of composition.
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesCompositionSummaryRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityCompositionSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesCompositionSummaryRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesCompositionSummaryRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateMaterialEntity} [createMaterialEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesCreate(createMaterialEntity?: CreateMaterialEntity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMaterialEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesCreate(createMaterialEntity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [annotationsLabelIn] Multiple values may be separated by commas.
         * @param {boolean} [cibusIdIsnull] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] Search filter using full text search.
         * @param {Array<string>} [typeIn] Multiple values may be separated by commas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesList(annotationsLabelIn?: Array<string>, cibusIdIsnull?: boolean, ordering?: string, page?: number, pageSize?: number, search?: string, typeIn?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedMaterialEntityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesList(annotationsLabelIn, cibusIdIsnull, ordering, page, pageSize, search, typeIn, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the literature for an entity
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesLiteratureRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesLiteratureRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesLiteratureRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Calculate Nutritional Measurements from ingredients.
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesNutritionBreakdownRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityNutritionalBreakdown>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesNutritionBreakdownRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesNutritionBreakdownRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity.
         * @param {PatchedMaterialEntity} [patchedMaterialEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesPartialUpdate(id: number, patchedMaterialEntity?: PatchedMaterialEntity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesPartialUpdate(id, patchedMaterialEntity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity.
         * @param {CreateMaterialEntity} [createMaterialEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntitiesUpdate(id: number, createMaterialEntity?: CreateMaterialEntity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMaterialEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntitiesUpdate(id, createMaterialEntity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntitiesUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SingleEntityContainsMaterialEntity} singleEntityContainsMaterialEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntityContainsMaterialEntityCreate(singleEntityContainsMaterialEntity: SingleEntityContainsMaterialEntity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleEntityContainsMaterialEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntityContainsMaterialEntityCreate(singleEntityContainsMaterialEntity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntityContainsMaterialEntityCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity contains material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntityContainsMaterialEntityDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntityContainsMaterialEntityDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntityContainsMaterialEntityDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [amount] 
         * @param {number} [childMaterialEntity] 
         * @param {number} [materialEntity] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] Search filter using full text search.
         * @param {MaterialLibraryMaterialEntityContainsMaterialEntityListUnitEnum} [unit] * &#x60;%&#x60; - %
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntityContainsMaterialEntityList(amount?: number, childMaterialEntity?: number, materialEntity?: number, page?: number, pageSize?: number, search?: string, unit?: MaterialLibraryMaterialEntityContainsMaterialEntityListUnitEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedMaterialEntityContainsMaterialEntityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntityContainsMaterialEntityList(amount, childMaterialEntity, materialEntity, page, pageSize, search, unit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntityContainsMaterialEntityList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity contains material entity.
         * @param {PatchedMaterialEntityContainsMaterialEntity} [patchedMaterialEntityContainsMaterialEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntityContainsMaterialEntityPartialUpdate(id: number, patchedMaterialEntityContainsMaterialEntity?: PatchedMaterialEntityContainsMaterialEntity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityContainsMaterialEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntityContainsMaterialEntityPartialUpdate(id, patchedMaterialEntityContainsMaterialEntity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntityContainsMaterialEntityPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity contains material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntityContainsMaterialEntityRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityContainsMaterialEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntityContainsMaterialEntityRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntityContainsMaterialEntityRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity contains material entity.
         * @param {MaterialEntityContainsMaterialEntity} [materialEntityContainsMaterialEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryMaterialEntityContainsMaterialEntityUpdate(id: number, materialEntityContainsMaterialEntity?: MaterialEntityContainsMaterialEntity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialEntityContainsMaterialEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryMaterialEntityContainsMaterialEntityUpdate(id, materialEntityContainsMaterialEntity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryMaterialEntityContainsMaterialEntityUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProteinEntity} proteinEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryProteinsCreate(proteinEntity: ProteinEntity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProteinEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryProteinsCreate(proteinEntity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryProteinsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this protein entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryProteinsDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryProteinsDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryProteinsDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] Search filter using full text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryProteinsList(ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProteinEntityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryProteinsList(ordering, page, pageSize, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryProteinsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this protein entity.
         * @param {PatchedProteinEntity} [patchedProteinEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryProteinsPartialUpdate(id: number, patchedProteinEntity?: PatchedProteinEntity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProteinEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryProteinsPartialUpdate(id, patchedProteinEntity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryProteinsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this protein entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryProteinsRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProteinEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryProteinsRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryProteinsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this protein entity.
         * @param {ProteinEntity} proteinEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialLibraryProteinsUpdate(id: number, proteinEntity: ProteinEntity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProteinEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialLibraryProteinsUpdate(id, proteinEntity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaterialLibraryApi.materialLibraryProteinsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MaterialLibraryApi - factory interface
 * @export
 */
export const MaterialLibraryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaterialLibraryApiFp(configuration)
    return {
        /**
         * 
         * @param {ChemicalEntity} chemicalEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryCompoundsCreate(chemicalEntity: ChemicalEntity, options?: RawAxiosRequestConfig): AxiosPromise<ChemicalEntity> {
            return localVarFp.materialLibraryCompoundsCreate(chemicalEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this chemical entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryCompoundsDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.materialLibraryCompoundsDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] Search filter using full text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryCompoundsList(ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedChemicalEntityList> {
            return localVarFp.materialLibraryCompoundsList(ordering, page, pageSize, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this chemical entity.
         * @param {PatchedChemicalEntity} [patchedChemicalEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryCompoundsPartialUpdate(id: number, patchedChemicalEntity?: PatchedChemicalEntity, options?: RawAxiosRequestConfig): AxiosPromise<ChemicalEntity> {
            return localVarFp.materialLibraryCompoundsPartialUpdate(id, patchedChemicalEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this chemical entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryCompoundsRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ChemicalEntity> {
            return localVarFp.materialLibraryCompoundsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this chemical entity.
         * @param {ChemicalEntity} chemicalEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryCompoundsUpdate(id: number, chemicalEntity: ChemicalEntity, options?: RawAxiosRequestConfig): AxiosPromise<ChemicalEntity> {
            return localVarFp.materialLibraryCompoundsUpdate(id, chemicalEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * List of alternatives
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAlternativesRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityAlternatives> {
            return localVarFp.materialLibraryMaterialEntitiesAlternativesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List of labels by type available.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAnnotationLabelsRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<AnnotationLabel> {
            return localVarFp.materialLibraryMaterialEntitiesAnnotationLabelsRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * Summary of annotation labels of child entities, grouped by type and then label.
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAnnotationSummaryRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityAnnotationSummary> {
            return localVarFp.materialLibraryMaterialEntitiesAnnotationSummaryRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MaterialEntityAnnotation} materialEntityAnnotation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAnnotationsCreate(materialEntityAnnotation: MaterialEntityAnnotation, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityAnnotation> {
            return localVarFp.materialLibraryMaterialEntitiesAnnotationsCreate(materialEntityAnnotation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity annotation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAnnotationsDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.materialLibraryMaterialEntitiesAnnotationsDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [label] 
         * @param {number} [materialEntity] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] Search filter using full text search.
         * @param {MaterialLibraryMaterialEntitiesAnnotationsListTypeEnum} [type] * &#x60;EXT&#x60; - External ID * &#x60;LBL&#x60; - Label * &#x60;GEO&#x60; - Geography * &#x60;SRC&#x60; - Source * &#x60;REG&#x60; - Regulatory * &#x60;TST&#x60; - Taste * &#x60;TEX&#x60; - Texture * &#x60;ODO&#x60; - Aroma &amp; Odor * &#x60;HLT&#x60; - Health * &#x60;FUC&#x60; - Functional * &#x60;COL&#x60; - Color * &#x60;PAT&#x60; - Patent * &#x60;RSC&#x60; - Research * &#x60;TEC&#x60; - Technical Effect * &#x60;CMP&#x60; - Chemical Property
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAnnotationsList(label?: string, materialEntity?: number, ordering?: string, page?: number, pageSize?: number, search?: string, type?: MaterialLibraryMaterialEntitiesAnnotationsListTypeEnum, value?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedMaterialEntityAnnotationList> {
            return localVarFp.materialLibraryMaterialEntitiesAnnotationsList(label, materialEntity, ordering, page, pageSize, search, type, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity annotation.
         * @param {PatchedMaterialEntityAnnotation} [patchedMaterialEntityAnnotation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAnnotationsPartialUpdate(id: number, patchedMaterialEntityAnnotation?: PatchedMaterialEntityAnnotation, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityAnnotation> {
            return localVarFp.materialLibraryMaterialEntitiesAnnotationsPartialUpdate(id, patchedMaterialEntityAnnotation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity annotation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAnnotationsRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityAnnotation> {
            return localVarFp.materialLibraryMaterialEntitiesAnnotationsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity annotation.
         * @param {MaterialEntityAnnotation} materialEntityAnnotation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesAnnotationsUpdate(id: number, materialEntityAnnotation: MaterialEntityAnnotation, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityAnnotation> {
            return localVarFp.materialLibraryMaterialEntitiesAnnotationsUpdate(id, materialEntityAnnotation, options).then((request) => request(axios, basePath));
        },
        /**
         * Calculate Nutritional Measurements from ingredients.
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesCalculateMeasurementsCreate(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.materialLibraryMaterialEntitiesCalculateMeasurementsCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Materialized recursive composition.
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesCompositionRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityComposition> {
            return localVarFp.materialLibraryMaterialEntitiesCompositionRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Summary of composition.
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesCompositionSummaryRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityCompositionSummary> {
            return localVarFp.materialLibraryMaterialEntitiesCompositionSummaryRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateMaterialEntity} [createMaterialEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesCreate(createMaterialEntity?: CreateMaterialEntity, options?: RawAxiosRequestConfig): AxiosPromise<CreateMaterialEntity> {
            return localVarFp.materialLibraryMaterialEntitiesCreate(createMaterialEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.materialLibraryMaterialEntitiesDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [annotationsLabelIn] Multiple values may be separated by commas.
         * @param {boolean} [cibusIdIsnull] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] Search filter using full text search.
         * @param {Array<string>} [typeIn] Multiple values may be separated by commas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesList(annotationsLabelIn?: Array<string>, cibusIdIsnull?: boolean, ordering?: string, page?: number, pageSize?: number, search?: string, typeIn?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedMaterialEntityList> {
            return localVarFp.materialLibraryMaterialEntitiesList(annotationsLabelIn, cibusIdIsnull, ordering, page, pageSize, search, typeIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the literature for an entity
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesLiteratureRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntity> {
            return localVarFp.materialLibraryMaterialEntitiesLiteratureRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Calculate Nutritional Measurements from ingredients.
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesNutritionBreakdownRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityNutritionalBreakdown> {
            return localVarFp.materialLibraryMaterialEntitiesNutritionBreakdownRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity.
         * @param {PatchedMaterialEntity} [patchedMaterialEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesPartialUpdate(id: number, patchedMaterialEntity?: PatchedMaterialEntity, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntity> {
            return localVarFp.materialLibraryMaterialEntitiesPartialUpdate(id, patchedMaterialEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntity> {
            return localVarFp.materialLibraryMaterialEntitiesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity.
         * @param {CreateMaterialEntity} [createMaterialEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntitiesUpdate(id: number, createMaterialEntity?: CreateMaterialEntity, options?: RawAxiosRequestConfig): AxiosPromise<CreateMaterialEntity> {
            return localVarFp.materialLibraryMaterialEntitiesUpdate(id, createMaterialEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SingleEntityContainsMaterialEntity} singleEntityContainsMaterialEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntityContainsMaterialEntityCreate(singleEntityContainsMaterialEntity: SingleEntityContainsMaterialEntity, options?: RawAxiosRequestConfig): AxiosPromise<SingleEntityContainsMaterialEntity> {
            return localVarFp.materialLibraryMaterialEntityContainsMaterialEntityCreate(singleEntityContainsMaterialEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity contains material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntityContainsMaterialEntityDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.materialLibraryMaterialEntityContainsMaterialEntityDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [amount] 
         * @param {number} [childMaterialEntity] 
         * @param {number} [materialEntity] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] Search filter using full text search.
         * @param {MaterialLibraryMaterialEntityContainsMaterialEntityListUnitEnum} [unit] * &#x60;%&#x60; - %
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntityContainsMaterialEntityList(amount?: number, childMaterialEntity?: number, materialEntity?: number, page?: number, pageSize?: number, search?: string, unit?: MaterialLibraryMaterialEntityContainsMaterialEntityListUnitEnum, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedMaterialEntityContainsMaterialEntityList> {
            return localVarFp.materialLibraryMaterialEntityContainsMaterialEntityList(amount, childMaterialEntity, materialEntity, page, pageSize, search, unit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity contains material entity.
         * @param {PatchedMaterialEntityContainsMaterialEntity} [patchedMaterialEntityContainsMaterialEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntityContainsMaterialEntityPartialUpdate(id: number, patchedMaterialEntityContainsMaterialEntity?: PatchedMaterialEntityContainsMaterialEntity, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityContainsMaterialEntity> {
            return localVarFp.materialLibraryMaterialEntityContainsMaterialEntityPartialUpdate(id, patchedMaterialEntityContainsMaterialEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity contains material entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntityContainsMaterialEntityRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityContainsMaterialEntity> {
            return localVarFp.materialLibraryMaterialEntityContainsMaterialEntityRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this material entity contains material entity.
         * @param {MaterialEntityContainsMaterialEntity} [materialEntityContainsMaterialEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryMaterialEntityContainsMaterialEntityUpdate(id: number, materialEntityContainsMaterialEntity?: MaterialEntityContainsMaterialEntity, options?: RawAxiosRequestConfig): AxiosPromise<MaterialEntityContainsMaterialEntity> {
            return localVarFp.materialLibraryMaterialEntityContainsMaterialEntityUpdate(id, materialEntityContainsMaterialEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProteinEntity} proteinEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryProteinsCreate(proteinEntity: ProteinEntity, options?: RawAxiosRequestConfig): AxiosPromise<ProteinEntity> {
            return localVarFp.materialLibraryProteinsCreate(proteinEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this protein entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryProteinsDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.materialLibraryProteinsDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] Search filter using full text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryProteinsList(ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedProteinEntityList> {
            return localVarFp.materialLibraryProteinsList(ordering, page, pageSize, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this protein entity.
         * @param {PatchedProteinEntity} [patchedProteinEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryProteinsPartialUpdate(id: number, patchedProteinEntity?: PatchedProteinEntity, options?: RawAxiosRequestConfig): AxiosPromise<ProteinEntity> {
            return localVarFp.materialLibraryProteinsPartialUpdate(id, patchedProteinEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this protein entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryProteinsRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ProteinEntity> {
            return localVarFp.materialLibraryProteinsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this protein entity.
         * @param {ProteinEntity} proteinEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialLibraryProteinsUpdate(id: number, proteinEntity: ProteinEntity, options?: RawAxiosRequestConfig): AxiosPromise<ProteinEntity> {
            return localVarFp.materialLibraryProteinsUpdate(id, proteinEntity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaterialLibraryApi - object-oriented interface
 * @export
 * @class MaterialLibraryApi
 * @extends {BaseAPI}
 */
export class MaterialLibraryApi extends BaseAPI {
    /**
     * 
     * @param {ChemicalEntity} chemicalEntity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryCompoundsCreate(chemicalEntity: ChemicalEntity, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryCompoundsCreate(chemicalEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this chemical entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryCompoundsDestroy(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryCompoundsDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [search] Search filter using full text search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryCompoundsList(ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryCompoundsList(ordering, page, pageSize, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this chemical entity.
     * @param {PatchedChemicalEntity} [patchedChemicalEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryCompoundsPartialUpdate(id: number, patchedChemicalEntity?: PatchedChemicalEntity, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryCompoundsPartialUpdate(id, patchedChemicalEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this chemical entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryCompoundsRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryCompoundsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this chemical entity.
     * @param {ChemicalEntity} chemicalEntity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryCompoundsUpdate(id: number, chemicalEntity: ChemicalEntity, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryCompoundsUpdate(id, chemicalEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of alternatives
     * @param {number} id A unique integer value identifying this material entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesAlternativesRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesAlternativesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of labels by type available.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesAnnotationLabelsRetrieve(options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesAnnotationLabelsRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Summary of annotation labels of child entities, grouped by type and then label.
     * @param {number} id A unique integer value identifying this material entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesAnnotationSummaryRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesAnnotationSummaryRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MaterialEntityAnnotation} materialEntityAnnotation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesAnnotationsCreate(materialEntityAnnotation: MaterialEntityAnnotation, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesAnnotationsCreate(materialEntityAnnotation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this material entity annotation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesAnnotationsDestroy(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesAnnotationsDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [label] 
     * @param {number} [materialEntity] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [search] Search filter using full text search.
     * @param {MaterialLibraryMaterialEntitiesAnnotationsListTypeEnum} [type] * &#x60;EXT&#x60; - External ID * &#x60;LBL&#x60; - Label * &#x60;GEO&#x60; - Geography * &#x60;SRC&#x60; - Source * &#x60;REG&#x60; - Regulatory * &#x60;TST&#x60; - Taste * &#x60;TEX&#x60; - Texture * &#x60;ODO&#x60; - Aroma &amp; Odor * &#x60;HLT&#x60; - Health * &#x60;FUC&#x60; - Functional * &#x60;COL&#x60; - Color * &#x60;PAT&#x60; - Patent * &#x60;RSC&#x60; - Research * &#x60;TEC&#x60; - Technical Effect * &#x60;CMP&#x60; - Chemical Property
     * @param {string} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesAnnotationsList(label?: string, materialEntity?: number, ordering?: string, page?: number, pageSize?: number, search?: string, type?: MaterialLibraryMaterialEntitiesAnnotationsListTypeEnum, value?: string, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesAnnotationsList(label, materialEntity, ordering, page, pageSize, search, type, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this material entity annotation.
     * @param {PatchedMaterialEntityAnnotation} [patchedMaterialEntityAnnotation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesAnnotationsPartialUpdate(id: number, patchedMaterialEntityAnnotation?: PatchedMaterialEntityAnnotation, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesAnnotationsPartialUpdate(id, patchedMaterialEntityAnnotation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this material entity annotation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesAnnotationsRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesAnnotationsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this material entity annotation.
     * @param {MaterialEntityAnnotation} materialEntityAnnotation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesAnnotationsUpdate(id: number, materialEntityAnnotation: MaterialEntityAnnotation, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesAnnotationsUpdate(id, materialEntityAnnotation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calculate Nutritional Measurements from ingredients.
     * @param {number} id A unique integer value identifying this material entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesCalculateMeasurementsCreate(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesCalculateMeasurementsCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Materialized recursive composition.
     * @param {number} id A unique integer value identifying this material entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesCompositionRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesCompositionRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Summary of composition.
     * @param {number} id A unique integer value identifying this material entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesCompositionSummaryRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesCompositionSummaryRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateMaterialEntity} [createMaterialEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesCreate(createMaterialEntity?: CreateMaterialEntity, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesCreate(createMaterialEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this material entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesDestroy(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [annotationsLabelIn] Multiple values may be separated by commas.
     * @param {boolean} [cibusIdIsnull] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [search] Search filter using full text search.
     * @param {Array<string>} [typeIn] Multiple values may be separated by commas.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesList(annotationsLabelIn?: Array<string>, cibusIdIsnull?: boolean, ordering?: string, page?: number, pageSize?: number, search?: string, typeIn?: Array<string>, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesList(annotationsLabelIn, cibusIdIsnull, ordering, page, pageSize, search, typeIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the literature for an entity
     * @param {number} id A unique integer value identifying this material entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesLiteratureRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesLiteratureRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calculate Nutritional Measurements from ingredients.
     * @param {number} id A unique integer value identifying this material entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesNutritionBreakdownRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesNutritionBreakdownRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this material entity.
     * @param {PatchedMaterialEntity} [patchedMaterialEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesPartialUpdate(id: number, patchedMaterialEntity?: PatchedMaterialEntity, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesPartialUpdate(id, patchedMaterialEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this material entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this material entity.
     * @param {CreateMaterialEntity} [createMaterialEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntitiesUpdate(id: number, createMaterialEntity?: CreateMaterialEntity, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntitiesUpdate(id, createMaterialEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SingleEntityContainsMaterialEntity} singleEntityContainsMaterialEntity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntityContainsMaterialEntityCreate(singleEntityContainsMaterialEntity: SingleEntityContainsMaterialEntity, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntityContainsMaterialEntityCreate(singleEntityContainsMaterialEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this material entity contains material entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntityContainsMaterialEntityDestroy(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntityContainsMaterialEntityDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [amount] 
     * @param {number} [childMaterialEntity] 
     * @param {number} [materialEntity] 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [search] Search filter using full text search.
     * @param {MaterialLibraryMaterialEntityContainsMaterialEntityListUnitEnum} [unit] * &#x60;%&#x60; - %
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntityContainsMaterialEntityList(amount?: number, childMaterialEntity?: number, materialEntity?: number, page?: number, pageSize?: number, search?: string, unit?: MaterialLibraryMaterialEntityContainsMaterialEntityListUnitEnum, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntityContainsMaterialEntityList(amount, childMaterialEntity, materialEntity, page, pageSize, search, unit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this material entity contains material entity.
     * @param {PatchedMaterialEntityContainsMaterialEntity} [patchedMaterialEntityContainsMaterialEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntityContainsMaterialEntityPartialUpdate(id: number, patchedMaterialEntityContainsMaterialEntity?: PatchedMaterialEntityContainsMaterialEntity, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntityContainsMaterialEntityPartialUpdate(id, patchedMaterialEntityContainsMaterialEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this material entity contains material entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntityContainsMaterialEntityRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntityContainsMaterialEntityRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this material entity contains material entity.
     * @param {MaterialEntityContainsMaterialEntity} [materialEntityContainsMaterialEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryMaterialEntityContainsMaterialEntityUpdate(id: number, materialEntityContainsMaterialEntity?: MaterialEntityContainsMaterialEntity, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryMaterialEntityContainsMaterialEntityUpdate(id, materialEntityContainsMaterialEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProteinEntity} proteinEntity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryProteinsCreate(proteinEntity: ProteinEntity, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryProteinsCreate(proteinEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this protein entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryProteinsDestroy(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryProteinsDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [search] Search filter using full text search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryProteinsList(ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryProteinsList(ordering, page, pageSize, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this protein entity.
     * @param {PatchedProteinEntity} [patchedProteinEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryProteinsPartialUpdate(id: number, patchedProteinEntity?: PatchedProteinEntity, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryProteinsPartialUpdate(id, patchedProteinEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this protein entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryProteinsRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryProteinsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this protein entity.
     * @param {ProteinEntity} proteinEntity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialLibraryApi
     */
    public materialLibraryProteinsUpdate(id: number, proteinEntity: ProteinEntity, options?: RawAxiosRequestConfig) {
        return MaterialLibraryApiFp(this.configuration).materialLibraryProteinsUpdate(id, proteinEntity, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const MaterialLibraryMaterialEntitiesAnnotationsListTypeEnum = {
    Cmp: 'CMP',
    Col: 'COL',
    Ext: 'EXT',
    Fuc: 'FUC',
    Geo: 'GEO',
    Hlt: 'HLT',
    Lbl: 'LBL',
    Odo: 'ODO',
    Pat: 'PAT',
    Reg: 'REG',
    Rsc: 'RSC',
    Src: 'SRC',
    Tec: 'TEC',
    Tex: 'TEX',
    Tst: 'TST'
} as const;
export type MaterialLibraryMaterialEntitiesAnnotationsListTypeEnum = typeof MaterialLibraryMaterialEntitiesAnnotationsListTypeEnum[keyof typeof MaterialLibraryMaterialEntitiesAnnotationsListTypeEnum];
/**
 * @export
 */
export const MaterialLibraryMaterialEntityContainsMaterialEntityListUnitEnum = {
    Percent: '%'
} as const;
export type MaterialLibraryMaterialEntityContainsMaterialEntityListUnitEnum = typeof MaterialLibraryMaterialEntityContainsMaterialEntityListUnitEnum[keyof typeof MaterialLibraryMaterialEntityContainsMaterialEntityListUnitEnum];


/**
 * ModelLibraryApi - axios parameter creator
 * @export
 */
export const ModelLibraryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this model entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelLibraryDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modelLibraryDestroy', 'id', id)
            const localVarPath = `/model_library/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModelEntity} modelEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelLibraryIngestCreate: async (modelEntity: ModelEntity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelEntity' is not null or undefined
            assertParamExists('modelLibraryIngestCreate', 'modelEntity', modelEntity)
            const localVarPath = `/model_library/ingest/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modelEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelLibraryList: async (ordering?: string, page?: number, pageSize?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/model_library/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this model entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelLibraryRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modelLibraryRetrieve', 'id', id)
            const localVarPath = `/model_library/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this model entity.
         * @param {ModelEntity} modelEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelLibraryScoreCreate: async (id: number, modelEntity: ModelEntity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modelLibraryScoreCreate', 'id', id)
            // verify required parameter 'modelEntity' is not null or undefined
            assertParamExists('modelLibraryScoreCreate', 'modelEntity', modelEntity)
            const localVarPath = `/model_library/{id}/score/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modelEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this model entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelLibrarySignatureRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modelLibrarySignatureRetrieve', 'id', id)
            const localVarPath = `/model_library/{id}/signature/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelLibraryApi - functional programming interface
 * @export
 */
export const ModelLibraryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModelLibraryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this model entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelLibraryDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelLibraryDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModelLibraryApi.modelLibraryDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ModelEntity} modelEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelLibraryIngestCreate(modelEntity: ModelEntity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelLibraryIngestCreate(modelEntity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModelLibraryApi.modelLibraryIngestCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelLibraryList(ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedModelEntityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelLibraryList(ordering, page, pageSize, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModelLibraryApi.modelLibraryList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this model entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelLibraryRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelLibraryRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModelLibraryApi.modelLibraryRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this model entity.
         * @param {ModelEntity} modelEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelLibraryScoreCreate(id: number, modelEntity: ModelEntity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelLibraryScoreCreate(id, modelEntity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModelLibraryApi.modelLibraryScoreCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this model entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelLibrarySignatureRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelLibrarySignatureRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModelLibraryApi.modelLibrarySignatureRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ModelLibraryApi - factory interface
 * @export
 */
export const ModelLibraryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModelLibraryApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this model entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelLibraryDestroy(id: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.modelLibraryDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModelEntity} modelEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelLibraryIngestCreate(modelEntity: ModelEntity, options?: RawAxiosRequestConfig): AxiosPromise<ModelEntity> {
            return localVarFp.modelLibraryIngestCreate(modelEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelLibraryList(ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedModelEntityList> {
            return localVarFp.modelLibraryList(ordering, page, pageSize, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this model entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelLibraryRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ModelEntity> {
            return localVarFp.modelLibraryRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this model entity.
         * @param {ModelEntity} modelEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelLibraryScoreCreate(id: number, modelEntity: ModelEntity, options?: RawAxiosRequestConfig): AxiosPromise<ModelEntity> {
            return localVarFp.modelLibraryScoreCreate(id, modelEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this model entity.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelLibrarySignatureRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ModelEntity> {
            return localVarFp.modelLibrarySignatureRetrieve(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModelLibraryApi - object-oriented interface
 * @export
 * @class ModelLibraryApi
 * @extends {BaseAPI}
 */
export class ModelLibraryApi extends BaseAPI {
    /**
     * 
     * @param {number} id A unique integer value identifying this model entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelLibraryApi
     */
    public modelLibraryDestroy(id: number, options?: RawAxiosRequestConfig) {
        return ModelLibraryApiFp(this.configuration).modelLibraryDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModelEntity} modelEntity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelLibraryApi
     */
    public modelLibraryIngestCreate(modelEntity: ModelEntity, options?: RawAxiosRequestConfig) {
        return ModelLibraryApiFp(this.configuration).modelLibraryIngestCreate(modelEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelLibraryApi
     */
    public modelLibraryList(ordering?: string, page?: number, pageSize?: number, search?: string, options?: RawAxiosRequestConfig) {
        return ModelLibraryApiFp(this.configuration).modelLibraryList(ordering, page, pageSize, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this model entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelLibraryApi
     */
    public modelLibraryRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ModelLibraryApiFp(this.configuration).modelLibraryRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this model entity.
     * @param {ModelEntity} modelEntity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelLibraryApi
     */
    public modelLibraryScoreCreate(id: number, modelEntity: ModelEntity, options?: RawAxiosRequestConfig) {
        return ModelLibraryApiFp(this.configuration).modelLibraryScoreCreate(id, modelEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this model entity.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelLibraryApi
     */
    public modelLibrarySignatureRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return ModelLibraryApiFp(this.configuration).modelLibrarySignatureRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }
}



