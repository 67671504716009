import React, {ChangeEvent, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    Grid2,
    IconButton,
    InputAdornment,
    ListItem,
    ListItemText,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import {Add, ArrowForward, FileUploadOutlined, GridView, Search, TableView} from "@mui/icons-material";
import {
    FilterIngredientCardList,
    SortIngredientCardList,
    TypeIngredientCardList
} from "../sections/@dashboard/ingredients";
import {IngredientCard} from "../components/ingredient-card";
import {StyledSearch} from "../components/search";
import {QueryClient, useQuery} from "@tanstack/react-query";
import {apiRoutes, ingredientTypes, ingredientTypesOptions} from "../config";
import Pagination from "@mui/material/Pagination";
import {SortOption} from "../utils/filteringAndSorting";
import openAPIGeneratorMaterialLibraryInstance from "../openAPIGeneratorMaterialLibraryInstance";
import {NavLink as RouterLink, useSearchParams} from "react-router-dom";
import {MaterialEntity} from "../api";
import {
    ClearFilterIngredientCardList
} from "../sections/@dashboard/ingredients/ingredient-list-filters/FilterIngredientCardList";
import CreatorIngredientCardList, {
    CreatorOption
} from "../sections/@dashboard/ingredients/ingredient-list-filters/CreatorIngredientCardList";
import {DataLoadingStatusHandler} from "../components/data-loading-status-handler";
import FilterBar from "../components/filter-bar";
import {SCOPES} from "../access/permission-maps";
import PermissionsGate from "../access/PermissionsGate";
import {ToggleButton} from "@mui/lab";
import {DataList} from "../components/data-list";
import useMediaQuery from "@mui/material/useMediaQuery";

// ---------------------------------------------------------------------------------------------------------------------

export const sortInitialState: SortOption = {
    property: 'date',
    order: 'desc',
    label: 'Newest'
};

export const creatorInitialState: CreatorOption = {
    value: undefined, label: 'All'
};

export const ingredientsPageQuery = (
    annotationsValue?: string[],
    cibus_id?: boolean,
    searchQuery?: string,
    sort?: SortOption,
    page?: number,
    pageSize?: number,
    type?: string[]
) => ({
    queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, page, pageSize, searchQuery, sort, type, annotationsValue, cibus_id],
    queryFn: () => {
        return openAPIGeneratorMaterialLibraryInstance
            .materialLibraryMaterialEntitiesList(annotationsValue, cibus_id, undefined, page, pageSize, searchQuery, type)
            .then(response => response.data);
    },
});

export const ingredientsPageLoader = async (queryClient: QueryClient) => {
    // @ts-ignore
    const query = ingredientsPageQuery(undefined, undefined, undefined, sortInitialState, 1, 9, undefined);
    return queryClient.ensureQueryData(query);
};

export const annotationLabelsPageQuery = () => ({
    queryKey: [apiRoutes.materialLibrary.materialEntity.detail.annotationLabels],
    queryFn: () => {
        return openAPIGeneratorMaterialLibraryInstance
            .materialLibraryMaterialEntitiesAnnotationLabelsRetrieve()
            .then(response => response.data);
    },
});

// ---------------------------------------------------------------------------------------------------------------------

export const FilterTopicTypographyStyle = {
    marginTop: 1,
    mb: 1,
    fontWeight: "bold",
    letterSpacing: "0.5px",
    fontSize: "14px",
}


function AnnotationFilter(props: {
    title: string,
    allFilters: string[],
    filters: string[],
    handleFiltersChange: any,
    isLoading?: boolean,
}) {
    return (
        <>
            <Typography
                gutterBottom
                sx={FilterTopicTypographyStyle}
            >
                {props.title}
            </Typography>
            <Box sx={{paddingTop: '12px'}}>
                {
                    props.isLoading ?
                        <Skeleton/>
                        :
                        <FilterIngredientCardList value={props.filters}
                                                  setValue={props.handleFiltersChange}
                                                  allFilters={props.allFilters}/>
                }
            </Box>
        </>
    )
}


export function IngredientFilters(props: {
    creator: CreatorOption,
    handleCreatorChange: React.Dispatch<React.SetStateAction<CreatorOption>>,
    sort: SortOption,
    handleSortChange: React.Dispatch<React.SetStateAction<SortOption>>,
    type: string[],
    handleTypeChange: React.Dispatch<React.SetStateAction<string[]>>,
    filters: string[],
    handleFiltersChange: React.Dispatch<React.SetStateAction<string[]>>
}) {
    const [tabIndex, setTabIndex] = useState<number>(0);
    const sortOptions: SortOption[] = [
        {property: 'none', order: "asc", label: 'None'},
        {property: 'date', order: "desc", label: 'Newest'},
        {property: 'date', order: "asc", label: 'Oldest'},
        {property: 'name', order: "desc", label: 'Desc: Name'},
        {property: 'name', order: "asc", label: 'Asc: Name'},
    ];

    const creatorOptions = [
        {value: undefined, label: 'All'},
        {value: false, label: 'Cibus Data Service'},
        {value: true, label: 'User Submitted'},
    ];

    const {data: ingredientsAnnotationData, isLoading: ingredientsAnnotationDataIsLoading} = useQuery(
        annotationLabelsPageQuery()
    );

    // Handler for changing tabs
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    return <>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="filter-tabs">
            <Tab label="Main"/>
            <Tab label="Annotation"/>
            <Tab label="Usage & IDs"/>
        </Tabs>
        <Divider/>
        <TabPanel value={tabIndex} index={0}>
            <Typography
                gutterBottom
                sx={FilterTopicTypographyStyle}
            >
                Origin
            </Typography>
            <CreatorIngredientCardList value={props.creator} setValue={props.handleCreatorChange}
                                       sortOptions={creatorOptions}/>
            <Typography
                gutterBottom
                sx={FilterTopicTypographyStyle}
            >
                Sort By
            </Typography>
            <SortIngredientCardList value={props.sort} setValue={props.handleSortChange}
                                    sortOptions={sortOptions}/>
            <Typography
                gutterBottom
                sx={FilterTopicTypographyStyle}
            >
                Type
            </Typography>
            <TypeIngredientCardList
                selectedType={props.type}
                ingredientTypes={ingredientTypesOptions}
                setSelectedType={props.handleTypeChange}
            />
            {/*@ts-ignore*/}
            <ClearFilterIngredientCardList value={props.filters} setValue={props.handleFiltersChange}/>
        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
            <AnnotationFilter title={"Taste"}
                              allFilters={ingredientsAnnotationData?.TST ?? []}
                              isLoading={ingredientsAnnotationDataIsLoading}
                              filters={props.filters}
                              handleFiltersChange={props.handleFiltersChange}
            />
            <AnnotationFilter title={"Odor"}
                              allFilters={ingredientsAnnotationData?.ODO ?? []}
                              isLoading={ingredientsAnnotationDataIsLoading}
                              filters={props.filters}
                              handleFiltersChange={props.handleFiltersChange}
            />
            {/*@ts-ignore*/}
            <ClearFilterIngredientCardList value={props.filters} setValue={props.handleFiltersChange}/>
        </TabPanel>

        <TabPanel value={tabIndex} index={2}>
            <AnnotationFilter title={"Technical Effect"}
                              allFilters={ingredientsAnnotationData?.TEC ?? []}
                              isLoading={ingredientsAnnotationDataIsLoading}
                              filters={props.filters}
                              handleFiltersChange={props.handleFiltersChange}
            />
            <AnnotationFilter title={"Regulatory"}
                              allFilters={ingredientsAnnotationData?.REG ?? []}
                              isLoading={ingredientsAnnotationDataIsLoading}
                              filters={props.filters}
                              handleFiltersChange={props.handleFiltersChange}
            />
            <AnnotationFilter title={"External Identifiers"}
                              allFilters={ingredientsAnnotationData?.EXT ?? []}
                              isLoading={ingredientsAnnotationDataIsLoading}
                              filters={props.filters}
                              handleFiltersChange={props.handleFiltersChange}
            />
            {/*@ts-ignore*/}
            <ClearFilterIngredientCardList value={props.filters} setValue={props.handleFiltersChange}/>
        </TabPanel>
    </>
}

export default function IngredientsPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [filters, setFilters] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>(searchParams.get("search") ?? ""); // Updated to string
    const [sort, setSort] = useState<SortOption>(sortInitialState);
    const [creator, setCreator] = useState<CreatorOption>(creatorInitialState);
    const [page, setPage] = useState<number>(1);
    const pageSize = 9;
    const [type, setType] = useState<string[]>([]);
    const [viewMode, setViewMode] = React.useState('grid');
    // @ts-ignore
    const matches = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const handleViewModeChange = (
        event: React.MouseEvent<HTMLElement>,
        newMode: string,
    ) => {
        setViewMode(newMode);
    };

    const {data: ingredientsData, status: ingredientStatus} = useQuery(
        ingredientsPageQuery(filters, creator.value, searchQuery, sort, page, pageSize, type)
    );

    const handleQueryByKey = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchQuery(event.target.value);
        setSearchParams(event.target.value ? {"search": event.target.value.toString()} : undefined)
    };


    // Handler for changing pages
    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number): void => {
        setPage(page);
    };

    // Effect to reset the page to 1 whenever search query, type, annotations, or sorting changes
    useEffect(() => {
        setPage(1);
    }, [filters, searchQuery, sort, pageSize, type]);

    return (
        <>
            <Helmet>
                <title>Ingredients | CibusAI</title>
            </Helmet>

            {/* Main content container */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                {/* Sidebar with Tabs */}
                <FilterBar>
                    <IngredientFilters creator={creator}
                                       handleCreatorChange={setCreator}
                                       type={type}
                                       handleTypeChange={setType}
                                       sort={sort}
                                       handleSortChange={setSort}
                                       filters={filters}
                                       handleFiltersChange={setFilters}
                    />
                </FilterBar>

                {/* Main Content Section */}
                <Box width={"100%"}>
                    <Grid2 container
                           alignItems="center"
                           justifyContent="space-between"
                           spacing={2}>
                        <Grid2 size={12}>
                            <Stack
                                alignItems={"center"}
                                direction={"row"}
                                flex-grow={1}
                                justifyContent={"space-between"}
                            >
                                <StyledSearch
                                    fontSize={14}
                                    width={400}
                                    size={"small"}
                                    value={searchQuery}
                                    onChange={handleQueryByKey}
                                    placeholder="Search Ingredient..."
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Search/>
                                        </InputAdornment>
                                    }
                                />
                                <ToggleButtonGroup
                                    color="primary"
                                    value={viewMode}
                                    exclusive
                                    size={"small"}
                                    onChange={handleViewModeChange}
                                    aria-label="View Mode">
                                    <ToggleButton value="grid"><GridView/></ToggleButton>
                                    <ToggleButton value="table"><TableView/></ToggleButton>
                                </ToggleButtonGroup>
                                <PermissionsGate fallback={<></>} scopes={[SCOPES.ingredientsCanCreate]}>
                                    <ButtonGroup>
                                        {
                                            matches ?
                                                <Button variant="contained"
                                                        startIcon={<Add/>}
                                                        component={RouterLink}
                                                        to={"new-ingredient"}>
                                                    New Ingredient
                                                </Button> :
                                                <Button variant="contained"
                                                        component={RouterLink}
                                                        to={"new-ingredient"}>
                                                    <Add/>
                                                </Button>
                                        }
                                    </ButtonGroup>
                                </PermissionsGate>
                            </Stack>
                        </Grid2>
                        <Grid2 size={12}>
                            <DataLoadingStatusHandler
                                status={ingredientStatus}
                                count={ingredientsData?.count}
                                successRender={
                                    <Grid2 container spacing={3} size={12}>
                                        {viewMode == "table" ?
                                            <Grid2 size={12}>
                                                <DataList
                                                    data={ingredientsData?.results}
                                                    itemRender={(item) => (
                                                        <ListItem
                                                            key={item.id}
                                                            secondaryAction={
                                                                <IconButton component={RouterLink}
                                                                            to={`/ingredients/${item.id}`}>
                                                                    <ArrowForward/>
                                                                </IconButton>
                                                            }
                                                        >
                                                            <ListItemText
                                                                primary={item.name}
                                                                // @ts-ignore
                                                                secondary={ingredientTypes[item.type]}
                                                            />
                                                        </ListItem>)}
                                                />
                                            </Grid2> :
                                            <Grid2 container spacing={3} size={12}>
                                                {ingredientsData?.results.map((ingredient: MaterialEntity) => (
                                                    <Grid2 size={{xs: 12, md: 6, lg: 4}} key={ingredient.id}>
                                                        <IngredientCard ingredient={ingredient}/>
                                                    </Grid2>))
                                                }
                                            </Grid2>
                                        }
                                        <Grid2 size={12} alignItems={"center"}>
                                            <Stack alignItems={"center"}>
                                                <Pagination
                                                    variant="outlined"
                                                    shape="rounded"
                                                    count={Math.ceil(ingredientsData ? ingredientsData.count / pageSize : 0)}
                                                    page={page}
                                                    onChange={handlePageChange}
                                                />
                                            </Stack>
                                        </Grid2>
                                    </Grid2>
                                }
                            />
                        </Grid2>
                    </Grid2>
                </Box>
            </Box>
        </>
    )
        ;
}


// TabPanel Component
function TabPanel(props: {
    children?: React.ReactNode;
    index: number;
    value: number
}) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 2}}>
                    {children}
                </Box>
            )}
        </div>
    );
}
