import React from "react";
// @mui/material
import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid2,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import {Delete, Save} from "@mui/icons-material";
// material react table
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {foodDetailPageQuery} from "../../../pages/FoodDetailPage";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {CreateProduct, Product, VisibilityEnum} from "../../../api";
import openAPIGeneratorFoodDesignerInstance from "../../../openAPIGeneratorFoodDesignerInstance";
import {apiRoutes} from "../../../config";


// ----------------------------------------------------------------------
export default function FoodSettingsSection() {
    let {foodId} = useParams();

    const queryClient = useQueryClient()
    const navigate = useNavigate()


    const {isLoading: isLoadingFoodData, data: foodProductData} = useQuery(
        foodDetailPageQuery(Number(foodId))
    )

    const {
        control,
        handleSubmit
    } = useForm<Product>(
        {
            defaultValues: {
                // @ts-ignore
                name: foodProductData.name,
                // @ts-ignore
                description: foodProductData.description,
            },
        }
    )

    const foodUpdateMutation: any = useMutation({
        mutationFn: (food: CreateProduct) => {
            // @ts-ignore
            return openAPIGeneratorFoodDesignerInstance.foodDesignerProductsUpdate(foodId, food)
        },
        onSuccess: () => {
            // @ts-ignore
            queryClient.invalidateQueries({queryKey: [apiRoutes.foodDesigner.products.baseEndpoint, foodId.toString()]})
        },
    })

    const foodUpdateVisibilityMutation: any = useMutation({
        mutationFn: (visibility: VisibilityEnum) => {
            // @ts-ignore
            return openAPIGeneratorFoodDesignerInstance.foodDesignerProductsVisibilityUpdate(foodId, {"visibility": visibility})
        },
        onSuccess: () => {
            // @ts-ignore
            queryClient.invalidateQueries({queryKey: [apiRoutes.foodDesigner.products.baseEndpoint, foodId.toString()]})
        },
    })


    const foodDeleteMutation: any = useMutation({
        mutationFn: () => {
            // @ts-ignore
            return openAPIGeneratorFoodDesignerInstance.foodDesignerProductsDestroy(foodId)
        },
        onSuccess: () => {
            // @ts-ignore
            queryClient.invalidateQueries({queryKey: [apiRoutes.foodDesigner.products.baseEndpoint, foodId.toString()]})
        },
    })


    const onSubmit: SubmitHandler<Product> = (data) => {
        foodUpdateMutation.mutate(data)
    }

    const onDelete = () => {
        foodDeleteMutation.mutate()
        navigate("/food-products")
    }

    return (
        <Grid2 container spacing={3}>
            <Grid2 size={12}>
                <Typography variant={"h6"} gutterBottom>
                    Metadata
                </Typography>
                <Controller
                    name="name"
                    control={control}
                    render={({field}) => <TextField
                        variant="outlined"
                        label={"Name"}
                        fullWidth
                        margin="normal"
                        {...field} />}
                />
                <Controller
                    name="description"
                    control={control}
                    render={({field}) => <TextField
                        variant="outlined"
                        label={"Description"}
                        fullWidth
                        margin="normal"
                        multiline
                        minRows={3}
                        maxRows={8}
                        {...field} />}
                />
                <div>
                    <Button startIcon={<Save/>} onClick={handleSubmit(onSubmit)}>
                        Save
                    </Button>
                </div>
                <Typography variant={"h6"} gutterBottom>
                    Access
                </Typography>
                <FormControl component="fieldset" variant="standard">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={foodProductData?.is_public}
                                onChange={(event) => event.target.checked ? foodUpdateVisibilityMutation.mutate("public") : foodUpdateVisibilityMutation.mutate("private")}
                                name="public"/>
                        }
                        label="Make public"
                    />
                    <FormHelperText>Be careful, all associated information will also be made public</FormHelperText>
                </FormControl>
                <Typography variant={"h6"} gutterBottom>
                    Delete
                </Typography>
                <Button color={"error"} startIcon={<Delete/>} onClick={onDelete}>
                    Delete Permanently
                </Button>
            </Grid2>
        </Grid2>
    )
}