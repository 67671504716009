import React from "react";
// @mui
import {Grid2, Typography} from "@mui/material";
// components
import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {IngredientsTable, UsageTable} from "../foods/FoodDetails";
import {editableMaterialEntity, ingredientDetailQuery} from "../../../pages/IngredientDetailPage";
import {DataOperationStatus} from "../../../components/data-operation-status";
import {Warning} from "@mui/icons-material";
import {ingredientTypes} from "../../../config";

// ----------------------------------------------------------------------

export default function IngredientComposition() {
    let {ingredientId} = useParams();

    const {data: ingredientData} = useQuery(
        ingredientDetailQuery(Number(ingredientId))
    )

    return (
        <Grid2 container
               direction={"row"}
               justifyContent={'flex-start'}
               alignItems={'flex-start'}>
            <Grid2 size={12}>
                {ingredientData?.type === "CPX" ?
                    <IngredientsTable
                        food={ingredientData}
                        detail={true}
                        alternative={editableMaterialEntity(ingredientData)}
                        edit={editableMaterialEntity(ingredientData)}
                    /> :
                    <DataOperationStatus icon={<Warning fontSize={"large"} color={"disabled"}/>}>
                        <Typography color={"textSecondary"}>
                            {/*@ts-ignore*/}
                            Feature not implemented for the "{ingredientTypes[ingredientData?.type]}" ingredient type.
                        </Typography>
                    </DataOperationStatus>
                }
            </Grid2>
        </Grid2>
    )
}

export function IngredientUsage() {
    let {ingredientId} = useParams();

    const {data: ingredientData} = useQuery(
        ingredientDetailQuery(Number(ingredientId))
    )

    return (
        <Grid2 container
               direction={"row"}
               justifyContent={'flex-start'}
               alignItems={'flex-start'}>
            <Grid2 size={12}>
                {ingredientData ?
                    <UsageTable
                        food={ingredientData}
                        detail={true}
                        alternative={editableMaterialEntity(ingredientData)}
                        edit={editableMaterialEntity(ingredientData)}
                    /> : <></>
                }
            </Grid2>
        </Grid2>
    )
}
