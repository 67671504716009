// This is a simple debouncing version of the Autocomplete where the Search Bar autocomplete suggestions are debounced
import {Autocomplete} from "@mui/lab";
import {useQuery} from "@tanstack/react-query";
import {ReactNode, useState} from "react";
import {TextField, TextFieldProps, ChipProps} from "@mui/material";
import {useDebounce} from "../../hooks";

export const AutocompleteSearchBar = (
    {
        value,
        onChange,
        label,
        endpoint,
        queryFn,
        optionRenderFn,
        getOptionFn,
        size = undefined,
        limitTags = undefined,
        freeSolo = false,
        error = false,
        helperText = undefined,
        TextFieldProps = undefined,
        ChipProps = undefined,
        noOptionsText = "Query not found.",
        renderTags = undefined
    }: {
        value: any[] | undefined,
        onChange: (value: any[]) => void,
        label: string,
        endpoint: string,
        queryFn: Function,
        optionRenderFn: any,
        getOptionFn: (option: any) => string,
        size: "small" | "medium" | undefined,
        limitTags: number | undefined,
        freeSolo: boolean,
        error?: boolean | undefined,
        helperText?: string | undefined,
        TextFieldProps?: TextFieldProps | undefined,
        ChipProps?: ChipProps | undefined,
        noOptionsText?: string | undefined
        renderTags?: (value: any[], getTagProps: Function) => ReactNode
    }
) => {
    const [inputValue, setInputValue] = useState('');
    const {data} = useQuery({
        queryKey: [endpoint, inputValue],
        queryFn: () => queryFn(endpoint, inputValue),
    })

    if (!getOptionFn) {
        getOptionFn = (option: any) => option
    }

    if (!optionRenderFn) {
        optionRenderFn = (props: any, option: any) =>
            <>
                <li {...props}>
                    {getOptionFn(option)}
                </li>
            </>

    }

    return (
        <Autocomplete
            multiple
            filterOptions={(x) => x}
            options={data ?? []} //data is undefined on first render
            autoComplete
            getOptionLabel={getOptionFn}
            filterSelectedOptions
            includeInputInList
            size={size}
            limitTags={limitTags}
            noOptionsText={noOptionsText}
            value={value}
            freeSolo={freeSolo}
            onChange={(e, value) => {
                onChange(value)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...TextFieldProps}
                    error={error}
                    helperText={helperText}
                    label={label}
                    type="search"
                />
            )}
            ChipProps={ChipProps}
            renderTags={renderTags}
            renderOption={optionRenderFn}/>
    )
}

export default function DebouncedAutocompleteSearchBar(
    {
        value,
        onChange,
        label,
        queryFn,
        optionRenderFn,
        getOptionLabel,
        initalOptions = undefined,
        multiple = false,
        size = undefined,
        limitTags = undefined,
        freeSolo = false,
        error = false,
        helperText = undefined,
        TextFieldProps = undefined,
        ChipProps = undefined,
        noOptionsText = "Query not found.",
        delay = 500,
        additionalKey = undefined,
        renderTags = undefined,
    }: {
        value: any[] | any,
        onChange: (value: any[]) => void,
        queryFn: Function,
        label?: string,
        optionRenderFn?: any,
        getOptionLabel?: (option: any) => string,
        initalOptions?: any[],
        multiple?: boolean,
        size?: "small" | "medium" | undefined,
        limitTags?: number | undefined,
        freeSolo: boolean,
        error?: boolean | undefined,
        helperText?: string | undefined,
        TextFieldProps?: TextFieldProps | undefined,
        ChipProps?: ChipProps | undefined,
        noOptionsText?: string | undefined
        delay?: number
        additionalKey?: string | undefined
        renderTags?: (value: any[], getTagProps: Function) => ReactNode
    }
) {
    const [inputValue, setInputValue] = useState('');
    const debouncedInputValue = useDebounce(inputValue, delay)

    /*We are adding the `additionalKey` here to avoid the following bug:
    *   - There are two useQuery hooks being used in the strains endpoint.
    *   - One of them returns an array of strain objects
    *   - However, the other returns an array of strain names
    *   - Thus the react query cache must take an additional key to have a clear distinction between the outputs*/
    const {data, isSuccess} = useQuery({
        queryKey: [debouncedInputValue, additionalKey],
        queryFn: () => queryFn(1, 5, debouncedInputValue),
        initialData: initalOptions ?? []
    })

    if (!optionRenderFn) {
        optionRenderFn = (props: any, option: any) =>
            <>
                <li {...props}>
                    {getOptionLabel ? getOptionLabel(option) : option}
                </li>
            </>

    }

    return <Autocomplete
        multiple={multiple}
        filterOptions={(x) => x}
        options={data ?? []} //data is undefined on first render
        // autoComplete
        getOptionLabel={getOptionLabel}
        // filterSelectedOptions
        // includeInputInList
        size={size}
        limitTags={limitTags}
        noOptionsText={noOptionsText}
        value={value ?? null}
        freeSolo={freeSolo}
        onChange={(e, value) => {
            onChange(value)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue)
        }}
        renderInput={(params) => (
            <TextField
                {...params}
                {...TextFieldProps}
                error={error}
                helperText={helperText}
                label={label}
                type="search"
            />
        )}
        ChipProps={ChipProps}
        renderTags={renderTags}
        renderOption={optionRenderFn}/>
}
